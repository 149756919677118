/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import { inventory } from './data';
import * as S from './style';

/*
 * Component
 */
const Inventory = () => (
  <>
    <S.Title>{inventory.title}</S.Title>
    <S.List>
      {inventory.list.map(item => (
        <S.Item key={item.id} image={item.image}>
          <S.ItemTitle>{item.title}</S.ItemTitle>
          <S.ItemDesc>{item.description}</S.ItemDesc>
        </S.Item>
      ))}
    </S.List>
  </>
);

/*
 * Export
 */
export default Inventory;
