/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, medias, utils, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.div({
  marginTop: '98px',
  padding: '4em',

  [medias.maxWidth(breakpoints.m)]: {
    padding: '4em 2em 0',
  },
});

export const Block = styled.div(({ logo, complementaryLogo }) => ({
  position: 'relative',
  maxWidth: '36em',
  margin: '6em auto',

  '&::before': {
    position: 'absolute',
    left: '-5em',
    top: '-1em',
    content: '""',
    display: 'block',
    margin: '1em auto',
    height: '4.2em',
    width: '5em',
    ...utils.backSvgFill(logo),
    backgroundSize: 'contain',

    [medias.maxWidth(breakpoints.xl)]: {
      position: 'relative',
      left: 0,
    },
  },

  ':nth-last-of-type(-n+2)': {
    '&::before': {
      left: '-6em',

      [medias.maxWidth(breakpoints.xl)]: {
        position: 'relative',
        left: 0,
      },
    },
  },

  ...(complementaryLogo && {
    '&::after': {
      position: 'absolute',
      left: '-6em',
      top: '-3em',
      transform: 'rotate(-25deg)',
      content: '""',
      display: 'block',
      margin: '1em auto',
      height: '1.5em',
      width: '3em',
      ...utils.backSvgFill(complementaryLogo),
      backgroundSize: 'contain',

      [medias.maxWidth(breakpoints.xl)]: {
        left: '-3em',
        right: 0,
        margin: 'auto',
      },
      [medias.maxWidth(breakpoints.m)]: {},
    },
  }),
}));

export const Title = styled.h3({
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  lineHeight: '1.8em',
});

export const Paragraph = styled.p({
  marginBottom: '2em',
  lineHeight: '1.8em',
});

export const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginBottom: '2em',

  [medias.maxWidth(breakpoints.s)]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const Logo = styled.img({
  width: '10em',

  [medias.maxWidth(breakpoints.s)]: {
    margin: '2em 0',
  },
});
