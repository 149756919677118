/*
 * Package Import
 */
import React, { Fragment } from 'react';

/*
 * Local Import
 */
import { manifestum } from './data';
import * as S from './style';

/*
 * Component
 */
const Content = () => (
  <S.Container>
    {manifestum.map(({ logo, complementaryLogo, title, texts }) => (
      <S.Block key={title} logo={logo} complementaryLogo={complementaryLogo}>
        <S.Title>{title}</S.Title>
        {texts.map((text, index) => (
          <Fragment key={index}>{text}</Fragment>
        ))}
      </S.Block>
    ))}
  </S.Container>
);

/*
 * Export
 */
export default Content;
