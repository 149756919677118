/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const NavLink = ({ children, location, navId, onClick, to }) => (
  <S.Link
    to={to}
    navId={navId}
    onClick={onClick}
    isActive={location.pathname === to.pathname}
  >
    {children}
  </S.Link>
);

/*
 * PropTypes
 */
NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  navId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string,
  }).isRequired,
};

/*
 * Export
 */
export default withRouter(NavLink);
