/*
 * Local Import
 */
import sacoche from 'assets/files/images/program/sacoche.svg';
import torch from 'assets/files/images/program/torch.svg';
import chest from 'assets/files/images/program/chest.svg';

/**
 * Data
 */
export const timeline = [
  {
    title: 'découvre.',
    icon: sacoche,
    alt: 'sacoche',
    text:
      'Bienvenue dans ta nouvelle quête ! On te rappelle le but du jeu : découvrir ton futur métier. Les règles : 11 jours pour déchiffrer 5 métiers du numérique et en choisir un. La première étape est basée sur la découverte. D’abord, celle de tes compagnons d’aventures et guides, lors de ta première journée. Et le lendemain, celle du métier de développeur web, community manager, médiateur numérique, développeur cybersécurité et webdesigner. Le tout présenté de façon ludique et interactive, par nos intervenants, des experts dans leurs domaines.',
    time: 'jour 1',
  },
  {
    title: 'explore.',
    icon: torch,
    alt: 'torche',
    text:
      'Étape 2 : on ratiboise ! Tu as passé une journée à découvrir 5 métiers, le but est maintenant d’en retenir 3 que tu pourras explorer de plus belle. Prends ta curiosité en guise de pioche et prépare-toi à creuser ! Durant 4 jours, on va entrer davantage dans le concret, avec des témoignages inspirants, un bilan de compétences, ou encore en parlant de tes envies, pour que tu puisses avoir une idée plus précise des métiers et de ce qu’ils ont à t’offrir. À l’issue de cette exploration professionnelle et personnelle, tu devrais en savoir suffisamment pour choisir le métier qui te conviendra le mieux.',
    time: 'jour 3',
  },
  {
    title: 'choisis.',
    icon: chest,
    alt: 'coffre',
    text:
      'Le titre parle de lui-même, l’heure du choix a sonné ! Après avoir effleuré plusieurs domaines du numérique, tes deux prochains jours seront consacrés à un atelier exclusivement focalisé sur un seul métier, celui que tu as sélectionné. Au menu : cas concrets, mises en situation ludique et challenge. Le but est de valider la passion qui semble se dessiner, pour préparer ta future orientation. La veille du dernier jour de Momentum, on t’aidera également à préparer ton projet professionnel. Au menu : apprendre à se valoriser, bilan de compétences et élaboration du CV !',
    time: 'jour 7',
  },
];
