/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { breakpoints, colors, medias, weights } from 'src/themes';
import { MOBILE_NAV_HEIGHT, NAV_HEIGHT } from 'src/components/Nav/style';

/*
 * Keyframes
 */

/*
 * Components
 */
export const InnerMenu = styled.button({
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  fontWeight: weights.bold,
  fontSize: '1.5em',
  height: NAV_HEIGHT,
  letterSpacing: '0.15em',
  padding: '0 1em',
  position: 'relative',
  justifyContent: 'center',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '27px',
    right: 0,
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '10px solid white',
  },

  /* @medias */
  [medias.maxWidth(breakpoints.l)]: {
    padding: '0',
    margin: 'auto',
    maxWidth: breakpoints.iphone6s,
    height: MOBILE_NAV_HEIGHT,

    '&::before': {
      top: '0.6em',
      right: '-0.8em',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderTop: '15px solid white',
    },
  },
  [medias.minWidth(breakpoints.l)]: {
    // hover on Desktop (triangle).
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '10px solid white',
    },
  },

  [medias.maxWidth(breakpoints.s)]: {
    '&::before': {
      top: '1.2em',
      right: '-1em',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '9px solid white',
    },
  },
});

export const SubMenu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  [medias.minWidth(breakpoints.l)]: {
    position: 'absolute',
  },
});

export const SubLink = styled(RouterLink)({
  padding: '0.2em 0.5em',
  fontSize: '1.5em',

  ':hover': {
    color: 'white',
    background: colors.momentumOrange,
  },
});
