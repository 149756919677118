/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import { theWay } from './data';
import * as S from './style';

/*
 * Component
 */
const TheWay = () => (
  <S.Container>
    <S.Title>
      {theWay.title}
      <S.Highlight>{theWay.highlight}</S.Highlight>
    </S.Title>
    <S.List>
      {theWay.list.map(item => (
        <S.Item key={item.id} image={item.image}>
          <S.ItemTitle>{item.title}</S.ItemTitle>
          <S.ItemDesc>{item.description}</S.ItemDesc>
        </S.Item>
      ))}
    </S.List>
    <S.Cta to="/programme">Programme</S.Cta>
  </S.Container>
);

/*
 * Export
 */
export default TheWay;
