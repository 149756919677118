/*
 * Package Import
 */
import React from 'react';
import saveRef from '@oclock/save-ref';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';
import Title from 'src/components/Infos/Title';
import Content from 'src/components/Infos/Content';
import Cta from 'src/components/Jobs/Cta';

// Wrappers
import Main from 'src/components/Manifestum/Main';
import Wrapper from 'src/components/Manifestum/Wrapper';
import Section from 'src/components/Section';

// Data
import data from 'src/data/rgpd';
import metas from './metas';

/*
 * Component
 */
class RgpdContainer extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {};

  /*
   * Render
   */
  render() {
    const { title, intro, blockContent } = data;
    const cta = {
      content: [
        'Bon, maintenant, si tu veux donner tes données pour qu’on puisse t’aider, c’est par ici que ça se passe...',
      ],
      link: {
        to: '/programme',
        text: 'programme',
      },
    };

    return (
      <Wrapper ref={saveRef(this)('app')}>
        <Head {...metas} />
        <Nav />
        <Main ref={saveRef(this)('node')}>
          <Section color="black">
            <Title title={title} />
          </Section>
          <Section color="white">
            <Content intro={intro} blockContent={blockContent} />
          </Section>
          <Section color="black">
            <Cta data={cta} />
          </Section>
        </Main>
        <Footer saveRef={saveRef(this)('footer')} />
      </Wrapper>
    );
  }
}

/*
 * Component
 */
export default RgpdContainer;
