/*
 * Local Import
 */
import computerPixel from 'assets/files/images/program/computerPixel.svg';
import eclairPixel from 'assets/files/images/program/eclairPixel.svg';
import heartPixel from 'assets/files/images/program/heartPixel.svg';

/**
 * Data
 */
export const inventory = {
  title: 'Que faut-il dans ton inventaire ?',
  list: [
    {
      id: 'computer',
      title: 'ordinateur',
      description:
        'Un ordinateur, la base. Il aura le rôle d’un téléporteur pour te faire voyager à travers les différents métiers que tu vas explorer. Ce sera ton lieu de découverte, ta classe virtuelle.',
      image: { src: computerPixel },
    },
    {
      id: 'internet',
      title: 'connexion internet',
      description:
        'Tu t’en doutes, pour accéder à nos classes virtuelles, il te faudra t’équiper d’une connexion internet (minimum 5 Méga) pour que tu suives nos ateliers dans les meilleurs conditions.',
      image: { src: eclairPixel },
    },
    {
      id: 'découvrir',
      title: 'l’envie de découvrir',
      description:
        'Une grande curiosité. Elle sera ton arme principale pour mener à bien cette aventure. Plus elle sera élevée, plus tu te rapprocheras de ton objectif: découvrir ton futur métier.',
      image: { src: heartPixel },
    },
  ],
};
