/*
 * Package Import
 */
import React from 'react';
import { hot } from 'react-hot-loader/root';

/*
 * Local Import
 */
import Size from 'src/containers/Size';
import Scroll from 'src/containers/Scroll';

// Routes
import Routes from 'src/routes';

// Style
import GlobalStyle from './GlobalStyle';

/*
 * Component
 */
const App = () => (
  <>
    <Scroll />
    <Size />
    <GlobalStyle />
    <Routes />
  </>
);

/*
 * Export
 */
export default hot(App);
