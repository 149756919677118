/*
 * Local Import
 */
import oldMan from 'assets/files/images/common/oldMan.svg';

/**
 * data
 */
export default {
  title: {
    white: 'Mais, qu’est-ce qu’on fait',
    orange: 'de tes données ?',
    image: oldMan,
  },
  intro:
    'Bienvenue sur la page RGPD de Momentum, qu’est-ce qu’on fait ici ? Ce titre résume assez bien l’objectif : t’informer sur la collecte, l’utilisation et le partage d’informations que tu peux être amené à nous fournir. Que ce soit via ce site web, nos échanges ou encore par d’autres outils de communications : mail, téléphone, Slack et salle de cours. On abordera tout ça ensemble dans cette page. En gros, on va te montrer ce qu’on fait de tes données.',
  blockContent: [
    {
      title: 'Il y a un responsable ici ?',
      content: [
        {
          paragraph:
            'Oui, c’est l’École O’clock, dont le siège social est situé au 10 rue de Penthièvre à Paris (75008). C’est elle, ou plutôt nous les responsables du traitement de tes données à caractère personnel.',
        },
        {
          title: 'C’EST QUOI EXACTEMENT : UNE DONNÉE À CARACTÈRE PERSONNEL ?',
          paragraph:
            'Alors, ce qu’on appelle “données à caractère personnel”, c’est une information qui permet de t’identifier. Pour plus de simplicité, on les appellera : “données”, tout court.',
        },
      ],
    },
    {
      title: 'Quels genres de données, tu vas nous donner ?',
      content: [
        {
          title: 'Avant de nous rejoindre',
          paragraph:
            'Avant que tu rejoignes Momentum, on va collecter tes données lorsque tu rempliras le formulaire d’inscription présent sur ce site. Par exemple, on va te demander : ton nom, ton prénom, ton genre, ta date de naissance, ton adresse, ton niveau d’études, ta situation professionnelle actuelle, si tu es en situation de handicap, le métier qui t’intéresse le plus dans notre programme et ta disponibilité pour notre formation. Ensuite, nous pourrons être amenés à te demander plus d’informations sur ta motivation, tes hobbies, ton parcours personnel et professionnel, ton équipement ou encore ta connexion internet.',
        },
        {
          title: 'Quand  tu nous as rejoint',
          paragraph:
            'Lorsque tu rejoins Momentum, on pourra être amené à collecter d’autres données. Par exemple, pour ton suivi pédagogique : ta présence en cours, ta participation, tes questions, tes travaux ou encore ta satisfaction. D’ailleurs, tu pourrais être amené à rendre certaines de tes données publiques par toi-même, par exemple, si tu donnes des informations publiquement sur nos espaces de communications : salle de classe ou encore espaces Slack.',
        },
        {
          title: 'Après momentum',
          paragraph:
            'Quand Momentum sera terminé, on prendra le temps de suivre ton parcours pro, pour savoir si on a pu t’aider à réaliser tes objectifs.',
        },
      ],
    },
    {
      title: 'Pourquoi vouloir ces données, on va en faire quoi ?',
      content: [
        {
          paragraph:
            'Toutes les informations qu’on te demandera avant de nous rejoindre seront essentielles pour traiter ta pré-inscription, pour vérifier si Momentum peut te convenir et pour préparer les étapes suivantes. Nous ne collectons pas d’informations inutiles et si nous partageons des infos, ça sera uniquement pour veiller à la gestion administrative de ton dossier, potentiellement avec des organismes comme Pôle Emploi ou encore ta Mission Locale. La liste de données qu’on t’a dressée n’est pas exhaustive, mais comme tu le vois, toutes les données qu’on récolte sont nécessaires pour ton suivi. On les recueille sur base de ton consentement lors de ta pré-inscription en ligne, et ensuite sur la base de l’exécution de la prestation de formation, ainsi que pour respecter les obligations légales auxquelles nous sommes soumis.',
        },
      ],
    },
    {
      title: 'Qui peut voir tes données ?',
      content: [
        {
          paragraph:
            'Dans l’École O’clock, seules les personnes habilitées dans les services dédiés pourront traiter tes données. On peut être amené à partager les données utiles à ton suivi avec les professionnels et formateurs indépendants qui interviendront sur Momentum, ainsi qu’avec les organismes publics concourant au financement de Momentum. Dans tous les cas, on ne partage que les informations indispensables, afin de respecter ta vie privée. On conserve tes données pour une durée de trois ans maximum et nous mettons tout en place pour en garantir la sécurité.',
        },
      ],
    },
    {
      title: 'Tu as les droits',
      content: [
        {
          paragraph:
            'Bien évidemment, tu disposes de tout un ensemble de droits sur tes données : accès et information, rectification, effacement, limitation du traitement, portabilité des données, opposition au traitement, retrait de consentement, plainte auprès d’une autorité de contrôle et transmission de directives concernant le sort de tes données après décès.',
        },
      ],
    },
    {
      title: 'Comment exercer tes droits',
      content: [
        {
          paragraph:
            'Pour exercer tes droits, on te propose de nous contacter :',
        },
        {
          list: [
            'Par mail, idéalement, à l’adresse : rgpd@oclock.io',
            'Ou sinon, par courrier postal, à l’adresse : École O’clock, 10 rue de Penthièvre - 75008 Paris',
          ],
        },
        {
          paragraph:
            'D’ailleurs, si tu as la moindre question, tu peux aussi nous contacter de la même manière.',
        },
        {
          paragraph:
            'Sache que toutes les informations écrites peuvent être modifiées si cela est nécessaire, par exemple si une loi relative à la protection des données évolue. Les infos que tu lis ici datent du 30 mars 2021.',
        },
      ],
    },
  ],
};
