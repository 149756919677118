/*
 * Npm import
 */
import React from 'react';

/*
 * Local import
 */
import sanitizeHtml from 'sanitize-html';

/*
 * Code
 */

// Validation
const regexps = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  tel: /^(\+[0-9 ]{1,3}[0-9 .-/]{5,})|([0-9 .-/]{10,})$/,
  quote: /&rsquo;/gi,
  space: /&nbsp;/gi,
};
const validate = (value, type) => regexps[type].test(value);

// Sanitizing
const sanitizeOptions = {
  allowedTags: [
    // Titles
    'h2',
    'h3',
    'h4',

    // Blocks
    'header',
    'footer',
    'blockquote',
    'p',
    'div',

    // Lists
    'ul',
    'ol',
    'li',

    // Inline
    'a',
    'strong',
    'em',
    'code',
    'cite',
    'del',

    // Separators
    'hr',
    'br',

    // Images
    'figure',
    'img',
    'figcaption',

    // External stuff
    'iframe',
  ],
  allowedAttributes: {
    '*': ['class', 'id'],
    a: ['href', 'rel'],
    img: ['src', 'alt', 'width', 'height'],
    div: ['style'],
    p: ['style'],
    ul: ['style'],
    ol: ['style'],
    iframe: ['src', 'width', 'height'],
  },
};

/*
 * Export
 */
export const validateEmail = email => validate(email, 'email');
export const validateTel = tel => validate(tel, 'tel');

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const createMarkup = (node) => {
  if (typeof node === 'string') {
    // Sanitize html
    let html = sanitizeHtml(node, sanitizeOptions);

    // Turn multiple space into one
    html = html.replace(/\s+/g, ' ');

    // Turn space into non-breaking space
    html = html.replace(/\s([:;?!])/g, '&nbsp;$1');

    // Return node
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return node;
};

export const pad = str => `0${str}`.slice(-2);

export const formatPrice = num => `${num.toString().charAt(0)} ${num.toString().slice(1)}€`;

// TODO: do this in wordpress directly?
export const cleanTitle = string => string.replace(regexps.quote, '’').replace(regexps.space, ' ');

/*
 * Export default
 */
