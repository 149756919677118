/*
 * Local import
 */

/*
 * SEO
 */
const seo = {
  title: '',
  description: '',
  og: {
    title: '',
    description: '',
    image: '',
  },
  color: 'default',
};

/*
 * JSON-LD
 */
const jsonLD = [
  // {
  //   '@context': 'http://schema.org',
  //   '@type': 'SubscribeAction',
  //   agent: {
  //     '@type': 'Organization',
  //     name: 'Momentum',
  //   },
  //   object: {
  //     '@type': 'Intangible',
  //     name: '',
  //     description: seo.description,
  //   },
  // },
];

/*
 * Export
 */
export default { seo, jsonLD };
