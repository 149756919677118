/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import cupFiole from 'assets/files/images/home/cupFiole.svg';

/*
 * Components
 */
export const Container = styled.div({
  padding: '0 2em',
});

export const Title = styled.h2({
  fontWeight: weights.black,
  letterSpacing: '.12em',
  font: `${weights.normal} 2em/1.3 Quicksand`,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2em 2em 1em',

  /* @medias */
  [medias.maxWidth(breakpoints.iphone6s)]: {
    fontSize: '1.6em',
  },

  [medias.betweenWidth(breakpoints.xs, breakpoints.m)]: {
    fontSize: '1.4em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '2em 0.5em 1em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const SubTitle = styled.div({
  margin: '0 auto',
  maxWidth: '55em',
  textAlign: 'center',

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '0 10%',
    width: '100%',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '0',
    fontSize: '0.9em',
  },
});

export const JarsRadio = styled.div({
  margin: '1em auto 5em',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',

  [medias.maxWidth(breakpoints.m)]: {
    margin: '1em auto 2em',
  },
});

export const Input = styled.input({});

export const Jar = styled.img({
  width: '5em',
  height: '5em',

  [medias.maxWidth(breakpoints.m)]: {
    width: '4em',
    height: '4em',
  },
  [medias.maxWidth(breakpoints.xs)]: {
    width: '3.5em',
    height: '3.5em',
  },
});

export const Checkmark = styled.span(({ isActive }) => ({
  position: 'absolute',
  bottom: '-1em',
  left: 0,
  right: 0,
  margin: 'auto',
  height: '0.7em',
  width: '0.7em',
  backgroundColor: '#eee',
  borderRadius: '50%',

  '&:hover': {
    backgroundColor: !isActive && '#ccc',
  },
  ...(isActive && {
    backgroundColor: colors.momentumOrange,
  }),
}));

export const Label = styled.label(({ isActive }) => ({
  position: 'relative',
  margin: '4em 0 0',

  '&:hover': {
    [Checkmark]: {
      backgroundColor: !isActive && '#ccc',
    },
    ...(!isActive && {
      '&::before': {
        top: '-0.7em',
      },
    }),
  },
  '&::before': {
    position: 'absolute',
    left: '1em',
    top: '-0.2em',
    content: '""',
    display: 'block',
    height: '1.5em',
    width: '3em',
    ...utils.backSvgFill(cupFiole),
    backgroundSize: 'contain',
    transition: 'all 0.8s',

    ...(isActive && {
      transform: 'rotate(-25deg)',
      top: '-1.5em',
      left: 0,
    }),

    [medias.maxWidth(breakpoints.m)]: {
      left: '0.9em',
      width: '2.2em',
    },
    [medias.maxWidth(breakpoints.xs)]: {
      left: '0.8em',
      width: '2em',
    },
  },
}));

export const JarsBox = styled.div({
  font: `${weights.bold} 1em/1.3 Lato`,

  margin: 'auto',
  maxWidth: '25em',
  background: colors.momentumBlack,
  color: 'white',
  borderRadius: '15px',
  padding: '1em 2em 2em',
  textAlign: 'center',

  [medias.maxWidth(breakpoints.m)]: {
    padding: '1em 1em 2em',
  },
});

export const BoxTitle = styled.h4({
  textTransform: 'uppercase',
  fontSize: '0.9em',

  '::after': {
    content: '""',
    display: 'block',
    margin: '0.5em auto 1em',
    width: '2em',
    height: '2px',
    background: 'white',
  },
});

export const BoxText = styled.p({
  margin: '0 0 1.5em',
});

export const BoxCta = styled.a({
  border: '2px solid white',
  borderRadius: '25px',
  padding: '0.2em 1em',
});
