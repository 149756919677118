/*
 * Package Import
 */

/*
 * Local Import
 */
import { colors } from 'src/themes';
import { createWrapper } from 'src/components/Wrapper';

/*
 * Components
 */
export default createWrapper({
  background: colors.momentumBlack,
});
