// Base URL
export const BASE_URL = 'https://www.startmomentum.org/';

// Social networks
export const FB_URL = 'https://www.facebook.com/startmomentum.org/';
export const TWITTER_URL = 'https://twitter.com/Start_Momentum';
export const LINKEDIN_URL = 'https://www.linkedin.com/showcase/startmomentum';

// Contact
export const CONTACT_MAIL = 'momentum@oclock.io';
