/* eslint-disable react/no-children-prop */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import paths from 'src/routes/data/paths';
import Cards from './Cards';
import * as S from './style';

/*
 * Component
 */
const Title = ({ data }) => {
  const { title, card } = data;
  const { children } = paths.jobs;

  const activePage = Object.values(children).find(
    child => child.name.toLowerCase() === title,
  );

  const position = Object.keys(children).indexOf(activePage.id);

  return (
    <S.Container>
      <S.Title>
        Qu’est-ce qu’un
        <br />
        <S.Highlight>{title}&nbsp;?</S.Highlight>
      </S.Title>
      <Cards position={position} children={children} card={card} />
    </S.Container>
  );
};

/*
 * PropTypes
 */
Title.propTypes = {
  data: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Title;
