/*
 * Package Import
 */
import styled from '@emotion/styled';
import { breakpoints, colors, medias, weights } from 'src/themes';

/*
 * Local Import
 */

/*
 * Components
 */
export const Container = styled.div({
  height: '100%',
  padding: '0 5em 3em',

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '0 2em 3em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '0 1em 0',
  },
});

export const Formation = styled.div({
  position: 'relative',
  background: colors.momentumBlack,
  textAlign: 'center',
  padding: '5em',
  margin: 'auto',
  maxWidth: '55em',

  [medias.maxWidth(breakpoints.l)]: {
    padding: '3em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '3em 1em',
  },
});

export const Icons = styled.div`
  position: relative;
  max-width: 45em;
  height: 25em;
  overflow: hidden;
  margin: auto;
`;

export const Icon = styled.img(({ left, top, transform }) => ({
  position: 'absolute',
  display: 'block',
  height: '5em',
  width: '5em',
  top,
  left,
  transform,

  [medias.maxWidth(breakpoints.l)]: {
    ':nth-last-of-type(-n+1)': {
      display: 'none',
    },
  },
  [medias.maxWidth(breakpoints.m)]: {
    ':nth-of-type(5n+1)': {
      display: 'none',
    },
  },
  [medias.maxWidth(breakpoints.s)]: {
    ':nth-of-type(5)': {
      display: 'none',
    },
  },
}));

export const Title = styled.h4({
  color: colors.momentumOrange,
  fontWeight: weights.bold,
  textTransform: 'uppercase',
  fontSize: '1.2em',
  margin: '0 0 2em',
});

export const Text = styled.p({
  color: 'white',
  lineHeight: '1.7em',
});
