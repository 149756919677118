/*
 * Package Import
 */
import { fonts as themeFonts } from '@oclock/theme';

/*
 * Weights
 */
export const weights = {
  ...themeFonts,
};
