/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { weights } from 'src/themes';

/*
 * Components
 */
export const Envelope = styled.img({
  maxWidth: '30em',
});

export const Text = styled.p({
  fontWeight: weights.bold,
});
