/*
 * Local Import
 */
import fioleCM from 'assets/files/images/home/fioleCM.svg';
import cardCm from 'assets/files/images/jobs/cardCm.svg';
import cmMissions from 'assets/files/images/jobs/cmMissions.jpg';
import cmQualite from 'assets/files/images/jobs/cmQualite.jpg';

/**
 * Data
 */
export default {
  title: 'community manager',
  id: 'cm',
  card: {
    logo: fioleCM,
    missions: [
      'Assurer la visibilité d’une entreprise sur les réseaux sociaux',
      'Animer et fédérer une communauté',
      'Analyser des données pour ajuster la stratégie',
    ],
    qualities: ['Réactif', 'Créatif', 'Esprit analytique'],
    skills: [
      'Maîtriser les réseaux sociaux',
      'Avoir des capacités rédactionnelles',
      'Interpréter des données',
    ],
    salary: cardCm,
    evolutions: [
      'Social media manager',
      'Responsable communication',
      'Consultant social media',
    ],
    activity: ['Entreprise', 'Agence', 'Freelance'],
  },
  summary: [
    {
      title: 'Les missions du community manager',
      text: [
        'Le rôle du community manager ou plus communément appelé CM, est d’assurer la présence d’une entreprise ou d’une marque sur les réseaux sociaux, en accord avec la stratégie de communication. Le rôle du CM est double : il doit faire rayonner la marque et entretenir la communauté pour la fédérer autour des valeurs de l’entreprise et des produits qu’elle commercialise.',
        'Ses principaux outils sont les réseaux sociaux via lesquels le community manager va s’adresser à sa communauté. Il va la faire réagir et partager des contenus pour que l’aura de l’entreprise ou la marque qu’il représente grandisse.',
        'Le CM doit également analyser l’impact de son travail grâce à des données chiffrées qui lui permettront de mieux ajuster sa stratégie et les contenus qu’il postera sur les divers canaux de communication. Le community manager a le vent en poupe, car les entreprises misent de plus en plus sur les réseaux sociaux pour se faire connaître afin de vendre leur produit et entretenir le lien avec leurs clients.',
        'Aujourd’hui, le community manager travaille en agence pour les marques qu’elle représente, en entreprise ou même en freelance.',
      ],
      img: cmMissions,
      alt: 'Les missions du community manager',
    },
    {
      title: 'Les qualités cruciales du community manager ',
      text: [
        'La particularité du community manager est d’embarquer deux types de compétences précises. Le CM se doit d’être créatif pour constamment faire réagir sa communauté et lui apporter du contenu de qualité. Il doit être doté de très bonnes compétences rédactionnelles, avoir un sens de la répartie et de la réactivité pour toujours être au plus près des tendances.',
        'Si l’aspect créatif est important, le community manager reste un atout du marketing et se doit d’être en mesure d’analyser des données pour mesurer l’efficacité des contenus qu’il produit et publie. Un fort sens de la logique et de la déduction sont donc également très importants pour être un très bon community manager. Aussi, l’aspect empathique est primordial, car le CM est souvent la première personne à qui un client viendra s’adresser pour obtenir des renseignements.',
        'Le CM doit donc faire preuve d’écoute pour répondre aux interrogations et guider les clients de la meilleure manière.',
      ],
      img: cmQualite,
      alt: 'Les qualités du community manager',
    },
  ],
  salary: {
    title: 'Salaire et évolutions du community manager',
    text:
      'Parlons peu, parlons chiffres. Le salaire moyen d’un community manager junior en France est d’environ 24K  brut par an. Évidemment, ici, on parle d’une moyenne, mais ça dépend de plusieurs critères comme la région, l’entreprise dans laquelle il travaille ou encore son expérience. Un CM après quelques années pourra gagner jusqu’à 31K brut par an. Le community manager pourra également évoluer vers un poste avec plus de responsabilités et devenir Social media manager ou responsable/directeur communication avec quelques années d’expérience derrière lui, ou encore devenir consultant social media.',
    evolution: [
      {
        post: 'Community manager junior',
        salary: '24 000€ par an.',
      },
      {
        post: 'Community manager confirmé',
        salary: '25 000€ par an.',
      },
      {
        post: 'Community manager senior',
        salary: 'de 31 000€ par an.',
      },
    ],
  },
  hats: {
    formation: {
      title: 'Les formations pour devenir community manager',
      text:
        'On ne s’improvise pas community manager parce qu’on arrive à faire marrer quelques potes sur les réseaux sociaux, mais on peut le devenir de bien des manières. Cela dit, l’autodidaxie n’est pas à exclure, il faut néanmoins quelques jours de formations auprès d’un expert pour acquérir les bons réflexes, les bonnes notions et le cadre de ce métier pour bien l’exercer. Il existe divers modules et organismes de formations courtes pour devenir CM. De nombreuses aides de financements sont envisageables pour ces formations. Sinon, il y a aussi les voies dites plus “classiques” post-bac en faisant un BTS communication, MUC ou encore technico-commercial. DUT, licence pro et autres écoles de communication, marketing et journalisme.',
    },
  },
  cta: {
    content: [
      'Ça t’a donné envie d’en savoir plus ?',
      'Viens explorer le métier de community manager dans notre programme de découverte !',
    ],
    link: {
      to: '/programme',
      text: 'programme',
    },
  },
};
