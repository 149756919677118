/*
 * Local Import
 */
import oldMan from 'assets/files/images/common/oldMan.svg';

/**
 * data
 */
export default {
  title: {
    white: 'Toi,',
    orange: 'tu dois vraiment nous aimer',
    image: oldMan,
  },
  intro: [
    'Si t’es arrivé ici, c’est que tu dois vraiment nous aimer, parce que même nous on était pas franchement emballé à l’idée de faire cette page. Si elle est là, c’est clairement parce qu’on n’a pas eu le choix. Alors, aller jusqu’à la trouver et cliquer dessus avec l’intention de la lire, on prend clairement ça comme une déclaration d’amour.',
    'Et parce que cet amour est réciproque, on te prévient quand même : ne t’attends pas à découvrir un nouveau sens à ta vie ici, c’est plutôt  dans la page Programme que tu devrais trouver ton bonheur. Là, on est juste sur des mentions légales, à la limite tu découvriras peut-être quelle entité se cache derrière Momentum, mais ça on en parle déjà dans notre Manifestum, qui est nettement plus sympa à lire, on ne va pas se mentir.',
    'Bon, si ces deux paragraphes ne t’ont toujours pas convaincu à te diriger vers une page plus captivante, voici nos mentions légales :',
  ],
  blockContent: [
    {
      title: 'Nos mentions légales',
      content: [
        {
          title: 'Éditeur :',
          paragraph:
            'École O’clock, SAS au capital de 1 641 026 € ayant son siège social au 10 rue de Penthièvre à Paris (75008), immatriculée sous le numéro 818 614 588 au RCS de Paris.',
        },
        {
          title: 'Directeur de la publication :',
          paragraph: 'Anthony Marais',
        },
        {
          title: 'Adresse de courrier électronique et numéro de téléphone :',
          paragraph: 'hey@oclock.io - 09.74.76.80.67',
        },
        {
          title: 'Hébergeur du site :',
          paragraph:
            'Amazon Web Services, à la dénomination sociale : Amazon CS Ireland Ltd, qui est une  société du groupe Amazon Web Services (AWS), dont le siège social se situe à : Inc. P.O/ Box 81226 – Seattle, WA 98108-1226. On te balance aussi son adresse :  Unit 27 – 6400 Cork Airport Business Park – Kinsale Road – Ireland, son téléphone  : (206) 266-4064 et son fax : (206) 266-7010.',
        },
        {
          title: 'Déclaration CNIL :',
          paragraph:
            'Conformément à la loi N°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, la société O’clock a fait l’objet d’une déclaration auprès de la CNIL sous le numéro d’enregistrement 2027779_',
        },
        {
          paragraph:
            'Si tu veux d’autres infos plus transcendantes, tu peux toujours retourner sur notre home',
        },
      ],
    },
  ],
};
