/*
 * Package Import
 */

/*
 * Local Import
 */
import { colors, breakpoints, medias } from 'src/themes';

/*
 * Select style overriden
 */
export const selectStyleOverride = {
  container: styles => ({
    ...styles,
    letterSpacing: 0,
  }),
  valueContainer: styles => ({
    ...styles,
    justifyContent: 'center',

    [medias.maxWidth(breakpoints.m)]: {
      height: '2.5em',
    },
  }),
  control: (styles, state) => {
    let style = {
      ...styles,
      background: colors.white,
      borderRadius: '9px',
      borderColor: colors.grayVeryLight,
      color: colors.gray,
      cursor: 'pointer',
      height: '2.5em',
      padding: '0.15em 0',
      ':hover': {
        borderColor: colors.grayLight,
      },

      [medias.maxWidth(breakpoints.m)]: {
        height: '4.5em',
      },
    };

    if (state.isFocused) {
      style = {
        ...style,
        borderColor: colors.momentumOrange,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        ':hover': {
          borderColor: colors.momentumOrange,
        },
      };
    }

    return style;
  },
  option: (styles, state) => {
    let style = {
      ...styles,
      backgroundColor: colors.white,
      borderBottom: '1px solid #eaecef',
      color: 'grey',
      cursor: 'pointer',
    };

    if (state.isSelected) {
      style = {
        ...style,
        backgroundColor: colors.momentumOrange,
        color: colors.white,
      };
    }
    else if (state.isFocused) {
      style = {
        ...style,
        backgroundColor: colors.momentumOrangeLight,

        // No need for :active, as the select is closing
        ':active': {
          backgroundColor: colors.momentumOrange,
        },
      };
    }

    return style;
  },
  indicatorSeparator: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? colors.grayLight : colors.grayVeryLight,
  }),
  input: styles => ({
    ...styles,
    color: colors.momentumOrange,
  }),
  singleValue: styles => ({
    ...styles,
    color: 'grey',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: colors.momentumOrange,
  }),
  menuList: styles => ({
    ...styles,
    color: colors.momentumOrange,
    height: '100%',
    maxHeight: 325,
    padding: 0,
    borderBottomLeftRadius: '9px',
    borderBottomRightRadius: '9px',
  }),
  menu: styles => ({
    ...styles,
    marginTop: 0,
    borderColor: colors.grayVeryLight,
  }),
};
