/*
 * Package Import
 */
import React from 'react';
import { Global } from '@emotion/core';

/*
 * Local Import
 */
import resetStyle from 'src/themes/reset';
import rootStyle from 'src/themes/root';
import fontStyle from 'src/themes/fonts';

/*
 * Component
 */
const GlobalStyle = () => (
  <>
    <Global styles={resetStyle} />
    <Global styles={rootStyle} />
    <Global styles={fontStyle} />
  </>
);

/*
 * Export
 */
export default GlobalStyle;
