/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, breakpoints, medias, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.div({
  marginTop: '98px',
});

export const Title = styled.h1({
  textTransform: 'uppercase',
  letterSpacing: 0,
  textAlign: 'center',
  padding: '3em 1em 0',
  font: `${weights.bold} 2.4em/1.2 Quicksand`,

  [medias.maxWidth(breakpoints.xl)]: {
    fontSize: '2em',
  },
  [medias.maxWidth(breakpoints.l)]: {
    fontSize: '1.7em',
    padding: '1.5em 1em 0',
  },
  [medias.maxWidth(breakpoints.s)]: {
    fontSize: '1.5em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const Img = styled.img({
  width: '13em',
  display: 'block',
  margin: '5em auto 0',

  [medias.maxWidth(breakpoints.s)]: {
    width: '9em',
  },
});
