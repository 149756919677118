/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { mergeObject } from 'src/utils';
import { breakpoints, colors, medias, weights } from 'src/themes';
import { MOBILE_NAV_HEIGHT, NAV_HEIGHT } from 'src/components/Nav/style';

/*
 * Components
 */
export const Link = styled(RouterLink, {
  shouldForwardProp: prop => !['isActive', 'navId'].includes(prop),
})(
  {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3em',
    fontWeight: weights.bold,
    height: NAV_HEIGHT,
    letterSpacing: '0.15em',
    padding: '0 1em',
    position: 'relative',
    justifyContent: 'center',
    transition: '0.2s background',

    /* @medias */
    [medias.maxWidth(breakpoints.l)]: {
      padding: '0',
      margin: 'auto',
      maxWidth: breakpoints.iphone6s,
      height: MOBILE_NAV_HEIGHT,

      '&::before': {
        content: 'none',
        transition: 'none',
      },
    },
  },
  ({ isActive, navId }) => {
    // Style
    let style = {};
    let activeStyle = {};

    // Props
    const isSignup = navId === 'signup';

    // SignUp
    if (isSignup) {
      style = {
        border: isActive
          ? `2px solid ${colors.momentumOrange}`
          : '2px solid white',
        background: isActive && colors.momentumOrange,
        borderRadius: '2em',
        height: 42,
        padding: '0 2em',

        '&:hover': {
          border: `2px solid ${colors.momentumOrange}`,
        },

        [medias.minWidth(breakpoints.l)]: {
          '&::before': {
            display: 'none',
          },
        },

        /* @medias */
        [medias.maxWidth(breakpoints.l)]: {
          padding: '1em',
          height: 'auto',
        },
      };
    }
    else {
      style = {
        [medias.minWidth(breakpoints.l)]: {
          // hover on Desktop (triangle).
          '&:hover::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '10px solid white',
          },
        },
      };
    }

    if (isActive) {
      activeStyle = {
        /* @medias */
        [medias.maxWidth(breakpoints.l)]: {
          // isActive on Mobile (dot).
          '&::before, &::after': {
            background: 'none',
            content: '"•"',
            fontSize: '1.5em',
            height: 'auto',
            margin: '0 .5em',
            position: 'static',
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
        [medias.minWidth(breakpoints.l)]: {
          // isActive on Desktop (triangle).
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: `10px solid ${colors.momentumOrange}`,
          },
        },
      };
    }

    return mergeObject(activeStyle, style);
  },
);
