/*
 * Local Import
 */
import paper from 'assets/files/images/home/paper.svg';
import longueVue from 'assets/files/images/home/longueVue.svg';
import boussole from 'assets/files/images/home/boussole.svg';

/**
 * Data
 */
export const theWay = {
  title: '11 jours pour trouver ',
  highlight: 'ta voie',
  list: [
    {
      id: 'inscription',
      title: 'inscription',
      description: 'Pour nous rejoindre et commencer l’aventure, la base.',
      image: { src: paper },
    },
    {
      id: 'initiation',
      title: 'initiation',
      description:
        'Pour découvrir ta vocation parmi 5 métiers du numérique pendant 11 jours en téléprésentiel.',
      image: { src: longueVue },
    },
    {
      id: 'orientation',
      title: 'orientation',
      description:
        'Pour apprendre à exercer ta vocation, on t’oriente vers ta future formation.',
      image: { src: boussole },
    },
  ],
};
