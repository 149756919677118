/*
 * Package Import
 */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

/*
 * Local Import
 */
import {
  animations,
  breakpoints,
  colors,
  colorsRGB,
  medias,
  utils,
  zIndex,
} from 'src/themes';
// Assets
import logoMomentum from 'assets/files/images/common/logoMomentumWhite.svg';
import pictoMomentum from 'assets/files/images/common/pictoMomentumWhite.svg';

/*
 * Code
 */
export const NAV_HEIGHT = 58;
export const MOBILE_NAV_HEIGHT = 45;
const spin = keyframes(animations.spin);

/*
 * Components
 */
export const Header = styled.header();

export const Nav = styled.nav(
  () => ({
    background: colors.momentumBlack,
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    fontSize: '1.5rem',
    zIndex: zIndex.nav,

    '::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      background: `rgba(${colorsRGB.black}, .12)`,
      zIndex: zIndex.navBefore,
    },

    // Display burger menu
    [medias.maxWidth(breakpoints.l)]: {
      position: 'absolute',
      '::before': {
        content: 'none',
      },
    },
  }),
  ({ isOpen }) => {
    let stateStyle = {};

    /* If menu is open */
    if (isOpen) {
      stateStyle = {
        [medias.maxWidth(breakpoints.l)]: {
          height: 'auto',
          bottom: '0',
          position: 'fixed',
          visibility: 'visible',
          left: '0',
          zIndex: zIndex.navList,

          // Fix chrome mobile url bar bug
          top: '-5em',
          paddingTop: '5em',
          textAlign: 'center',

          '::before': {
            position: 'static',
          },

          '.nav--no-fixed &': {
            background: colors.greenWater,
          },
        },
      };
    }

    if (!isOpen) {
      stateStyle = {
        [medias.maxWidth(breakpoints.l)]: {
          height: '98px',
        },
      };
    }

    return stateStyle;
  },
);

export const Container = styled.div(
  {
    position: 'relative',
    zIndex: zIndex.navList,
    [medias.maxWidth(breakpoints.l)]: {
      display: 'none',
    },
  },
  ({ isOpen }) => isOpen && {
    [medias.maxWidth(breakpoints.l)]: {
      width: '100%',
      height: '100%',
      background: `rgba(${colorsRGB.black}, .12)`,
      padding: '4em 0',
      overflow: 'auto',
      display: 'block',
    },
    [medias.maxWidth(breakpoints.s)]: {
      display: 'flex',
    },
  },
);

export const List = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin: 3em 3em auto;
  overflow: hidden;

  /* @medias */
  ${medias.maxWidth(breakpoints.xl)} {
    padding-left: 0;
  }
  ${medias.maxWidth(breakpoints.l)} {
    flex-wrap: wrap;
    justify-content: center;
  }
  ${medias.maxWidth(breakpoints.s)} {
    font-size: 0.5em;
    margin: auto 3em auto;
  }
`;

export const Item = styled.li(
  {
    height: NAV_HEIGHT,
    margin: '0 .2em',
  },
  ({ navId, isOpen, isMobile }) => {
    let style = {};

    if (isOpen) {
      // Props
      const isSignup = navId === 'signup';

      style = {
        [medias.maxWidth(breakpoints.l)]: {
          display: 'block',
          fontSize: '1.6em',
          margin: '.4em 0',
          width: '100%',
          height: isMobile ? 'fit-content' : MOBILE_NAV_HEIGHT,

          ...(isSignup && {
            marginLeft: '1em',
            marginRight: '1em',
            height: 'auto',
          }),
        },
      };
    }

    const styleByItem = {
      // Hide home between 900px && 1200px
      home: {
        [medias.betweenWidth(breakpoints.l, breakpoints.xxl)]: {
          display: 'none',
        },
      },

      signup: {
        paddingTop: 8,
        marginLeft: '.5em',
        order: 5,
      },
    };

    return {
      ...style,
      ...styleByItem[navId],
    };
  },
);

export const Close = styled.div`
  ${medias.maxWidth(breakpoints.l)} {
    /* Close button for mobile */
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    padding: 0.1em;
    color: white;
    font-size: 3em;
    line-height: 2;
    width: 2em;
    height: 2em;
    text-align: center;
  }
`;

export const Logo = styled.div(
  {
    content: '""',
    cursor: 'pointer',
    position: 'absolute',
    width: '20em',
    height: NAV_HEIGHT,
    ...utils.backSvgFill(logoMomentum, 'no-repeat center'),

    // Style on `Pages`
    left: '3em',
    top: '0em',
    [medias.maxWidth(breakpoints.xxxl)]: {
      width: '17em',
    },
    [medias.maxWidth(breakpoints.xxl)]: {
      width: '16em',
    },
    [medias.maxWidth(breakpoints.xl)]: {
      // Short logo
      ...utils.backSvgFill(pictoMomentum, 'no-repeat center'),
      width: '6em',
    },
    [medias.maxWidth(breakpoints.l)]: {
      marginTop: '0.8em',
    },
  },
  ({ isLoading }) => isLoading && {
    animation: `${spin} .6s`,
  },
);
