/*
 * Package Import
 */

/*
 * Local Import
 */
import { colors } from 'src/themes';
import { createMain } from 'src/components/Main';

/*
 * Components
 */
export default createMain({
  background: colors.momentumBlack,
});
