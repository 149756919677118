/*
 * Package Import
 */
import { keyframes as themeKeyframes } from '@oclock/theme';
import { colorsRGB } from 'src/themes';

/*
 ******************************
 *
 *     Custom Keyframes
 *
 ******************************
 */

// Fade In / Fade Out
const fadeInBottomCenter = {
  '0%': {
    transform: 'translate3d(-50%, 1em, 0) scale3d(.8, .8, 1)',
    opacity: 0,
  },
};

const fadeScaleIn = {
  '0%': {
    opacity: 0,
    transform: 'scale3d(1.15, 1.15, 1)',
  },
};

// Scale In / Scale Out
const scaleIn = {
  '0%': { transform: 'scale3d(0, 1, 1)' },
};

const scaleOut = {
  '100%': { transform: 'scale3d(0, 1, 1)' },
};

// Shake
const shake = {
  '20%': { transform: 'scale3d(1.05, 1.05, 1)' },
  '40%': { transform: 'scale3d(.97, .97, 1)' },
  '60%': { transform: 'scale3d(1.01, 1.01, 1)' },
  '80%': { transform: 'scale3d(.99, .99, 1)' },
};

const shakeLong = {
  '2%': { transform: 'scale3d(1.1, 1.1, 1)' },
  '4%': { transform: 'scale3d(.94, .94, 1)' },
  '6%': { transform: 'scale3d(1.04, 1.04, 1)' },
  '8%': { transform: 'scale3d(.98, .98, 1)' },
  '10%': { transform: 'none' },
};

const twistShake = {
  '20%': { transform: 'rotate(15deg) scale3d(1.1, 1.1, 1)' },
  '40%': { transform: 'rotate(-20deg) scale3d(.95, .95, 1)' },
  '60%': { transform: 'rotate(15deg) scale3d(1.02, 1.02, 1)' },
  '80%': { transform: 'rotate(-10deg) scale3d(.99, .99, 1)' },
};

const twistShakeLong = {
  '2%': { transform: 'rotate(15deg) scale3d(1.1, 1.1, 1)' },
  '4%': { transform: 'rotate(-20deg) scale3d(.95, .95, 1)' },
  '6%': { transform: 'rotate(15deg) scale3d(1.02, 1.02, 1)' },
  '8%': { transform: 'rotate(-10deg) scale3d(.99, .99, 1)' },
  '10%': { transform: 'none' },
};

// Wave (Lms Preview)
const wave = {
  '0%': { transform: 'scale(0)' },
  '50%': { transform: 'scale(.5)', opacity: 1 },
  '100%': { transform: 'scale(1)', opacity: 0 },
};

// Back & forth
const backforthRight = {
  '50%': { transform: 'translate3d(.15em, 0, 0)' },
};

const backforthBottom = {
  '50%': { transform: 'translate3d(0, .15em, 0)' },
};

// Overlay
const overlayBg = {
  '100%': { background: 'rgba(10, 8, 0, 0.85)' },
};

// Jump
const jump = {
  '0%': {
    transform: 'none',
  },
  '20%': {
    transform: 'translateY(-.2em)',
  },
  '40%': {
    transform: 'none',
  },
};

const jump7 = {
  '0%': {
    transform: 'none',
    opacity: '.25',
  },
  '6%': {
    transform: 'translateY(-.5em)',
    opacity: '.8',
    filter: 'grayscale(0)',
  },
  '12%': {
    transform: 'none',
    opacity: '.25',
  },
  '24%': {
    filter: 'grayscale(100%)',
  },
};

// toDark
const toDark = {
  '100%': {
    background: `rgba(${colorsRGB.skyDark}, .6)`,
  },
};

// Wok
const animWok = {
  main: {
    '0%': {
      transform: 'rotate(10deg)',
    },
    '50%': {
      transform: 'rotate(5deg)',
    },
    '100%': {
      transform: 'rotate(10deg)',
    },
  },
  huge: {
    '0%': {
      transform: 'rotate(10deg) scale(.8)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.8)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.8)',
    },
  },
  xxxl: {
    '0%': {
      transform: 'rotate(10deg) scale(.7)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.7)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.7)',
    },
  },
  xxl: {
    '0%': {
      transform: 'rotate(10deg) scale(.6)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.6)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.6)',
    },
  },
  l: {
    '0%': {
      transform: 'rotate(10deg) scale(.4)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.4)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.4)',
    },
  },
  s: {
    '0%': {
      transform: 'rotate(10deg) scale(.3)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.3)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.3)',
    },
  },
  xs: {
    '0%': {
      transform: 'rotate(10deg) scale(.25)',
    },
    '50%': {
      transform: 'rotate(5deg) scale(.25)',
    },
    '100%': {
      transform: 'rotate(10deg) scale(.25)',
    },
  },
};

const animTechnos = (style, name) => {
  const { bottom, transform } = style;
  const pixel = parseInt(bottom.slice(0, -2), 10);
  const rotate = parseInt(
    transform.substring(
      transform.lastIndexOf('(') + 1,
      transform.lastIndexOf('deg'),
    ),
    10,
  );
  const move = {
    js: -10,
    css: 20,
    symfo: -30,
    php: -25,
    html: 10,
    react: 35,
  };

  return {
    '0%': {
      bottom,
      transform,
    },
    '50%': {
      bottom: `${pixel + 50}px`,
      transform: `rotate(${rotate + move[name]}deg)`,
    },
    '100%': {
      bottom,
      transform,
    },
  };
};

/*
 * Export
 */
export const animations = {
  /*
   * Keyframes from `@oclock/theme`
   */
  ...themeKeyframes,

  /*
   * Custom Keyframes
   */

  // Fade In / Fade Out
  fadeScaleIn,
  fadeInBottomCenter,

  // Scale In / Scale Out
  scaleIn,
  scaleOut,

  // Shake
  shake,
  shakeLong,
  twistShake,
  twistShakeLong,

  // Wave (Lms Preview)
  wave,

  // Back & forth
  backforthRight,
  backforthBottom,

  // Overlay
  overlayBg,

  // Jump
  jump,
  jump7,

  // toDark
  toDark,

  // Wok
  animWok,
  animTechnos,
};
