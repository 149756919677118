/*
 * Package Import
 */

/*
 * Local Import
 */
import pages from 'src/routes/data/pages';

/*
 * Export
 */
export default () => {
  pages.forEach((route) => {
    if (route.component && typeof route.component.load === 'function') {
      route.component.load();
    }
  });
};
