/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import homeBg from 'assets/files/images/home/tempBackgroundHome.png';

/*
 * Components
 */
export const Container = styled.div({
  height: '39em',
  marginTop: '98px',
  padding: '11em 0 0 8em',
  ...utils.backSvgFill(homeBg),
  backgroundSize: 'contain',
  backgroundPosition: 'right bottom',

  [medias.maxWidth(breakpoints.huge)]: {
    backgroundSize: '80%',
  },
  [medias.maxWidth(breakpoints.xl)]: {
    padding: '4em 2em',
    backgroundSize: '120%',
  },
  [medias.maxWidth(breakpoints.m)]: {
    fontSize: '0.8em',
    backgroundSize: '140%',
  },
  [medias.maxWidth(breakpoints.s)]: {
    backgroundSize: '180%',
  },
  [medias.maxWidth(breakpoints.xs)]: {
    backgroundSize: '210%',
  },
});

export const Title = styled.h1({
  textTransform: 'uppercase',
  letterSpacing: 0,
  font: `${weights.bold} 2.4em/1.2 Quicksand`,

  [medias.maxWidth(breakpoints.xl)]: {
    fontSize: '2em',
    textAlign: 'center',
  },
  [medias.maxWidth(breakpoints.l)]: {
    fontSize: '1.7em',
    padding: '1.5em 1em 0',
  },
  [medias.maxWidth(breakpoints.s)]: {
    fontSize: '1.5em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const SubTitle = styled.div({
  color: 'white',
  margin: '2em 0',
  width: '35em',
  font: `${weights.bold} 1em/1.5 Lato`,

  [medias.maxWidth(breakpoints.xl)]: {
    margin: '2em auto',
    padding: '0 10%',
    width: '100%',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '0',
    fontSize: '0.9em',
  },
});
