/*
 * Package Import
 */
import React /* , { useState } */ from 'react';

/*
 * Local Import
 */
import maquette from 'assets/files/images/program/maquette.png';
// import { testimonies } from './data';
import * as S from './style';

/*
 * Component
 */
const Teaching = () => (
  /**
   * State
   */
  // const [activeTestimony, setActiveTestimony] = useState(testimonies[0]);

  // // handler
  // const handleClick = job => () => {
  //   const clickedTestimony = testimonies.find(
  //     testimony => testimony.job === job,
  //   );
  //   setActiveTestimony(clickedTestimony);
  // };

  // eslint-disable-next-line implicit-arrow-linebreak
  <>
    {/* <S.Title>
      Nos intervenant
      <br />
      Les passeurs de <S.Highlight>passions</S.Highlight>
    </S.Title> */}
    {/* Commented while we still don’t have last testimonies */}
    {/* <S.Job>
        <S.Insert>
          <S.Name>{activeTestimony.name}</S.Name>
          <S.Position>{activeTestimony.job}</S.Position>
          <hr />
          <S.Text>{activeTestimony.text}</S.Text>
        </S.Insert>
        <S.Menu>
          {testimonies.map(({ job }) => (
            <S.Item key={job}>
              <S.Link
                onClick={handleClick(job)}
                isActive={job === activeTestimony.job}
              >
                {job}
              </S.Link>
            </S.Item>
          ))}
        </S.Menu>
      </S.Job> */}
    <S.Class>
      <S.Classroom src={maquette} alt="interface slippers" />
      <S.Home>
        <S.Title as="h4">
          Fais bouger les choses
          <br />
          <S.Highlight>sans bouger de chez toi</S.Highlight>
        </S.Title>
        <S.Text>
          Pas besoin d’aller bien loin pour changer ton destin. Il suffit d’un
          ordinateur et d’une bonne connexion internet. Installe-toi
          confortablement et laisse-toi guider : c’est la magie du
          téléprésentiel ! Une méthode pensée pour permettre à tous de suivre un
          cours à distance, dans les meilleures conditions d’apprentissage et
          d’accompagnement. Deux mots d’ordre : inclusion et accessibilité !
        </S.Text>
      </S.Home>
    </S.Class>
  </>
);

/*
 * Export
 */
export default Teaching;
