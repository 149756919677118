/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import feuTricolore from 'assets/files/images/manifestum/feuTricolore.svg';
import fioleMediateurNumerique from 'assets/files/images/manifestum/fioleMediateurNumerique.svg';
import cupFiole from 'assets/files/images/home/cupFiole.svg';
import pictoOCK from 'assets/files/images/manifestum/pictoOCK.svg';
import heartPixel from 'assets/files/images/manifestum/heartPixel.svg';
import gic from 'assets/files/images/manifestum/gic.png';
import laruche from 'assets/files/images/manifestum/laruche.jpg';
import * as S from './style';

/**
 * Data
 */
export const manifestum = [
  {
    logo: feuTricolore,
    title: 'Circulez, tout va bien.',
    texts: [
      <S.Paragraph>
        Notre manifeste à nous, c’est pour dire que ça va plutôt bien. Pour
        preuve, des chiffres récents viennent de tomber : plus de 750 000 postes
        dans le numérique n’ont pas été pourvus l’année dernière, dans toute
        l’Europe. Rendez-vous compte du potentiel !
      </S.Paragraph>,
      <S.Paragraph>
        Mieux encore, pas moins de 2 millions d’emplois ont été créés par
        l’économie du numérique depuis plus de 10 ans. Donc oui, on le dit dans
        ce Manifestum : ça va plutôt bien. Alors, quand on dit que ça va bien,
        en vérité, on exagère un peu. Car oui, ça va bien. Mais pas pour tout le
        monde.
      </S.Paragraph>,
      <S.Paragraph>
        En France, 963 000 jeunes âgés entre 16 et 25 ans demeurent sans
        qualification ni emploi. Une statistique bien curieuse. Comment se
        fait-il que nous nous retrouvions dans cette situation alors que nous
        disposons d’autant d’emplois non pourvus, de toutes ces écoles qui
        pullulent et de tout cet argent investi dans la formation ?
      </S.Paragraph>,
      <S.Paragraph>
        Oui, les portes d’entrée vers ces nouveaux métiers sont nombreuses et
        l’on pourrait hâtivement penser qu’il suffirait de distribuer les clés
        pour que le problème soit résolu. Mais quand une porte vous apparaît
        sans serrure ni poignée, ce n’est ni plus ni moins qu’un mur. Cette
        métaphore qu’apprécierait notre serrurier est simplement là pour mettre
        en évidence une idée : si l’on veut réduire l’inégalité des chances dans
        le numérique, révélons d’abord l’existence de ces métiers.
      </S.Paragraph>,
      <S.Paragraph>
        Donc on le répète : oui, ça va plutôt bien. Et c’est bien pour ça que
        l’on se nomme Momentum. Avec un secteur du numérique aussi mature, des
        conditions économiques d’autant plus favorables et des infrastructures
        d’apprentissage adaptées à tous, on se dit que les feux n’ont jamais été
        aussi verts pour aider ces milliers de jeunes à trouver leur voie.
      </S.Paragraph>,
    ],
  },
  {
    logo: fioleMediateurNumerique,
    complementaryLogo: cupFiole,
    title: 'Tester pour mieux douter.',
    texts: [
      <S.Paragraph>
        Pour toutes les grandes décisions que nous avons à prendre dans nos
        vies, nous prenons le temps de peser le pour et le contre, d’essayer,
        d’expérimenter. Vous n’imagineriez pas acheter ou même louer un
        appartement sans le visiter, ni même de vous marier avec quelqu’un sans
        l’avoir vraiment rencontré. Même pour un simple vêtement, vous l’essayez
        avant.
      </S.Paragraph>,
      <S.Paragraph>
        Alors, pourquoi ne pas faire de même pour un métier avant de le choisir
        ?
      </S.Paragraph>,
      <S.Paragraph>
        C’est exactement ce que propose Momentum : offrir à tous ces jeunes qui
        n’ont pas encore trouvé leur voie, la possibilité de le faire en
        essayant plusieurs métiers du numérique. Avec la plateforme en ligne que
        nous avons conçue, des centaines de jeunes pourront se frotter à
        plusieurs domaines du numérique grâce à des ateliers animés en direct
        par des professionnels.
      </S.Paragraph>,
      <S.Paragraph>
        Un format qui favorise l’inclusion car exclusivement à distance et donc
        ouvert aux zones rurales, aux individus en situation de handicap,
        souffrant de contraintes personnelles ou subissant de plein fouet un
        contexte sanitaire paralysant.
      </S.Paragraph>,
      <S.Paragraph>
        Le numérique étant un secteur intimidant et encore trop nébuleux pour le
        grand public, l’objectif de Momentum est d’apporter à toutes ces
        personnes un chemin possible qui leur était interdit ou qu’ils se
        refusaient instinctivement. Pour cela, on se repose sur une idée
        essentielle : la passion. Cet élément déclencheur de tout choix
        professionnel et qui pourtant peine à trouver une résonance chez
        certains jeunes car ils ne voient pas la possibilité de transformer
        cette passion en vocation.
      </S.Paragraph>,
      <S.Paragraph>
        La mission de Momentum, c’est de les aider à franchir ce pas.
      </S.Paragraph>,
    ],
  },
  {
    logo: pictoOCK,
    title: 'Enchanté nous c’est O’clock.',
    texts: [
      <S.Paragraph>
        Oui, parce qu’avant d’être Momentum, nous sommes O’clock, une école
        spécialisée dans l’apprentissage du développement web. Au fur et à
        mesure des années, nous avons fait la rencontre de centaines d’étudiants
        qui ont découvert une passion pour le numérique sur le tard, pensant que
        ce chemin leur était refusé.
      </S.Paragraph>,
      <S.Paragraph>
        Ce constat nous a ouvert les yeux : il est plus qu’important, au-delà de
        former des personnes à un métier, de faire de la pédagogie autour du
        domaine du numérique et surtout à destination des personnes qui en sont
        le plus éloignées. Notre expérience nous a également démontré que le
        numérique est une source intarissable d’opportunités qui correspondent à
        des compétences et des sensibilités diverses. Le goût pour l’analyse, le
        développement, l’écriture, le graphisme, la gestion : tous ces domaines
        trouvent écho dans le secteur du numérique. Ce qui en fait un eldorado
        pour toutes les personnes qui portent une passion, sans même savoir dans
        quel métier elles pourraient l’exprimer.
      </S.Paragraph>,
      <S.Paragraph>
        Pour aider à faire ce pas vers une future vocation, O’clock a mis en
        place sa technologie de salle de classe virtuelle développée et pensée
        pour rendre l’expérience d’apprentissage et de découverte la plus riche
        possible.
      </S.Paragraph>,
    ],
  },
  {
    logo: heartPixel,
    title: 'Comment nous aider ?',
    texts: [
      <S.Paragraph>
        Évidemment qu’un projet aussi ambitieux avec un impact social aussi
        grand, ne peut pas se réaliser qu’à la seule force de nos poignets.
      </S.Paragraph>,
      <S.Paragraph>
        Momentum se veut être entouré de partenaires qui nous aident à mettre en
        œuvre cette volonté de réduire les inégalités d’accès au métier du
        numérique. En premier lieu, les partenaires historiques qui ont
        contribué à ce que Momentum sorte de terre.
      </S.Paragraph>,
      <S.Paragraph>
        Il y a tout d’abord Google, qui nous a permis via son concours “Google
        Impact Challenge” de mettre notre projet sur pieds, qui demeurait
        jusqu’alors uniquement sur papier. Peu de temps après, La Ruche, pour
        qui l’entrepreneuriat social est prioritaire, à décidé de nous
        accompagner dans le cadre de notre développement.
      </S.Paragraph>,
      <S.LogoContainer>
        <S.Logo src={gic} alt="google impact challenge" />
        <S.Logo src={laruche} alt="La ruche" />
      </S.LogoContainer>,
      <S.Paragraph>
        Mais ce n’est pas tout. Pour fonctionner, Momentum a besoin d’un ancrage
        territorial grâce aux missions locales et aux associations de terrains
        pour faire connaître notre projet auprès des jeunes.
      </S.Paragraph>,
      <S.Paragraph>
        Ces partenaires peuvent être également des formations et des écoles pour
        permettre de s’orienter durablement après s’être frotté à nos ateliers
        découverte. Enfin, l’aide des entreprises est évidemment cruciale car
        elles offrent une perspective d’avenir concrète à tous ces jeunes qui
        auront enfin trouvé une voie professionnelle qui leur plaira.
      </S.Paragraph>,
      <S.Paragraph>
        Vous l’aurez compris, Momentum s’inscrit pleinement dans ce parcours de
        retour à l’emploi dont chacun de nos partenaires est un maillon
        essentiel à sa réussite !
      </S.Paragraph>,
    ],
  },
];
