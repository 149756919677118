/*
 * Npm import
 */

/*
 * Local import
 */
import createMemo from 'src/modules/memoization';
import paths from 'src/routes/data/paths';

/*
 * Datas
 */

/*
 * Code
 */

/**
 * Get path object
 * @param {array} route - key or array of keys in paths object
 * @return {object} - path object for this route
 */
const getPath = createMemo((routes) => {
  // String or array?
  const routesArray = typeof routes === 'string' ? [routes] : routes;

  // Path object
  let path = paths;

  // For each route
  const found = routesArray.every((route) => {
    if (path && path.children) {
      path = path.children[route];
    }
    else {
      path = paths[route];
    }
    return path;
  });

  // Collapse to a string
  const result = found && path;

  // Check
  if (!result) {
    // eslint-disable-next-line no-console
    console.error(`getPath: Route ${routesArray} does not exist.`);
  }

  // Store & return
  return result;
});

/*
 * Exports
 */

/**
 * Get pathname
 * @param {string|array} route - key or array of keys in paths object
 * @return {string} - route pathname
 */
export const getPathname = createMemo((routes) => {
  // String or array?
  const routesArray = typeof routes === 'string' ? [routes] : routes;

  // For storing the parent route, and pathname
  let parent;
  let pathname = '';

  // For each route
  const found = routesArray.every((route) => {
    // We take the pathname : from paths or from parent for children
    if (parent) {
      const { children } = parent;
      if (children && children[route] && children[route].pathname) {
        pathname += children[route].pathname;
        parent = children[route];
        return true;
      }
    }
    else if (paths[route] && paths[route].pathname) {
      pathname += paths[route].pathname;
      parent = paths[route];
      return true;
    }
    return false;
  });

  // If found, return pathname
  const result = found && pathname;

  // Check
  if (!result) {
    // eslint-disable-next-line no-console
    console.error(`getPathname: A Route ${routesArray} does not exist.`);
  }

  // Return
  return result;
});

/**
 * Hash
 * @param {object} - routes and hash
 * @return {string} - hash value for these params
 */
export const getHash = ({ route, hash }) => {
  const path = getPath(route);
  return path && path.hash && path.hash[hash];
};

/**
 * Get Post path
 * @param {string|array} routes
 * @return {string} - post path for this route
 */
export const getPost = (routes) => {
  const path = getPath(routes);
  return path && path.post;
};

/**
 * Get Id
 * @param {string|array} routes
 * @return {string} - id for this route
 */
export const getId = (routes) => {
  const path = getPath(routes);
  return path && path.id;
};

/**
 * Get Params
 * @param {string|array} routes
 * @return {array} - params for this route
 */
export const getParams = (routes) => {
  const path = getPath(routes);
  return path && path.params;
};

/**
 * Get Link from route
 * Intended for <Link> and <Route> component
 * @param {string|object} arg
 * @return {object}
 */
export const getLink = (route) => {
  // Route object
  let routeObject;
  if (Array.isArray(route) || typeof route === 'string') {
    routeObject = { route };
  }
  else {
    routeObject = { ...route };
  }

  // Vars
  const link = {};

  // Route or pathname?
  if (routeObject.route) {
    link.pathname = getPathname(routeObject.route);
  }
  else {
    // eslint-disable-next-line no-console
    console.error('getLink: invalid routeObject', routeObject);
  }

  // Hash
  if (routeObject.hash) {
    link.hash = `#${getHash(routeObject)}`;
  }

  // Return
  return link;
};

/**
 * Get Route from pathname
 * @param {string} pathname
 * @return {string} - route
 */
export const getRouteKeyByPathname = (pathname) => {
  // Get parts
  const parts = pathname.slice(1).split('/');

  // First, search in paths
  let searchPaths = paths;

  // To be returned
  let routeKey = false;

  // For each part
  parts.every((part) => {
    // Search on path
    if (searchPaths) {
      Object.keys(searchPaths).some((route) => {
        const path = searchPaths[route];
        // If pathname is found, then save id and children
        if (path.pathname === `/${part}`) {
          routeKey = route;
          searchPaths = path.children;
          return true;
        }
        return false;
      });
      return true;
    }
    return false;
  });

  return routeKey;
};

/**
 * getRedirectHandler
 * @description create the redirection url for this route
 * @param {string|array} routes
 * @return {function} - redirect handler
 */
export const getRedirectHandler = (routes) => {
  const routePathname = getPathname(routes);
  const routeParams = getParams(routes);

  // If route has param, return a modifier function
  if (routeParams) {
    return (params, redirectParams = {}) => {
      let path = routePathname;
      routeParams.forEach((param) => {
        // If redirect params are given, we take them
        // Useful to rewrite a malformed blog post name for instance
        const value = redirectParams[param] || params[param];
        path = path.replace(`:${param}`, value);
      });
      return path;
    };
  }

  // If not, return just a dummy function
  return () => routePathname;
};

/*
 * Export default
 */
export default paths;
