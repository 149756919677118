/*
 * zIndex
 */
export const zIndex = {
  /*
   * Global
   */

  // In & Out
  in: 1,
  out: 0,

  // Nav
  underNav: 9,
  nav: 10,
  navBefore: 11,
  navList: 12,
};
