/*
 * Package Import
 */
import React from 'react';
import { useLocation } from 'react-router-dom';

/*
 * Local Import
 */
import { getLink } from 'src/routes/utils/paths';
import logoMomentum from 'assets/files/images/common/logoMomentumWhite.svg';
import logoOclock from 'assets/files/images/common/logoOclock.svg';
import facebook from 'assets/files/images/common/facebook.svg';
import linkedin from 'assets/files/images/common/linkedin.svg';
import twitter from 'assets/files/images/common/twitter.svg';
import {
  FB_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  CONTACT_MAIL,
} from 'src/data/constants';
import { scrollTo } from '~adaptors';
import * as S from './style';

/*
 * Component
 */
const links = [
  { link: 'index', label: 'Home' },
  { link: ['jobs', 'webdev'], label: 'Nos métiers' },
  { link: 'program', label: 'Le programme' },
  { link: 'manifestum', label: 'Manifestum' },
];

const legalLinks = [
  { link: 'rgpd', label: 'RGPD' },
  { link: 'mentions', label: 'Mentions légales' },
];

const socialNetworks = [
  {
    alt: 'facebook',
    url: FB_URL,
    logo: facebook,
  },
  {
    alt: 'twitter',
    url: TWITTER_URL,
    logo: twitter,
  },
  {
    alt: 'linkedin',
    url: LINKEDIN_URL,
    logo: linkedin,
  },
];

const Footer = () => {
  // hooks
  const { pathname } = useLocation();

  // handler
  const handleClick = link => () => {
    const toLink = getLink(link);

    // if the page we're going to, is the same as the one we're on
    if (pathname === toLink.pathname) {
      const opts = {};
      const target = document.body;

      // Let's go !
      scrollTo(target, opts);
    }
  };

  return (
    <S.Footer>
      <S.Container>
        <S.Links aria-label="Liens">
          {links.map(({ link, label }) => (
            <S.Link
              key={link}
              onClick={handleClick(link)}
              to={getLink(link)}
              isActive={getLink(link).pathname === pathname}
            >
              {label}
            </S.Link>
          ))}
        </S.Links>
        <S.LogoContainer>
          <S.Momentum>
            <S.MomentumLogo src={logoMomentum} alt="logo momentum" />
          </S.Momentum>
          <S.Oclock>
            Un projet créé par
            <S.ExternalLink href="https://oclock.io/" target="_blank">
              <S.LogoOclock src={logoOclock} alt=" logo O’clock" />
            </S.ExternalLink>
          </S.Oclock>
        </S.LogoContainer>
        <S.Contact>
          <S.Links aria-label="Liens">
            {legalLinks.map(({ link, label }) => (
              <S.Link
                key={link}
                onClick={handleClick(link)}
                to={getLink(link)}
                isActive={getLink(link).pathname === pathname}
              >
                {label}
              </S.Link>
            ))}
          </S.Links>
          <S.Mail href={`mailto:${CONTACT_MAIL}`}>{CONTACT_MAIL}</S.Mail>
          <S.SocialNetworks>
            {socialNetworks.map(({ url, logo, alt }) => (
              <S.ExternalLink key={alt} href={url} target="_blank">
                <S.SocialLogo src={logo} alt={alt} />
              </S.ExternalLink>
            ))}
          </S.SocialNetworks>
        </S.Contact>
      </S.Container>
    </S.Footer>
  );
};

/*
 * Export
 */
export default Footer;
