/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, medias, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.div({
  minHeight: '34em',
  padding: '22em 5em 1em',

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '22em 2em 1em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '34em 2em 1em',
  },
});

export const Argument = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 0 5em',
  '&:first-of-type': {
    margin: '0 0 7em',
  },
  [medias.minWidth(breakpoints.xxxl)]: {
    '&:nth-of-type(even)': {
      flexDirection: 'row-reverse',
    },
  },

  [medias.maxWidth(breakpoints.xxxl)]: {
    flexDirection: 'column-reverse',
  },
});

export const Summary = styled.div({
  width: '50%',

  [medias.maxWidth(breakpoints.xxxl)]: {
    width: '100%',
  },
});

export const Title = styled.h4({
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  margin: '0 0 1em',
});

export const Text = styled.div({
  lineHeight: '1.4em',
});

export const Paragraph = styled.p({
  margin: '0 0 0.8em',
});

export const Illustration = styled.img({
  width: '40%',
  height: 'fit-content',
  background: 'grey',

  [medias.maxWidth(breakpoints.xxxl)]: {
    height: '100%',
    width: '100%',
    margin: '0 0 2em',
  },
});
