/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Data
 */
export const form = {
  fields: [
    {
      type: 'text',
      placeholder: 'Nom',
      fieldName: 'lastname',
      autoComplete: 'family-name',
    },
    {
      type: 'text',
      placeholder: 'Prénom',
      fieldName: 'firstname',
      autoComplete: 'given-name',
    },
    {
      type: 'email',
      placeholder: 'Mail',
      fieldName: 'email',
      autoComplete: 'email',
      autoCapitalize: 'off',
    },
    {
      type: 'tel',
      placeholder: 'Numéro de téléphone',
      fieldName: 'phone',
      autoComplete: 'tel',
      autoCapitalize: 'off',
    },
    {
      type: 'text',
      placeholder: 'Rue',
      fieldName: 'street',
      autoComplete: 'street-address',
      autoCapitalize: 'off',
    },
  ],
  check1: [
    {
      type: 'text',
      fieldName: 'lastname',
    },
    {
      type: 'text',
      fieldName: 'firstname',
    },
    {
      type: 'email',
      fieldName: 'email',
    },
    {
      type: 'tel',
      fieldName: 'phone',
    },
    {
      type: 'text',
      fieldName: 'street',
    },
    {
      type: 'text',
      fieldName: 'birthdate',
    },
    {
      type: 'text',
      fieldName: 'city',
    },
    {
      type: 'text',
      fieldName: 'zip',
    },
  ],
  step2: [
    [
      'Aucun (Infra Bac)',
      'Niv 4 (Bac)',
      'Niv 5 (Bac+2)',
      'Niv 6 (Bac+3)',
      'Niv 7 (Bac+5)',
      'Niv 8 (Bac+8)',
    ],
    ['Oui', 'Oui et bénéficiaire de la RQTH', 'Non'],
    [
      'Étudiant',
      'Travail temporaire/intérim',
      'Salarié CDD',
      'Salarié CDI',
      'Demandeur d’emploi',
      'Demandeur d’emploi longue durée (plus de 12 mois)',
      'Demandeur d’emploi très longue durée (plus de 24 mois)',
    ],
    [
      'Développeur web',
      'Designer web',
      'Community manager',
      'Développeur cybersécurité',
      'Médiateur numérique',
      'Pour l’instant, je n’ai pas de préférence',
    ],
    ['oui', 'non'],
    [
      'Pub / Article (y compris réseaux sociaux)',
      'O’clock',
      'Bouche à oreille / Réseau',
      'Salon, Forum',
      'Pôle Emploi',
      'Cap Emploi',
      'Agefiph',
      'Mission locale',
      'Autre structure d’insertion',
      'Autre',
    ],
    [
      'Non',
      'RSA',
      'ASS',
      'AER-R',
      'ATA',
      'ADA',
      'AAH',
      'ASI',
      'AV',
      'ASV/ASPA',
      'RSO',
    ],
  ],
  cta: 'OK',
  errors: {
    fields: 'Oops, les champs ne sont pas bien remplis !',
    alreadyRegistered: `
      Il semblerait que tu aies déjà commencé ton Chemin Vers O’clock !
      Jette un oeil à ta boîte e-mail, nous venons de te renvoyer le lien
      vers le formulaire d’inscription.
    `,
  },
};
