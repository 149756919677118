/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';

// Wrappers
import Main from 'src/components/SignUp/Main';
import Wrapper from 'src/components/SignUp/Wrapper';
import Section from 'src/components/Section';

// Page component
import Register from 'src/components/Register';

// Datas
import metas from './metas';

/*
 * Component
 */
const SignUpContainer = () => (
  <Wrapper>
    <Head {...metas} />
    <Nav />
    <Main>
      <Section color="black">
        <Register />
      </Section>
    </Main>
    <Footer only />
  </Wrapper>
);

/*
 * Export
 */
export default SignUpContainer;
