/*
 * Local import
 */
import getDuck from 'src/store/helpers/value';

/*
 * Code
 */
const { reducer, actionCreator } = getDuck({
  initialState: typeof window === 'undefined' ? null : window.innerWidth,
  type: 'SIZE_CHANGE',
});

/*
 * Action Creators
 */
export const changeSize = actionCreator;

/*
 * Reducer
 */
export default reducer;
