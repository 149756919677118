/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, colorsRGB, utils } from 'src/themes';
import man from 'assets/files/images/signup/man.svg';
import woman from 'assets/files/images/signup/woman.svg';
import checked from 'assets/files/images/signup/checked.svg';

/*
 * Components
 */
export const SignupGender = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em auto 0;
`;

export const Gender = styled.label(
  {
    background: 'grey',
    border: '2px solid transparent',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '.85em',
    margin: '0 .5em',
    letterSpacing: '0.02em',
    padding: '.9em .9em .5em',
    position: 'relative',
    opacity: '0.5',
  },
  ({ isActive, isFocus }) => {
    let style = {};

    if (isActive) {
      style = {
        background: colors.momentumOrange,
        opacity: 1,

        '::before': {
          content: '""',
          borderStyle: 'solid',
          borderColor: 'transparent white transparent transparent',
          borderWidth: '0 45px 45px 0',
          position: 'absolute',
          right: '-2px',
          top: '-2px',
          transition: 'opacity .2s ease-in-out',
        },

        '::after': {
          content: '""',
          position: 'absolute',
          top: '4px',
          right: '4px',
          width: '16px',
          height: '16px',
          ...utils.backSvgFill(checked),
        },
      };
    }

    if (isFocus) {
      style = {
        ...style,
        borderColor: `rgba(${colorsRGB.black}, .05)`,
      };
    }

    return style;
  },
);

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Image = styled.div(
  {
    width: '4em',
    height: '4.1em',
    margin: '0 0.5em 0.25em',

    '::before': {
      content: '""',
    },
  },
  ({ gender }) => ({
    ...utils.backSvgFill(gender === 'woman' ? woman : man),
    backgroundSize: '70%',
    backgroundPosition: 'center',
  }),
);

export const Label = styled.div(({ isActive }) => ({
  background: isActive ? colors.momentumOrange : 'grey',
}));
