/*
 * Local Import
 */

// Helpers
import { getBreadcrumb } from 'src/modules/jsonLD';
// import { getPathname } from 'src/routes/utils/paths';

/*
 * SEO
 */
const seo = {
  title: '',
  description: '',
  og: {
    title: '',
    description: '',
    image: '',
  },
  color: 'default',
};

/*
 * JSON-LD
 */
const jsonLD = [
  // Breadcrumb list
  getBreadcrumb([
    {
      name: 'Manifestum',
      // item: getPathname('manifestum'),
    },
  ]),
];

/*
 * Export
 */
export default { seo, jsonLD };
