/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Cta = ({ data }) => (
  <S.Container>
    <S.Title>
      11 jours, 5 métiers, 1 vocation&nbsp;:
      <br />
      <S.Highlight>tout un programme</S.Highlight>
    </S.Title>
    {data.content.map((datum, index) => (
      <S.KnowMore key={index}>{datum}</S.KnowMore>
    ))}
    <S.Cta to={data.link.to}>{data.link.text}</S.Cta>
  </S.Container>
);

/*
 * PropTypes
 */
Cta.propTypes = {
  data: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Cta;
