/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import pictoMomentumWhite from 'assets/files/images/common/pictoMomentumWhite.svg';

/*
 * Components
 */
export const Container = styled.div({
  height: '34em',
  marginTop: '98px',
});

export const Title = styled.h1({
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  letterSpacing: 0,
  textAlign: 'center',
  padding: '3em 1em 0',
  font: `${weights.bold} 2.4em/1.2 Quicksand`,

  [medias.maxWidth(breakpoints.xl)]: {
    fontSize: '2em',
  },
  [medias.maxWidth(breakpoints.l)]: {
    fontSize: '1.7em',
    padding: '1.5em 1em 0',
  },
  [medias.maxWidth(breakpoints.s)]: {
    fontSize: '1.5em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const Panel = styled.div({
  position: 'relative',
  background: colors.momentumOrange,
  maxWidth: '50em',
  height: '30em',
  color: 'white',
  margin: '8em auto 0',
  padding: '3em 6em',

  '::before': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: '-2.2em',
    textAlign: 'center',
    display: 'block',
    height: '4.4em',
    width: '5.5em',
    zIndex: 1,
    ...utils.backSvgFill(pictoMomentumWhite, 'no-repeat center'),
  },
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: '-1em',
    textAlign: 'center',
    display: 'block',
    height: '2.5em',
    width: '2.5em',
    background: colors.momentumOrange,
  },

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '3em 1em',
  },
  [medias.maxWidth(breakpoints.m)]: {
    margin: '8em 2em 0',
    height: 'fit-content',
    fontSize: '0.8em',
  },
});

export const Tldr = styled.div({
  textAlign: 'center',
  fontWeight: weights.bold,
  letterSpacing: 0,
});

export const Items = styled.div({
  display: 'flex',
  flexFlow: 'column wrap',
  alignContent: 'space-around',
  height: '90%',
  marginTop: '1.6em',
});

export const Item = styled.div(({ icon }) => ({
  position: 'relative',
  fontWeight: weights.bold,
  marginTop: '1em',

  '::before': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    marginLeft: '-2em',
    left: 0,
    right: 0,
    top: 0,
    textAlign: 'center',
    display: 'block',
    height: '1.5em',
    width: '1.5em',
    ...utils.backSvgFill(icon),
  },

  [medias.maxWidth(breakpoints.m)]: {
    marginLeft: '1.6em',
  },
}));

export const List = styled.ul({
  fontWeight: weights.normal,
  marginBottom: '2em',
});

export const Point = styled.li({
  '::before': {
    content: '"–"',
  },
});
