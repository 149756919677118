/*
 * Local Import
 */
import fioleDeveloppeurWeb from 'assets/files/images/home/fioleDeveloppeurWeb.svg';
import cardDevWeb from 'assets/files/images/jobs/cardDevWeb.svg';
import devWebMissions from 'assets/files/images/jobs/devWebMissions.jpg';
import devWebQualite from 'assets/files/images/jobs/devWebQualite.jpg';

/**
 * data
 */
export default {
  title: 'développeur web',
  id: 'webdev',
  card: {
    logo: fioleDeveloppeurWeb,
    missions: [
      'Analyser les besoins du client pour les traduire techniquement',
      'Développer des sites, applications ou logiciels web',
      'Mettre à jour, assurer la maintenance et le suivi des projets réalisés',
    ],
    qualities: ['Esprit analytique', 'Rigoureux', 'Débrouillard'],
    skills: [
      'Maîtriser les langages de développement et programmation',
      'Travailler en équipe',
      'Planifier un projet, analyser un cahier des charges',
    ],
    salary: cardDevWeb,
    evolutions: ['Lead développeur', 'Chef de projet technique'],
    activity: ['Entreprise', 'Agence', 'Freelance'],
  },
  summary: [
    {
      title: 'les missions du développeur web',
      text: [
        'Le rôle du développeur web est de réaliser des sites internet ou des applications web. Dans un premier temps, son travail est d’analyser les besoins d’un client et traduire ces mêmes besoins en code informatique qu’exécutera la machine.',
        'Il est garant de la planification technique du projet et du choix des technologies à employer selon les fonctionnalités exigées. Il est présent tout au long du développement technique, du début jusqu’à la mise en ligne du projet. D’ailleurs, son travail se poursuit également une fois les projets réalisés. Il interviendra pour corriger les bugs et s’occuper de la maintenance de ceux-ci.',
        'Aujourd’hui, il est bien difficile de citer un secteur qui n’a pas besoin de développer une présence sur le web, tant le numérique a profondément changé nos sociétés. Un développeur pourra donc trouver sa place dans les agences et les petites, moyennes ou grandes entreprises, ou se plaire en tant que freelance.',
      ],
      img: devWebMissions,
      alt: 'Les missions du développer web',
    },
    {
      title: 'Les qualités cruciales du développeur web',
      text: [
        'On le verra ensemble lors des ateliers, il y a quelques qualités et compétences requises indispensables pour devenir développeur web. Évidemment, un grand intérêt pour l’informatique, ça paraît logique. Mais pas que. Un esprit d’abstraction, de la rigueur et un brin de débrouillardise sont également essentiels.',
        'Côté technique, il y a plusieurs profils de développeurs web. Certains vont privilégier ce que l’on appelle les technologies Front-end. En gros, tous les langages qui assurent l’interactivité avec l’utilisateur. Par exemple, le CSS et le HTML permettent d’afficher les lignes de textes que vous lisez actuellement. D’autres développeurs vont s’orienter vers le Back-end, la partie qui est “cachée” de l’utilisateur. Cela va de la gestion des données des inscrits au lien entre le site et le serveur. Là aussi, il y a beaucoup de technologies Back-end comme : Symfony, Python, JavaScript etc. Il y a également d’autres développeurs qui vont faire un peu des deux (front et back), on appelle ça être “fullstack”. Multifonction si tu préfères.',
        'Tu l’auras compris, les technologies et langages sont nombreux, cette grande variété contribue à la richesse de ce métier et permet aujourd’hui de se spécialiser dans un domaine très particulier où l’on pourra développer une expertise très recherchée sur le marché.',
      ],
      img: devWebQualite,
      alt: 'Les qualités du développeur web',
    },
  ],
  salary: {
    title: 'Salaire et évolutions du développeur web',
    text:
      'On en vient à la partie croustillante. Le métier de développeur web donne lieu à de multiples possibilités d’évolution. En apprenant d’autres technologies qui permettent d’accroître son champ d’action et de gagner en expertise. Il peut également, avec l’expérience, prétendre à des postes à responsabilités comme la gestion des projets et des équipes techniques. Côté sous, le salaire moyen d’un développeur web junior en France se situe entre 28K et 43K brut par an. Évidemment, ici, on parle d’une moyenne, mais ça dépend de plusieurs critères comme la région dans laquelle il travaille ou encore les technos qu’il utilise, s’il est en télétravail ou en présentiel et son expérience, un dev senior pourra, quant à lui gagner jusqu’à 58K brut par an et 70K s’il devient Lead Dev.',
    evolution: [
      {
        post: 'Développeur web junior',
        salary: 'Entre 28 000€ et 43 000€ par an.',
      },
      {
        post: 'Développeur web confirmé',
        salary: 'Entre 33 000€ et 52 000€ par an.',
      },
      {
        post: 'Développeur web senior',
        salary: 'Entre 37 000€ et 58 000€ par an.',
      },
    ],
  },
  hats: {
    formation: {
      title: 'Les formations pour devenir développeur web',
      text:
        'Si tous les chemins mènent à Rome, ça doit être la capitale du développement web. Aujourd’hui, on peut devenir développeur web avec des organismes de formation en 6 mois donnant lieu à des certifications reconnues par l’État et éligibles aux financements Pôle emploi. Le développement web étant un métier destiné aux “débrouillards” qui apprécient chercher par eux-mêmes, on retrouve beaucoup de professionnels qui ont appris le métier par leurs propres moyens, grâce à des ressources gratuites en libre service sur le web. Sinon, il y a aussi la filière universitaire classique  post-bac en 2 ou 3 ans.',
    },
  },
  cta: {
    content: [
      'Ça t’a donné envie d’en savoir plus ?',
      'Viens explorer le métier de développeur web dans notre programme de découverte !',
    ],
    link: {
      to: '/programme',
      text: 'programme',
    },
  },
};
