/*
 * Local import
 */
import getDuck from 'src/store/helpers/value';


/*
 * Code
 */
const { reducer, actionCreator } = getDuck({
  initialState: '',
  type: 'SECTION_CHANGE',
});


/*
 * Action Creators
 */
export const changeSection = actionCreator;


/*
 * Reducer
 */
export default reducer;
