/*
 * Package Import
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

/*
 * Local Import
 */
import 'src/modules/requestIdle';
import Client from 'src/containers/Client';
import { init as startTracking } from 'src/modules/tracking';

// Preload Chuncks
import preloadChunks from 'src/routes/utils/preload';

/*
 * Code
 */
const html = document.documentElement;
const MOUNT_NODE = document.getElementById('root');

const init = () => {
  html.classList.add('domloaded');

  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

  if (module.hot) {
    module.hot.accept();
  }

  loadableReady(() => {
    // React
    renderMethod(<Client />, MOUNT_NODE);

    // Tracking
    startTracking();
  });
};

// DOM Ready
if (
  document.readyState === 'complete'
  || document.readyState === 'loaded'
  || document.readyState === 'interactive'
) {
  init();
}
else {
  document.addEventListener('DOMContentLoaded', init);
}

// Fully loaded
window.addEventListener('load', () => {
  html.classList.add('loaded');

  // Loads webpack chunks
  requestIdleCallback(preloadChunks);
});
