/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import gic from 'assets/files/images/manifestum/gic.png';
import laruche from 'assets/files/images/manifestum/laruche.jpg';
import * as S from './style';

/*
 * Component
 */
const Pillars = () => (
  <S.Container>
    <S.Title>
      <S.Highlight>Les piliers</S.Highlight> de notre formation
    </S.Title>
    <S.SubTitle>
      Momentum ne s’est pas construit tout seul, chaque partenaire constitue une
      pierre essentielle de ce projet.
      <br />
      Et lorsqu’on veut avoir un impact social aussi important, il faut des
      piliers solides.
    </S.SubTitle>
    <S.LogoContainer>
      <S.Logo src={laruche} alt="La ruche" />
      <S.Logo src={gic} alt="google impact challenge" />
    </S.LogoContainer>
    <S.Cta to="/manifestum">Manifestum</S.Cta>
  </S.Container>
);

/*
 * Export
 */
export default Pillars;
