/* eslint-disable camelcase */
/*
 * Package Import
 */
import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';

/*
 * Local Import
 */
import { validateEmail, validateTel } from 'src/modules/utils';
import Gender from './Gender';
import Select from './Select';
import Step3 from './Step3';
import { form } from './data';
import * as S from './style';

/*
 * Component
 */
const Register = () => {
  /**
   * State
   */
  const [gender, setGender] = useState('woman');
  const [fields, setFields] = useState({
    lastname: '',
    firstname: '',
    email: '',
    phone: '',
    birthdate: '',
    street: '',
    city: '',
    zip: '',
    school_level: '',
    disability: '',
    professionnal: '',
    interest: '',
    availability: '',
    source: '',
    minimal_social: '',
  });
  const [error, setError] = useState([]);
  const [step, setStep] = useState(1);
  const [pending, setPending] = useState(false);

  /**
   * Handlers
   */
  const handleGender = (genderClick) => {
    setGender(genderClick);
  };

  const handleChange = fieldName => (evt) => {
    const { value } = evt.target;
    setFields({
      ...fields,
      [fieldName]: value,
    });
  };

  const handleSelect = (value, fieldName) => {
    setFields({
      ...fields,
      [fieldName]: value,
    });
  };

  const getChoices = array => array.reduce((result, item) => {
    result.push({ label: `${item}` });
    return result;
  }, []);

  /*
   * Test value
   */
  const testValue = (field) => {
    if (field) {
      const { fieldName, type } = field;
      if (fieldName in fields) {
        const value = fields[fieldName];
        switch (type) {
          case 'email':
            if (validateEmail(value) === false) {
              setError(currentErrors => [...currentErrors, fieldName]);
              return false;
            }
            setError(currentErrors => [
              ...currentErrors.filter(err => err !== fieldName),
            ]);
            return true;
          case 'tel':
            if (validateTel(value) === false) {
              setError(currentErrors => [...currentErrors, fieldName]);
              return false;
            }
            setError(currentErrors => [
              ...currentErrors.filter(err => err !== fieldName),
            ]);
            return true;
          default:
            if ((value !== '') === false) {
              setError(currentErrors => [...currentErrors, fieldName]);
              return false;
            }
            setError(currentErrors => [
              ...currentErrors.filter(err => err !== fieldName),
            ]);
            return true;
        }
      }
    }
    return false;
  };

  const changeStep = direction => () => {
    // If the user validates the first step
    if (direction > 0) {
      if (!form.check1.map(testValue).includes(false)) {
        setStep(step + direction);
      }
    }
    // If the user returns to first step
    else {
      setStep(step + direction);
    }
  };

  const checkError = fieldName => !error.includes(fieldName);

  /*
   * Form Submit
   */
  const send = () => {
    const data = qs.stringify({
      gender,
      lastname: fields.lastname,
      firstname: fields.firstname,
      email: fields.email,
      phone: fields.phone,
      birthdate: fields.birthdate,
      street: fields.street,
      city: fields.city,
      zip: fields.zip,
      school_level: fields.school_level,
      disability: fields.disability,
      professionnal: fields.professionnal,
      interest: fields.interest,
      availability: fields.availability,
      source: fields.source,
      minimal_social: fields.minimal_social,
    });
    const config = {
      method: 'post',
      url: 'https://hook.integromat.com/39228s1ki84g3x0fcw1bacdls27rmdlp',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };
    axios(config)
      .then(() => {
        setStep(3);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => (evt) => {
    // Prevent submit
    evt.preventDefault();
    // If error: no need to send a request
    // If pending: no need either, a request has already been sent
    if (!error.length && !pending) {
      // Check
      const {
        school_level,
        disability,
        professionnal,
        interest,
        availability,
        source,
        minimal_social,
      } = fields;
      const valuesToCheck = [
        school_level,
        disability,
        professionnal,
        interest,
        availability,
        source,
        minimal_social,
      ];
      // Check if an answer is empty
      if (valuesToCheck.every(field => field)) {
        send();
        setPending(true);
      }
      // Add error message
    }
  };

  /**
   * Components
   */
  const step1 = () => (
    <>
      <Gender value={gender} onChange={handleGender} />

      {!!error.length && (
        <S.ErrorMessage>
          Oups !
          <br />
          Ton inscription rencontre un petit problème.
        </S.ErrorMessage>
      )}

      {form.fields.map((field, index) => (
        <S.Input
          {...field}
          autoCorrect="off"
          spellCheck="false"
          required
          aria-label={field.placeholder}
          isValidated={checkError(field.fieldName)}
          key={index}
          onChange={handleChange(field.fieldName)}
          value={fields[field.fieldName] || ''}
          data-testid={field.fieldName}
        />
      ))}
      <S.DatePicker
        type="date"
        required
        name="birthdate"
        max="2010-01-01"
        // Fix for safari, does not appear in other browsers
        placeholder="jj/mm/aaa"
        isValidated={checkError('birthdate')}
        value={fields.birthdate}
        onChange={handleChange('birthdate')}
      />
      <S.TownContainer>
        <S.Input
          placeholder="Ville"
          fieldName="city"
          autoCorrect="off"
          spellCheck="false"
          required
          aria-label="Ville"
          isValidated={checkError('city')}
          onChange={handleChange('city')}
          value={fields.city || ''}
          data-testid="city"
        />
        <S.Input
          placeholder="Code Postal"
          fieldName="zip"
          autoCorrect="off"
          spellCheck="false"
          required
          aria-label="Code Postal"
          isValidated={checkError('zip')}
          onChange={handleChange('zip')}
          value={fields.zip || ''}
          data-testid="zip"
        />
      </S.TownContainer>
    </>
  );

  const step2 = () => (
    <>
      <S.SelectContainer>
        {form.step2.map((field, index) => {
          const name = {
            school_level: 'Niveau scolaire',
            disability: 'Handicap',
            professionnal: 'Quelle est ta situation professionnelle ?',
            interest: 'Quel métier t’intéresse le plus dans le programme ?',
            availability: 'Es-tu disponible pendant 11 jours consécutifs ?',
            source: 'Tu nous as connu comment ?',
            minimal_social: 'Es-tu bénéficiaire d’un minima social ?',
          };
          const values = Object.values(name);
          const keys = Object.keys(name);
          const keysIndex = keys[index];

          return (
            <Select
              key={index}
              required
              fieldName={values[index]}
              value={fields[keysIndex] || ''}
              placeholder={values[index]}
              onSelectChange={label => handleSelect(label, keys[index])}
              choices={getChoices(field)}
            />
          );
        })}
      </S.SelectContainer>
      <S.Cta type="submit" step={step}>
        Je m’inscris
      </S.Cta>
    </>
  );

  return (
    <S.Container>
      <S.Title id="contact-form">
        Démarre ton
        <S.Highlight> aventure</S.Highlight>
      </S.Title>
      <S.Beginning>
        C’est ici que tout commence.
        <br />
        Inscris-toi gratuitement et pars à la découverte de ton avenir dès
        maintenant.
      </S.Beginning>

      <S.Form onSubmit={handleSubmit()}>
        {step === 1 && step1()}
        {step === 2 && step2()}
        {step !== 3 && (
          <>
            <S.Stepbar step={step} />
            <S.Step>{step}/2</S.Step>
            {step === 2 && (
              <S.PreviousStep onClick={changeStep(-1)}>
                Étape précédente
              </S.PreviousStep>
            )}
          </>
        )}
      </S.Form>
      {step === 1 && <S.Cta onClick={changeStep(1)}>Suivant</S.Cta>}
      {step === 3 && <Step3 />}
    </S.Container>
  );
};

/*
 * Export
 */
export default Register;
