/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Summary = ({ data }) => (
  <S.Container>
    {data.map(({ title, text, img, alt }, index) => (
      <S.Argument key={index}>
        <S.Summary>
          <S.Title>{title}</S.Title>
          <S.Text>
            {text.map((paragraph, id) => (
              <S.Paragraph key={id}>{paragraph}</S.Paragraph>
            ))}
          </S.Text>
        </S.Summary>
        <S.Illustration src={img} alt={alt} />
      </S.Argument>
    ))}
  </S.Container>
);

/*
 * PropTypes
 */
Summary.propTypes = {
  data: PropTypes.array.isRequired,
};

/*
 * Export
 */
export default Summary;
