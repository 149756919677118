/*
 * Package Import
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Content = ({ intro, blockContent }) => (
  <S.Container>
    {Array.isArray(intro) ? (
      <S.Intro>
        {intro.map((loopIntro, index) => (
          <S.LoopIntro key={index}>{loopIntro}</S.LoopIntro>
        ))}
      </S.Intro>
    ) : (
      <S.Intro>{intro}</S.Intro>
    )}
    {blockContent.map(({ title, content }) => (
      <S.Block key={title}>
        <S.Title>{title}</S.Title>
        {content.map(({ title: contentTitle, paragraph, list }, index) => (
          <Fragment key={index}>
            {contentTitle && <S.SubTitle>{contentTitle}</S.SubTitle>}
            <S.Paragraph>{paragraph}</S.Paragraph>
            {list && (
              <S.Ul>
                {list.map((line, lineIndex) => (
                  <S.Li key={lineIndex}>{line}</S.Li>
                ))}
              </S.Ul>
            )}
          </Fragment>
        ))}
      </S.Block>
    ))}
  </S.Container>
);

Content.propTypes = {
  intro: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  blockContent: PropTypes.array.isRequired,
};

/*
 * Export
 */
export default Content;
