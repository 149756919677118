/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { utils, weights, breakpoints, medias } from 'src/themes';

/*
 * Components
 */
export const Title = styled.h2({
  fontWeight: weights.black,
  letterSpacing: '.12em',
  font: `${weights.normal} 2em/1.3 Quicksand`,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2em 0 1em',

  /* @medias */
  [medias.maxWidth(breakpoints.iphone6s)]: {
    fontSize: '1.6em',
  },

  [medias.betweenWidth(breakpoints.xs, breakpoints.m)]: {
    fontSize: '1.4em',
  },
  [medias.maxWidth(breakpoints.xs)]: {
    fontSize: '1.8em',
  },
});

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const Item = styled.li`
  margin-top: 1em;
  position: relative;
  width: 400px;

  &::before {
    content: '';
    display: block;
    margin: 1em auto;
    height: 4.2em;
    width: 5em;
    ${props => utils.backSvgFill(props.image.src)};
    background-position: bottom;
  }

  /** @medias */
  ${medias.betweenWidth(breakpoints.xxxl, breakpoints.huge)} {
    width: 350px;
  },
`;

export const ItemTitle = styled.div`
  letter-spacing: 0;
  margin-bottom: 1em;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  font-weight: ${weights.bold};
`;

export const ItemLine = styled.span`
  display: block;
  font-size: 0.8em;
  font-weight: ${weights.bold};
`;

export const ItemDesc = styled.p`
  font-size: 0.8em;
  margin: auto;
  margin-top: 0.2em;
  width: 85%;
  text-align: center;
`;
