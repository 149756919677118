/*
 * Package Import
 */
// import React, { Fragment } from 'react';

/*
 * Local Import
 */
import { getLink, getId } from 'src/routes/utils/paths';
// import * as S from 'src/components/Nav/Link/style';

/*
 * Code
 */
export const links = [
  {
    id: getId('index'),
    link: getLink('index'),
    text: 'Home',
  },
  {
    id: getId('jobs'),
    link: getLink('jobs'),
    text: 'Nos métiers',
  },
  {
    id: getId('program'),
    link: getLink('program'),
    text: 'Le programme',
  },
  {
    id: getId('manifestum'),
    link: getLink('manifestum'),
    text: 'Manifestum',
  },
  {
    id: getId('signup'),
    link: getLink('signup'),
    text: 'Inscription',
  },
];
