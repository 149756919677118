/* eslint-disable import/no-dynamic-require  */
/* eslint-disable global-require */
/**
 * Get @font-face
 * @param  {String} fontName
 * @param  {String} src
 * @param  {Number} [weight=400]
 * @param  {String} [style='normal' }]
 * @return {String}
 */
export const getFontFace = ({
  fontName,
  src,
  weight = 400,
  style = 'normal',
}) => `
  @font-face {
    font-display: block;
    font-family: ${fontName};
    src: url(${require(`../../assets/files/fonts/${src}.ttf`)}) format("truetype");
    font-style: ${style};
    font-weight: ${weight};
  }
`;

const fonts = `
  ${getFontFace({
    fontName: 'Brandon',
    src: 'brandon',
    weight: 400,
    style: 'normal',
  })}

  ${getFontFace({
    fontName: 'Lato',
    src: 'lato-bold',
    weight: 700,
    style: 'normal',
  })}

  ${getFontFace({
    fontName: 'Lato',
    src: 'lato-regular',
    weight: 400,
    style: 'normal',
  })}

  ${getFontFace({
    fontName: 'Quicksand',
    src: 'quicksand-bold',
    weight: 700,
    style: 'normal',
  })}
`;

/*
 * Export
 */
export default fonts;
