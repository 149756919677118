/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { breakpoints, colors, medias, utils, weights } from 'src/themes';
import cupFiole from 'assets/files/images/home/cupFiole.svg';

/*
 * Keyframes
 */

/*
 * Components
 */
export const Card = styled.div(({ logo }) => ({
  position: 'relative',
  background: colors.momentumOrange,
  maxWidth: '50em',
  color: 'white',
  margin: '8em auto 0',
  padding: '3em 4em',
  boxShadow:
    '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072)',

  '&::before': {
    position: 'absolute',
    right: '2em',
    top: '-2em',
    content: '""',
    display: 'block',
    height: '8em',
    width: '8em',
    ...utils.backSvgFill(logo),
  },

  '&::after': {
    position: 'absolute',
    right: '4.5em',
    top: '-4.5em',
    content: '""',
    display: 'block',
    height: '4.5em',
    width: '4.5em',
    ...utils.backSvgFill(cupFiole),
    transform: 'rotate(-25deg)',
  },

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '3em 1em',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '3em 0.5em',
    margin: '8em 2em 0',
    height: 'fit-content',
    fontSize: '0.8em',
  },
}));

export const Block = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  fontSize: '0.9em',
  '&:nth-of-type(2)': {
    [medias.maxWidth(breakpoints.s)]: {
      flexWrap: 'wrap',
    },
  },
  '&:nth-of-type(3)': {
    justifyContent: 'space-between',

    [medias.maxWidth(breakpoints.m)]: {
      flexWrap: 'wrap',
    },
  },
});

export const Missions = styled.div({
  width: '100%',
  margin: '1em',
});

export const Category = styled.p({
  fontWeight: weights.bold,
  fontSize: '1.2em',
});

export const Qualities = styled.div({
  margin: '1em 1.5em 1em 1em',
});

export const Skills = styled.div({
  margin: '1em 1em 1em 2em',

  [medias.maxWidth(breakpoints.s)]: {
    margin: '1em',
  },
});

export const Salary = styled.div(({ salary }) => ({
  margin: '1em',
  position: 'relative',
  width: '15em',
  height: '6em',

  [medias.maxWidth(breakpoints.m)]: {
    margin: '1em auto',
  },
  '&::before': {
    position: 'absolute',
    left: '-1em',
    top: '3em',
    content: '""',
    display: 'block',
    height: '4em',
    width: '15em',
    ...utils.backSvgFill(salary),

    [medias.maxWidth(breakpoints.m)]: {
      left: 0,
    },
  },
}));

export const Evolutions = styled.div({
  margin: '1em',
  width: '12em',
});

export const Activity = styled.div({
  margin: '1em',
});

export const List = styled.ul({});

export const Item = styled.li({
  position: 'relative',
  margin: '0 0 0 0.7em',

  '::before': {
    content: '"–"',
    position: 'absolute',
    left: '-0.7em',
  },
});

export const Slideshow = styled.ul({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '-3em',
  left: 0,
  right: 0,
});

export const Bullet = styled.li(({ isActive }) => ({
  margin: '0 1em',
  height: '0.7em',
  width: '0.7em',
  backgroundColor: '#eee',
  borderRadius: '50%',

  '&:hover': {
    backgroundColor: !isActive && '#ccc',
  },
  ...(isActive && {
    backgroundColor: colors.momentumOrange,
  }),
}));

export const Link = styled(RouterLink)({
  height: '0.7em',
  width: '0.7em',
  display: 'block',
});
