/*
 * Local Import
 */
import { weights } from 'src/themes';

/*
 * Reset Style
 */
export default {
  /*
   * Elements
   */
  'html, body, div, span, object, iframe, ul, ol, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, menu, nav, section, summary, time, mark, audio, video, picture, main, button': {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    font: 'inherit',
  },
  'article, aside, details, figcaption, figure, footer, header, menu, nav, section, picture, main': {
    display: 'block',
  },

  /*
   * Specific display
   */
  'blockquote, q': {
    quotes: 'none',
    '&::before, &::after': {
      content: 'none',
    },
  },
  del: {
    textDecoration: 'none',
  },
  'abbr[title], dfn[title]': {
    borderBottom: 0,
    textDecoration: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  hr: {
    display: 'block',
    height: '1px',
    width: '100%',
    border: 0,
    margin: 0,
    padding: 0,
  },

  /*
   * Root
   */
  html: {
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    textSizeAdjust: '100%',
    touchAction: 'manipulation',
  },

  /*
   * States
   */
  'a, input, button': {
    '&:hover, &:active, &:focus': {
      outline: 0,
    },
  },
  'input, button': {
    '&, &:invalid, &:valid': {
      boxShadow: 'none',
    },
    '&::-moz-focus-inner': {
      border: 0,
    },
  },
  a: {
    color: 'inherit',
    backgroundColor: 'transparent',
    textDecoration: 'none',
  },

  /*
   * Editorial elements
   */
  'ul, ol, li': {
    listStyle: 'none',
  },
  'strong, th': {
    fontWeight: weights.normal,
  },
  em: {
    fontStyle: 'normal',
  },
  'sub, sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
  },
  sup: {
    top: '-0.25em',
  },
  sub: {
    bottom: '-0.25em',
  },

  /*
   * Form elements
   */
  'button, input, select, textarea': {
    margin: 0,
    outline: 0,
  },
  'button, input[type=submit]': {
    background: 0,
    border: 0,
    display: 'block',
    width: 'auto',
    overflow: 'visible',
  },
  'input, select': {
    verticalAlign: 'middle',
  },
  'input, textarea, button': {
    appearance: 'none',
    borderRadius: 0,
  },
  'label, input[type=button], input[type=submit], input[type=image], button, a': {
    cursor: 'pointer',
  },

  /*
   * Ergonomics
   */
  'label, nav a': {
    userSelect: 'none',
  },
  textarea: {
    display: 'block',
    overflow: 'auto',
    resize: 'none',
  },

  /*
   * Box-sizing
   */
  '*, :before, :after': {
    boxSizing: 'border-box',
  },
  '@viewport': {
    width: 'device-width',
    zoom: 1,
  },
};
