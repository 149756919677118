/*
 * Package Import
 */
import React from 'react';
import saveRef from '@oclock/save-ref';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';
import Title from 'src/components/Program/Title';
import Timeline from 'src/components/Program/Timeline';
import Teaching from 'src/components/Program/Teaching';
import Inventory from 'src/components/Program/Inventory';
import Register from 'src/components/Register';

// Wrappers
import Main from 'src/components/Program/Main';
import Wrapper from 'src/components/Program/Wrapper';
import Section from 'src/components/Section';

// Style
import { colors } from 'src/themes';

// Data
import metas from './metas';

class ProgramContainer extends React.Component {
  /*
   * Render
   */
  render() {
    return (
      <Wrapper ref={saveRef(this)('app')}>
        <Head {...metas} />
        <Nav />
        <Main ref={saveRef(this)('node')}>
          <Section color="black">
            <Title />
          </Section>
          <Section color="white">
            <Timeline />
          </Section>
          <Section color="black">
            <Teaching />
          </Section>
          <Section color={colors.momentumOrange}>
            <Inventory />
          </Section>
          <Section color="black">
            <Register />
          </Section>
        </Main>
        <Footer saveRef={saveRef(this)('footer')} />
      </Wrapper>
    );
  }
}

/*
 * Component
 */
export default ProgramContainer;
