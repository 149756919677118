/*
 * Configuration
 */
const dsn = 'https://ed530c56e8084e2ebb1418f1ac873f7c@sentry.io/1480291';

/*
 * Options
 */
const ignoreErrors = [];

const ignoreUrls = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
];

const whitelistUrls = [/https?:\/\/((www)\.)?oclock\.io/];

/*
 * Sentry Config
 */
export const configSentry = {
  dsn,
  options: {
    ignoreUrls,
    ignoreErrors,
    whitelistUrls,
  },
};
