/*
 * Package Import
 */
import {
  colors as themeColors,
  colorsRGB as themeColorsRGB,
} from '@oclock/theme';

/*
 * Basic Colors
 */
export const colors = {
  ...themeColors,
  momentumBlack: '#16171A',
  momentumOrange: '#FE8E5C',
  momentumOrangeLight: '#FFD8C7',
  momentumRed: '#F97070',
  momentumDarkGrey: '#575757',
  momentumLightGrey: '#F2F2F2',
  momentumHover: '#FFD8C7',
  grayDarker: '#262626',
  purple: '#6542a4',
  disabledPromo: '#95928b',
  chatPhp: '#b84e44',
  chatVariable: '#e0685c',
  chatString: '#98be5f',
  chatInt: '#d19a66',
  chatComment: '#5c6370',
  chatFunc: '#24b8b5',
  chatText: '#d5d8cc',
  pink: '#da9eda',
  pinkDark: '#c18fbf',
  pinkLight: '#faf1fa',
  blueLight: '#ebf3fb',
  magnolia: '#f3effc',
  biloba: '#bca8e9',
  violet: '#896ACE',
  persianGreen: '#0da98e',
  solitude: '#dfeeff',
  regalBlue: '#123357',
  snow: '#f9f9f9',
};

/*
 * RGB Colors
 */
export const colorsRGB = {
  ...themeColorsRGB,
  grayDarker: '38, 38, 38',
};
