/*
 * Local Import
 */
import { reactToString } from 'src/utils';
import {
  BASE_URL,
  FB_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  CONTACT_MAIL,
} from 'src/data/constants';
import { getDateTime } from 'src/modules/dates';

/**
 * Get the Breadcrumb list
 * @param {Array} items
 * @return {Object}
 */
export const getBreadcrumb = items => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: BASE_URL,
      item: BASE_URL,
    },
    ...items.map(({ name, item }, index) => ({
      '@type': 'ListItem',
      position: index + 2,
      name,
      item: `${BASE_URL}${item}`,
    })),
  ],
});

/**
 * Get Organization information
 * @return {Object}
 */
export const getOrganization = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Momentum',
  url: BASE_URL,
  sameAs: [FB_URL, TWITTER_URL, LINKEDIN_URL],
  contactPoint: [
    {
      '@type': 'ContactPoint',
      // telephone: '+33 974768067',
      contactType: 'sales',
      availableLanguage: 'French',
      email: CONTACT_MAIL,
    },
  ],
});

/**
 * Get FAQPage data
 * @param {Array} questions
 * @return {Object}
 */
export const getFAQPage = ({ questions }) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    ...questions.map(question => ({
      '@type': 'Question',
      name: question.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: reactToString(question.answer),
      },
    })),
  ],
});

/**
 * Get Education Event
 * @param {Array} promo
 * @return {Object}
 */
export const getEducationEvent = promo => ({
  '@context': 'https://schema.org',
  '@type': 'EducationEvent',
  eventAttendanceMode: 'OnlineEventAttendanceMode',
  eventStatus: 'EventScheduled',

  startDate: getDateTime(promo.begin),
  endDate: getDateTime(promo.end),

  location: [
    {
      '@type': 'VirtualLocation',
      url: BASE_URL,
    },
    {
      '@type': 'Place',
      name: 'Chez vous',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'en téléprésentiel',
      },
    },
  ],

  performer: {
    '@type': 'Organization',
    name: 'O’clock',
  },
});

/**
 * Get Product
 * @param {Array} testimonies
 */
export const getProduct = testimonies => ({
  '@context': 'https://schema.org/',
  '@type': 'Product',

  review: [
    ...testimonies.list.map(testimony => ({
      reviewRating: {
        '@type': 'Rating',
        ratingValue: 5,
      },
      author: {
        '@type': 'Person',
        name: testimony.studentName,
      },
      reviewBody: reactToString(testimony.text),
      publisher: {
        '@type': 'Organization',
        name: 'O’clock',
      },
    })),
  ],
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: 5,
    bestRating: 5,
    ratingCount: testimonies.list.length,
  },

  brand: getOrganization(),
});
