/* eslint-disable react/prefer-stateless-function */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import saveRef from '@oclock/save-ref';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';

// Wrappers
import Main from 'src/components/Jobs/Main';
import Wrapper from 'src/components/Jobs/Wrapper';
import Section from 'src/components/Section';

// Page component
import Title from 'src/components/Jobs/Title';
import Summary from 'src/components/Jobs/Summary';
import Salary from 'src/components/Jobs/Salary';
import Hats from 'src/components/Jobs/Hats';
import Cta from 'src/components/Jobs/Cta';

// Style
import { colors } from 'src/themes';

// Data
import { jobs } from 'src/data/jobs';
import metas from './metas';

/*
 * Component
 */
class JobsContainer extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    size: PropTypes.number,
    page: PropTypes.string.isRequired,
  };

  /*
   * Render
   */
  render() {
    const { page } = this.props;
    const { card, cta, hats, salary, summary, title } = jobs[page];

    return (
      <Wrapper>
        <Head {...metas} />
        <Nav />
        <Main>
          <Section color="black">
            <Title data={{ title, card }} />
          </Section>
          <Section color="white">
            <Summary data={summary} />
          </Section>
          <Section color={colors.momentumOrange}>
            <Salary data={salary} />
          </Section>
          <Section color="white">
            <Hats data={hats} />
          </Section>
          <Section color="black">
            <Cta data={cta} />
          </Section>
        </Main>
        <Footer saveRef={saveRef(this)('footer')} />
      </Wrapper>
    );
  }
}

/*
 * Redux
 */
const mapStateToProps = state => ({
  size: state.size,
});

const mapDispatchToProps = {};

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(JobsContainer);
