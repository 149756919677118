/*
 * Local Import
 */
import webdesigner from './webdesigner';
import cm from './cm';
import webdev from './webdev';
import cybersecurity from './cybersecurity';
import digitalmediator from './digitalmediator';

/**
 * Content jobs
 * @type {Object}
 */
export const jobs = {
  webdev,
  cm,
  cybersecurity,
  webdesigner,
  digitalmediator,
};
