/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Title = ({ title }) => (
  <S.Container>
    <S.Title>
      {title.white}
      <br />
      <S.Highlight>{title.orange}</S.Highlight>
    </S.Title>
    <S.Img src={title.image} alt="Vieux mage" />
  </S.Container>
);

Title.propTypes = {
  title: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Title;
