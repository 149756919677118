/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import icon from 'assets/files/images/jobs/hat.svg';
import * as S from './style';

/**
 * Code
 */
const logos = [
  {
    top: '12em',
    left: '0%',
    transform: 'rotate(30deg)',
  },
  {
    top: '21.5em',
    left: '12%',
    transform: 'rotate(20deg)',
  },
  {
    top: '9em',
    left: '20%',
    transform: 'rotate(10deg)',
  },
  {
    top: '16em',
    left: '32%',
    transform: 'rotate(-30deg)',
  },
  {
    top: '23em',
    left: '45%',
    transform: 'rotate(60deg)',
  },
  {
    top: '12.5em',
    left: '55%',
    transform: 'rotate(-10deg)',
  },
  {
    top: '19em',
    left: '70%',
    transform: 'rotate(50deg)',
  },
  {
    top: '8em',
    left: '77%',
    transform: 'rotate(-30deg)',
  },
  {
    top: '15em',
    left: '89%',
    transform: 'rotate(-70deg)',
  },
];
/*
 * Component
 */
const Hats = ({ data }) => {
  const { formation } = data;

  return (
    <S.Container>
      <S.Icons aria-hidden="true">
        {logos.map(({ transform, top, left }, id) => (
          <S.Icon
            key={id}
            aria-hidden="true"
            alt="Chapeau de diplômé lancé en l'air"
            src={icon}
            transform={transform}
            top={top}
            left={left}
          />
        ))}
      </S.Icons>
      <S.Formation>
        <S.Title>{formation.title}</S.Title>
        <S.Text>{formation.text}</S.Text>
      </S.Formation>
    </S.Container>
  );
};

/*
 * PropTypes
 */
Hats.propTypes = {
  data: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Hats;
