/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import button from 'assets/files/images/home/boutonPixel.svg';

/*
 * Components
 */
export const Container = styled.div({
  margin: '10em 0 7em',
});

export const Title = styled.h2({
  fontWeight: weights.black,
  letterSpacing: '.12em',
  font: `${weights.normal} 2em/1.3 Quicksand`,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2em 2em 1em',

  /* @medias */
  [medias.maxWidth(breakpoints.iphone6s)]: {
    fontSize: '1.6em',
  },
  [medias.betweenWidth(breakpoints.xs, breakpoints.m)]: {
    fontSize: '1.4em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '2em 0.5em 1em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const KnowMore = styled.p({
  textAlign: 'center',
  padding: '0 2em',
  fontFamily: 'Quicksand',

  [medias.maxWidth(breakpoints.s)]: {
    padding: '0 1em',
  },
});

export const Cta = styled(RouterLink)({
  ...utils.backSvgFill(button),
  color: 'white',
  margin: '3em auto 0',
  width: '12em',
  height: '3em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: weights.bold,

  [medias.maxWidth(breakpoints.s)]: {
    width: '10em',
    height: '2.5em',
  },
});
