/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Cards = ({ card, children, position }) => {
  const {
    activity,
    evolutions,
    logo,
    missions,
    salary,
    qualities,
    skills,
  } = card;

  return (
    <S.Card logo={logo}>
      <S.Block>
        <S.Missions>
          <S.Category>Missions&nbsp;:</S.Category>
          <S.List>
            {missions.map(mission => (
              <S.Item key={mission}>{mission}</S.Item>
            ))}
          </S.List>
        </S.Missions>
      </S.Block>
      <S.Block>
        <S.Qualities>
          <S.Category>Qualités&nbsp;:</S.Category>
          <S.List>
            {qualities.map(quality => (
              <S.Item key={quality}>{quality}</S.Item>
            ))}
          </S.List>
        </S.Qualities>
        <S.Skills>
          <S.Category>Compétences&nbsp;:</S.Category>
          <S.List>
            {skills.map(skill => (
              <S.Item key={skill}>{skill}</S.Item>
            ))}
          </S.List>
        </S.Skills>
      </S.Block>
      <S.Block>
        <S.Salary salary={salary}>
          <S.Category>Salaire brut/an&nbsp;:</S.Category>
        </S.Salary>
        <S.Evolutions>
          <S.Category>Évolutions&nbsp;:</S.Category>
          <S.List>
            {evolutions.map(evolution => (
              <S.Item key={evolution}>{evolution}</S.Item>
            ))}
          </S.List>
        </S.Evolutions>
        <S.Activity>
          <S.Category>Secteur d’activité&nbsp;:</S.Category>
          <S.List>
            {activity.map(sector => (
              <S.Item key={sector}>{sector}</S.Item>
            ))}
          </S.List>
        </S.Activity>
      </S.Block>
      <S.Slideshow>
        {Object.keys(children).map((child, index) => (
          <S.Bullet key={index} isActive={position === index}>
            <S.Link to={`/nos-metiers${children[child].pathname}`} />
          </S.Bullet>
        ))}
      </S.Slideshow>
    </S.Card>
  );
};

/*
 * PropTypes
 */
Cards.propTypes = {
  card: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default withRouter(Cards);
