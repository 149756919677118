/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import button from 'assets/files/images/home/boutonPixel.svg';

/*
 * Components
 */
export const Container = styled.div({
  margin: '7em 0 6em',
  padding: '0 2em',
});

export const Title = styled.h2({
  fontWeight: weights.black,
  letterSpacing: '.12em',
  font: `${weights.normal} 2em/1.3 Quicksand`,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2em 2em 1em',

  /* @medias */
  [medias.maxWidth(breakpoints.iphone6s)]: {
    fontSize: '1.6em',
  },

  [medias.betweenWidth(breakpoints.xs, breakpoints.m)]: {
    fontSize: '1.4em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '2em 0.5em 1em',
  },
  [medias.maxWidth(breakpoints.xs)]: {
    fontSize: '1.8em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
  display: 'block',
});

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const Item = styled.li`
  margin-top: 1em;
  position: relative;
  width: 400px;

  &::before {
    content: '';
    display: block;
    margin: 1em auto;
    height: 5em;
    width: 5em;
    ${props => utils.backSvgFill(props.image.src, 'no-repeat white', '65%')};
    background-position: center;
    border-radius: 50%;
  }

  /** @medias */
  ${medias.betweenWidth(breakpoints.xxxl, breakpoints.huge)} {
    width: 350px;
  },
`;

export const ItemTitle = styled.div`
  letter-spacing: 0;
  margin-bottom: 1em;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.momentumOrange};
  font-weight: ${weights.bold};
  font-size: 0.9em;
`;

export const ItemLine = styled.span`
  display: block;
  font-size: 0.8em;
  font-weight: ${weights.bold};
`;

export const ItemDesc = styled.p`
  font-size: 0.8em;
  margin: auto;
  margin-top: 0.2em;
  width: 85%;
  text-align: center;
`;

export const Cta = styled(RouterLink)({
  ...utils.backSvgFill(button),
  color: 'white',
  margin: '3em auto 0',
  width: '12em',
  height: '3em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: weights.bold,

  [medias.maxWidth(breakpoints.s)]: {
    width: '10em',
    height: '2.5em',
  },
});
