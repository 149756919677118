/*
 * Pacakge Import
 */
import React from 'react';

/*
 * Local Import
 */
import envelope from 'assets/files/images/signup/envelope.svg';
import * as S from './style';

/*
 * Component
 */
const Step3 = () => (
  <>
    <S.Envelope src={envelope} alt="enveloppe" />
    <S.Text>
      Bien joué, tout est validé !
      <br />
      La suite se déroule maintenant dans ta boîte mail !
    </S.Text>
  </>
);

/*
 * Export
 */
export default Step3;
