/*
 * Package Import
 */
import {
  breakpoints as themeBreakpoints,
  betweenWidth,
  maxWidth,
  minWidth,
} from '@oclock/theme';

/*
 * Breakpoints
 */
export const breakpoints = {
  ...themeBreakpoints,
  iphone6s: 414,
  tabletMin: themeBreakpoints.m,
  tabletMax: themeBreakpoints.xl,
  phoneMax: themeBreakpoints.m,
  desktopMin: themeBreakpoints.xl,
};

/*
 * Medias
 */

/**
 * Max-height
 * @param  {Number} x
 * @return {String}
 *
 * e.g ->
 * [maxHeight(700)]: {
 *   paddingLeft: '0.2em',
 * }
 */
export const maxHeight = x => `@media (max-height: ${x - 1}px)`;

/**
 * Min-height
 * @param  {Number} x
 * @return {String}
 *
 * e.g ->
 * [minHeight(700)]: {
 *   position: 'relative',
 *   textAlign: 'right',
 * }
 */
export const minHeight = x => `@media (min-height: ${x}px)`;

/**
 * Between-height
 * @param  {Number} x
 * @param  {Number} y
 * @return {String}
 *
 * e.g ->
 * [betweenHeight(1200, 1600)]: {
 *   display: 'block',
 * }
 */
export const betweenHeight = (x, y) =>
  `@media (min-height: ${x}px) and (max-height: ${y - 1}px)`;

/*
 * Functions medias
 */
export const medias = {
  // width
  betweenWidth,
  maxWidth,
  minWidth,

  // height
  betweenHeight,
  maxHeight,
  minHeight,
};
