/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/*
 * Local Import
 */
import { NAV_HEIGHT } from 'src/components/Nav/style';
import { scrollTo } from '~adaptors';

/*
 * Component
 */
class Scroll extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
  };

  /*
   * LifeCycles
   */
  componentDidMount() {
    if (this.props.location.hash) {
      window.addEventListener('load', this.scrollToHash);
    }
  }

  componentDidUpdate(prevProps) {
    this.scrollTarget(prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.scrollToHash);
  }

  /*
   * Actions
   */
  scrollToHash = () => {
    this.scrollTarget();
  };

  scrollTarget = (prevProps) => {
    // Props
    let target;
    const { hash, pathname } = this.props.location;
    const isMobile = window.innerWidth < 900;

    // Vars
    const isFirstPaint = !prevProps;
    const isChangingPage = !!(
      prevProps
      && prevProps.location
      && prevProps.location.pathname !== pathname
    );

    // If we are on desktop, substract some pixels to mitigate the fixed nav
    // height+1 to avoid weird any rouding problem
    const opts = { add: isMobile ? 0 : -(NAV_HEIGHT + 1) };

    // Scroll without animation when mobile or changing page
    if (isMobile || isFirstPaint || isChangingPage) {
      opts.minDuration = 0;
      opts.speed = 0;
    }

    // If URL has a hash, scroll to element
    if (hash) {
      const id = hash.replace('#', '');
      target = document.getElementById(id);
    }
    // Otherwise, if we changing page, scroll to top
    else if (isChangingPage) {
      target = document.getElementById('root');
    }

    // If we have a target, let's scroll!
    if (target) {
      scrollTo(target, opts);
    }
  };

  /*
   * Render
   */
  render() {
    return null;
  }
}

/*
 * Export
 */
export default withRouter(Scroll);
