/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';

// Wrappers
import Wrapper from 'src/components/NotFound/Wrapper';

// Page component
import NotFound from 'src/components/NotFound';

// Data
import metas from './metas';

/*
 * Component
 */
export default () => (
  <Wrapper>
    <Head {...metas} />
    <Nav />
    <NotFound />
    <Footer />
  </Wrapper>
);
