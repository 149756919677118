/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Components
 */
export const createWrapper = ({ background }) => styled.div({ background });
