/*
 * Local Import
 */
import fioleCM from 'assets/files/images/home/fioleCM.svg';
import fioleDeveloppeurWeb from 'assets/files/images/home/fioleDeveloppeurWeb.svg';
import fioleWebdesigner from 'assets/files/images/home/fioleWebdesigner.svg';
import fioleMediateurNumerique from 'assets/files/images/home/fioleMediateurNumerique.svg';
import fioleCybersecurite from 'assets/files/images/home/fioleCybersecurite.svg';

/**
 * Data
 */
export const jars = [
  {
    img: fioleDeveloppeurWeb,
    name: 'développeur web',
    text: 'Découvre les codes du métier qui développe le monde du web.',
    link: 'nos-metiers/developpeur-web',
  },
  {
    img: fioleCM,
    name: 'community manager',
    text:
      'Devenir la voix d’une marque et entretenir sa communauté, c’est tout un métier.',
    link: 'nos-metiers/community-manager',
  },
  {
    img: fioleCybersecurite,
    name: 'cybersécurité',
    text:
      'Pars à la rencontre d’un métier qui veille à la sécurité du web et lutte contre la cybercriminalité.',
    link: 'nos-metiers/cybersecurite',
  },
  {
    img: fioleWebdesigner,
    name: 'webdesigner',
    text: 'Pars à la découverte du métier qui embellit le web.',
    link: 'nos-metiers/web-designer',
  },
  {
    img: fioleMediateurNumerique,
    name: 'médiateur numérique',
    text:
      'Découvre un métier pour guider ceux qui se sentent perdus dans l’univers du numérique.',
    link: 'nos-metiers/mediateur-numerique',
  },
];
