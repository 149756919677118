/*
 * Local import
 */
import { colors } from 'src/themes/colors';

// Favicons • Black
import favicon16 from 'assets/favicons/favicon-momentum-16.png';
import faivcon32 from 'assets/favicons/favicon-momentum-32.png';

/**
 * Favicons
 * @type {Object}
 */
const favicons = {
  default: { 16: favicon16, 32: faivcon32 },
};

/**
 * Get color
 * @param colorName? - color key
 */
export const getColor = ({ color }) => {
  if (color in colors) {
    return colors[color];
  }
  return '#0ac3a7';
};

/**
 * Change Favicon
 * @param colorName? (optional) - color key
 */
export const getFaviconPath = ({ color }) => {
  if (color !== 'default' && color in colors) {
    return favicons[color];
  }
  return favicons.default;
};
