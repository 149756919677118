/*
 * Package Import
 */
import React from 'react';
import saveRef from '@oclock/save-ref';

/*
 * Local Import
 */
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';
import Title from 'src/components/Manifestum/Title';
import Content from 'src/components/Manifestum/Content';
import Register from 'src/components/Register';

// Wrappers
import Main from 'src/components/Manifestum/Main';
import Wrapper from 'src/components/Manifestum/Wrapper';
import Section from 'src/components/Section';

// Data
import metas from './metas';

/*
 * Component
 */
class ManifestumContainer extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {};

  /*
   * Render
   */
  render() {
    return (
      <Wrapper ref={saveRef(this)('app')}>
        <Head {...metas} />
        <Nav />
        <Main ref={saveRef(this)('node')}>
          <Section color="black">
            <Title />
          </Section>
          <Section color="white">
            <Content />
          </Section>
          <Section color="black">
            <Register />
          </Section>
        </Main>
        <Footer saveRef={saveRef(this)('footer')} />
      </Wrapper>
    );
  }
}

/*
 * Component
 */
export default ManifestumContainer;
