// if requestIdleCallback isn't supported by the client
// use a shim https://gist.github.com/paullewis/55efe5d6f05434a96c36
window.requestIdleCallback =
  window.requestIdleCallback ||
  (callback =>
    setTimeout(() => {
      const start = Date.now();
      callback({
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
      });
    }, 1));

// cancelIdleCallback shim
window.cancelIdleCallback =
  window.cancelIdleCallback || (id => clearTimeout(id));
