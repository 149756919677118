/*
 * Package Import
 */
import React, { useState } from 'react';

/*
 * Local Import
 */
import { jars } from './data';
import * as S from './style';

/*
 * Component
 */
const Jars = () => {
  /**
   * State
   */
  const [activeJar, setActiveJar] = useState(jars[0]);

  // handler
  const handleClick = jar => () => {
    const clickedTestimony = jars.find(job => job === jar);
    setActiveJar(clickedTestimony);
  };

  return (
    <S.Container>
      <S.Title>
        <S.Highlight>Connexion</S.Highlight> à ta vocation, en cours...
      </S.Title>
      <S.SubTitle>
        Derrière l’application que tu utilises tous les jours ou les marques que
        tu suis, se cachent un grand nombre de métiers du numérique :
        développeur web, community manager, webdesigner, développeur
        cybersécurité ou encore médiateur numérique. Ce qu’on te propose :
        découvrir ces métiers avec nous depuis notre plateforme pour que l’un
        d’eux devienne le tien.
      </S.SubTitle>
      <S.JarsRadio>
        {jars.map(jar => (
          <S.Label key={jar.name} isActive={jar === activeJar}>
            <S.Jar src={jar.img} alt={jar.name} />
            <S.Input type="radio" onClick={handleClick(jar)} />
            <S.Checkmark isActive={jar === activeJar} />
          </S.Label>
        ))}
      </S.JarsRadio>
      <S.JarsBox>
        <S.BoxTitle>{activeJar.name}</S.BoxTitle>
        <S.BoxText>{activeJar.text}</S.BoxText>
        <S.BoxCta href={activeJar.link}>En savoir plus</S.BoxCta>
      </S.JarsBox>
    </S.Container>
  );
};

/*
 * Export
 */
export default Jars;
