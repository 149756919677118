/*
 * Package Import
 */
import { memo } from 'react';

/**
 * mergeObject
 * Fusionne les objets en prenant pour chaque clé la première valeur trouvée
 *
 * @param  {array} objects  plusieurs objets à fusionner
 * @return {object}         objet fusionné
 */
export const mergeObject = (...objects) => objects.reduce((cumul, item) => {
  Object.keys(item).forEach((key) => {
    if (cumul[key] && typeof cumul[key] === 'object') {
      cumul[key] = mergeObject(cumul[key], item[key]);
    }
    else {
      cumul[key] = item[key] || cumul[key];
    }
  });

  return cumul;
}, {});

/**
 * Create props change check function
 * @param  {Array}  props      Array of props to look up
 * @param  {Object}  prevProps Previous props
 * @param  {Object}  nextProps Next props
 * @return {Boolean}           true if a prop has changed
 */
const hasPropsChanged = props => (prevProps, nextProps) => props.every(item => prevProps[item] === nextProps[item]);

/**
 * Create props change check function
 * @param  {Array}  props      Array of props to look up
 * @param  {Object}  prevProps Previous props
 * @param  {Object}  nextProps Next props
 * @return {Boolean}           true if a prop has changed
 */
export const memoFromProps = (Component, props) => memo(Component, hasPropsChanged(props));

/**
 * Convert React elements to strings
 * @param  {Node} element
 * @return {String}
 */
export const reactToString = (element) => {
  if (!element) {
    return '';
  }

  if (typeof element === 'string') {
    return element;
  }

  if (typeof element === 'number') {
    return String(element);
  }

  if (Array.isArray(element)) {
    return element.map(subElement => reactToString(subElement)).join('');
  }

  if (element.props && element.props.children) {
    return reactToString(element.props.children);
  }

  if (element.props && !element.props.children) {
    return '';
  }

  return '';
};
