/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Title = () => (
  <S.Container>
    <S.Title>
      Mise à jour de ton avenir&nbsp;:
      <br />
      <S.Highlight>disponible</S.Highlight>
    </S.Title>
    <S.SubTitle>
      Découvre ton avenir là où tu ne l’attendais pas.
      <br />
      Embarque dans nos classes virtuelles pour t’initier gratuitement à
      plusieurs métiers du numérique. Un seul objectif : en trouver un qui sera
      le tien.
    </S.SubTitle>
  </S.Container>
);

/*
 * Export
 */
export default Title;
