/*
 * Local Import
 */

// Helpers
import { getOrganization } from 'src/modules/jsonLD';

/*
 * SEO
 */
const seo = {
  title: '',
  description: '',
  og: {
    title: '',
    description: '',
    image: '',
  },
  color: 'default',
};

/*
 * JSON-LD
 */
const jsonLD = [
  // Organization information
  getOrganization(),
];

/*
 * Export
 */
export default { seo, jsonLD };
