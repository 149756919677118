/*
 * Pacakge Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Code
 */
const labels = {
  woman: 'Femme',
  man: 'Homme',
};

/*
 * Component
 */
export default class Button extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    gender: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  /*
   * State
   */
  state = {};

  /*
   * Handlers
   */
  handleChange = () => {
    this.props.onChange(this.props.gender);
  };

  /*
   * Render
   */
  render() {
    // Props
    const { gender, value } = this.props;
    const { focus } = this.state;
    const isActive = value === gender;
    const inputId = `signup-gender-input-${gender}`;

    // View
    return (
      <S.Gender isActive={isActive} isFocus={focus} htmlFor={inputId}>
        <S.Input
          type="radio"
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
          checked={isActive}
          onChange={this.handleChange}
          id={inputId}
        />
        <S.Image gender={gender} />
        <S.Label isActive={isActive}>{labels[gender]}</S.Label>
      </S.Gender>
    );
  }
}
