/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import Section from 'src/components/Section';
import { Status } from 'src/components/Status';

// Styled
import * as S from './style';

/*
 * Component
 */
export default () => (
  <Status statusCode={404}>
    <S.Container>
      <Section color="white">
        <S.Text>
          <S.Title>404</S.Title>
          <S.Hello>Ohhh, un curieux !</S.Hello>
          <p>Pour le moment, il n’y a pas grand chose ici.</p>
        </S.Text>
      </Section>
    </S.Container>
  </Status>
);
