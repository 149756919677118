/*
 * Package import
 */
import TagManager from 'react-gtm-module';

/*
 * IDs
 */
const analyticsId = 'GTM-PFSHB5K';

/*
 * Export
 */
export function init() {
  // Vars
  const online = window.location.hostname.includes('startmomentum.org');
  // If we're online, load SDK
  if (online) {
    /* eslint-disable */
    try {
      // https://github.com/alinemorelli/react-gtm
      const tagManagerArgs = {
        gtmId: analyticsId,
      };
      TagManager.initialize(tagManagerArgs);
    } catch (err) {
      console.log('err tracking', err);
    }
  }
}
