/*
 * Npm import
 */

/*
 * Local import
 */

/*
 * Code
 */

/*
 * Export default
 */
export default ({ initialState, type }) => {
  // Reducer
  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case type:
        return action.payload;
      default:
        return state;
    }
  };

  // Action creator
  const actionCreator = payload => ({ type, payload });

  // Return
  return { reducer, actionCreator };
};
