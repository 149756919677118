/*
 * Local Import
 */
import fioleWebdesigner from 'assets/files/images/home/fioleWebdesigner.svg';
import cardWebdesigner from 'assets/files/images/jobs/cardWebdesigner.svg';
import webdesignerMissions from 'assets/files/images/jobs/webdesignerMissions.jpg';
import webdesignerQualite from 'assets/files/images/jobs/webdesignerQualite.jpg';

/**
 * data
 */
export default {
  title: 'webdesigner',
  id: 'webdesigner',
  card: {
    logo: fioleWebdesigner,
    missions: [
      'Analyser les besoins du client pour les traduire graphiquement',
      'Concevoir et réaliser l’identité visuelle d’une interface web',
      'Rédiger le cahier des charges',
      'Superviser le travail réalisé lors de l’intégration web',
    ],
    qualities: ['Créatif', 'Minutieux', 'Polyvalent'],
    skills: [
      'Maîtriser les divers outils de création graphique ',
      'Connaître les outils de programmation et logiciels en usage dans le dev web',
      'Avoir des notions en communication et marketing',
    ],
    salary: cardWebdesigner,
    evolutions: ['Directeur artistique', 'Designer UX/UI'],
    activity: ['Agence', 'Studio de création', 'Entreprise', 'Freelance'],
  },
  summary: [
    {
      title: 'Les missions du webdesigner',
      text: [
        'Le rôle du webdesigner est de concevoir et réaliser l’identité visuelle d’une interface web.',
        'Le webdesigner est à la croisée des chemins entre graphisme, technique et marketing. Son rôle premier est d’être garant du rendu purement graphique comme l’aspect visuel général, le choix des couleurs, des typographies, les formes, les illustrations, les photos etc. Aussi, il doit toujours avoir en tête de créer une plateforme ergonomique, intuitive et facilement utilisable par les internautes. Il travaille d’ailleurs de concert avec les développeurs web qui vont se charger de rendre “dynamique” les maquettes que le webdesigner aura réalisées.',
        'Enfin, le dernier volet du travail du webdesigner concerne les enjeux marketing et commerciaux. Un site web ou une application sont des outils qui vont permettre d’informer et de commercialiser des produits et services. Le webdesigner doit donc répondre à ces objectifs avec une interface efficace, et qui réponde à la fois aux exigences marketing et de communication d’une marque ou d’une entreprise.',
        'La demande est omniprésente pour ce métier, il peut donc l’exercer en agence, dans un studio de création, directement pour une entreprise ou même en indépendant.',
      ],
      img: webdesignerMissions,
      alt: 'les missions du webdesigner',
    },
    {
      title: 'Les qualités cruciales du webdesigner',
      text: [
        'Il y a quelques qualités indispensables que tout bon webdesigner doit avoir. La principale est la créativité, pour attirer l’attention de façon originale, rendre une marque identifiable et séduire à travers un design.',
        'Mais que serait la créativité sans maîtrise des outils de créations graphiques ? Ce serait comme un peintre sans pinceau. Les différents logiciels tels que : Photoshop, Illustrator ou encore Dreamweaver sont les ustensiles de création du webdesign, donc indispensables.',
        'Une connaissance ne serait-ce que théorique du développement web est également un grand atout car permet au webdesigner de concevoir des maquettes qui répondent à la faisabilité technique du projet.',
        'Il doit aussi avoir de bonnes notions en communication et marketing afin de comprendre la stratégie et les enjeux du client. Le webdesigner doit allier compétences artistiques et connaissances techniques en faisant preuve de créativité et de minutie pour exceller dans son domaine.',
      ],
      img: webdesignerQualite,
      alt: 'Les qualités du webdesigner',
    },
  ],
  salary: {
    title: 'Le salaire & les possibilités d’évolution du webdesigner',
    text:
      'Comment parler d’un métier sans penser à ses possibilités d’évolutions et à sa rémunération ? Un webdesigner gagnera environ 24K à 28K brut par an au début de sa carrière. Évidemment, ici, on parle d’une moyenne qui dépend de plusieurs critères comme la région dans laquelle il travaille, s’il est en télétravail ou en présentiel. Lorsqu’il passera webdesigner sénior, il pourra prétendre à un salaire plus élevé (Entre 40 et 50K brut par an). En fonction de ses spécialités, le webdesigner pourra évoluer vers le poste de directeur artistique ou encore se spécialiser sur des segments du webdesign bien précis comme designer UX/UI.',
    evolution: [
      {
        post: 'webdesigner junior',
        salary: 'Entre 21 000€ et 24 000€ par an.',
      },
      {
        post: 'webdesigner confirmé',
        salary: '36 000€ par an.',
      },
      {
        post: 'webdesigner senior',
        salary: 'Entre 45 000€ et 50 000€ par an.',
      },
    ],
  },
  hats: {
    formation: {
      title: 'Les formations pour devenir webdesigner',
      text:
        'Tu peux devenir webdesigner sans forcément enchaîner les années d’études et même sans le bac. Il existe plusieurs façons de devenir webdesigner, notamment avec des organismes de formations courtes. Il n’est pas non plus impossible de le devenir en autodidacte, grâce aux innombrables ressources disponibles en ligne. Bien évidemment, il y a aussi le chemin plus “classique” post-bac avec les BTS multimédia ou les cursus Bachelor dans les écoles spécialisées.',
    },
  },
  cta: {
    content: [
      'Ça t’a donné envie d’en savoir plus ?',
      'Viens explorer le métier de webdesigner dans notre programme de découverte !',
    ],
    link: {
      to: '/programme',
      text: 'programme',
    },
  },
};
