/*
 * Package import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

/*
 * Local Import
 */

/*
 * Redirects • The router only ever adds context.url. But you may want some
 * redirects to be 301 and others 302. Or maybe you’d like to send a 404
 * response if some specific branch of UI is rendered, or a 401 if they
 * aren’t authorized. The context prop is yours, so you can mutate it.
 */
export const RedirectWithStatus = ({ from, to, status }) => (
  <Route
    render={({ staticContext }) => {
      // there is no `staticContext` on the client,
      // so we need to guard against that here
      if (staticContext) staticContext.status = status;
      return <Redirect from={from} to={to} />;
    }}
  />
);

RedirectWithStatus.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  status: PropTypes.number,
};

/*
 * Status • Create a component that adds some context and render it anywhere
 * in the app to get a different status code. Now you can render a Status
 * anywhere in the app that you want to add the code to staticContext.
 */
export const Status = ({ statusCode, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = statusCode;
      return children;
    }}
  />
);

Status.propTypes = {
  statusCode: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
};
