/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors } from 'src/themes';

/*
 * Component
 */
export const Container = styled.section(({ color, noPadding }) => ({
  color: color === 'black' ? 'white' : 'black',
  padding: noPadding ? '0' : '0 0 5em',
  background: color === 'black' ? colors.momentumBlack : color,
}));
