/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Section = ({ children, color, noPadding }) => (
  <S.Container color={color} noPadding={noPadding}>
    {children}
  </S.Container>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
};

Section.defaultProps = {
  noPadding: false,
};

/*
 * Export
 */
export default Section;
