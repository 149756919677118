/*
 * Paths
 */
const paths = {
  index: {
    id: 'home',
    pathname: '/',
  },
  program: {
    id: 'program',
    pathname: '/programme',
    hash: {
      day: 'une-journee-chez-oclock',
    },
  },
  signup: {
    id: 'signup',
    pathname: '/inscription',
    post: '/signup/submit',
  },
  manifestum: {
    id: 'manifestum',
    pathname: '/manifestum',
  },
  jobs: {
    id: 'jobs',
    pathname: '/nos-metiers',
    children: {
      webdev: {
        id: 'webdev',
        name: 'Développeur Web',
        pathname: '/developpeur-web',
      },
      cm: {
        id: 'cm',
        name: 'Community Manager',
        pathname: '/community-manager',
      },
      cybersecurity: {
        id: 'cybersecurity',
        name: 'Développeur cybersécurité',
        pathname: '/cybersecurite',
      },
      webdesigner: {
        id: 'webdesigner',
        name: 'Webdesigner',
        pathname: '/web-designer',
      },
      digitalmediator: {
        id: 'digitalmediator',
        name: 'Médiateur numérique',
        pathname: '/mediateur-numerique',
      },
    },
  },
  notFound: {
    id: 'not-found',
    pathname: '*',
  },
  rgpd: {
    id: 'rgpd',
    pathname: '/rgpd',
  },
  mentions: {
    id: 'mentions',
    pathname: '/mentions',
  },
};

/*
 * Export default
 */
export default paths;
