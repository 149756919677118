/*
 * Package Import
 */
import { loggerMiddleware } from '@oclock/logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';

/*
 * Local Import
 */
import createRootReducer from './reducer';

/*
 * Export default
 */
export default ({ initialState, history }) => {
  // we use rootReducer to reset the store to it's initial state
  // on LOCATION_CHANGE when we leave '/team' page
  // https://stackoverflow.com/a/35641992
  const rootReducer = (state, action) => {
    if (action.type === LOCATION_CHANGE) {
      // eslint-disable-next-line no-param-reassign
      state = {
        size: state.size,
      };
    }

    return createRootReducer(history)(state, action);
  };

  /* 2. Initial state */
  // (in function param)

  /* 3. Middlewares */
  // Redux DevTools extension
  let devTools = [];
  if (
    typeof window === 'object'
    && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    && process.env.NODE_ENV !== 'production'
  ) {
    devTools = [window.__REDUX_DEVTOOLS_EXTENSION__()];
  }

  // React router redux
  const rrr = applyMiddleware(routerMiddleware(history), loggerMiddleware());

  // Compose
  const middlewares = compose(rrr, ...devTools);

  /* Create store width 1. reducers, 2. initial state and 3. middlewares */
  const store = createStore(rootReducer, initialState, middlewares);

  /* Return store */
  return store;
};
