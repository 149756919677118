/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import coins from 'assets/files/images/jobs/coins.svg';
import dollarBag from 'assets/files/images/jobs/dollarBag.svg';
import dollar from 'assets/files/images/jobs/dollar.svg';
import * as S from './style';

/*
 * Component
 */
const Salary = ({ data }) => {
  /**
   * Vars
   */
  const logos = [
    {
      logo: coins,
      alt: 'pièces',
    },
    {
      logo: dollar,
      alt: 'billets',
    },
    {
      logo: dollarBag,
      alt: 'sac de billets',
    },
  ];

  return (
    <>
      <S.Container>
        <S.Title>{data.title}</S.Title>
        <S.Text>{data.text}</S.Text>
      </S.Container>
      <S.Evolution>
        {data.evolution.map(({ post, salary }, index) => (
          <S.Steps key={index}>
            <S.Circle>
              <S.MoneyLogo src={logos[index].logo} alt={logos[index].alt} />
            </S.Circle>
            <S.Post>{post}</S.Post>
            <S.Salary>{salary}</S.Salary>
          </S.Steps>
        ))}
      </S.Evolution>
    </>
  );
};

/*
 * PropTypes
 */
Salary.propTypes = {
  data: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Salary;
