/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

/*
 * Local Import
 */
import { getLink } from 'src/routes/utils/paths';
import BurgerMenu from 'src/components/BurgerMenu';
import NavLink from './Link';
import InnerMenu from './InnerMenu';
import * as S from './style';

/*
 * Code
 */
const goHome = push(getLink('index'));

/*
 * Component
 */
class Nav extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    isPost: PropTypes.bool,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        link: PropTypes.object.isRequired,
        text: PropTypes.node.isRequired,
        callback: PropTypes.func,
      }),
    ).isRequired,
    activeSection: PropTypes.string,
    fromBlog: PropTypes.bool,
  };

  static defaultProps = {
    isPost: false,
  };

  /*
   * State
   */
  state = {
    open: false,
  };

  /*
   * Actions
   */
  openMenu = () => {
    this.setState({ open: true });
    document.body.style.position = 'fixed';
  };

  closeMenu = () => {
    document.body.className = '';
    this.setState({ open: false });
    document.body.style.position = '';
  };

  /*
   * Handlers
   */
  clickLink = cb => (evt) => {
    const { isMobile } = this.props;
    // Close menu on mobile
    if (isMobile) {
      this.closeMenu();
    }

    // Go away, focus!
    evt.target.blur();

    // Callback
    if (cb) {
      cb();
    }
  };

  clickLogo = () => {
    this.props.dispatch(goHome);
  };

  /*
   * Render
   */
  render() {
    const { open } = this.state;
    const { isMobile, links } = this.props;

    /*
     * View
     */
    return (
      <S.Header>
        <S.Nav
          // a11y
          role="navigation"
          aria-label="Menu principal"
          // Props
          isOpen={open}
        >
          {(!isMobile || open) && (
            <S.Container isOpen={open}>
              <S.List>
                {links.map(({ id, link, text, callback }) => (
                  <S.Item key={id} navId={id} isMobile={isMobile} isOpen={open}>
                    {id === 'jobs' ? (
                      <InnerMenu isMobile={isMobile} closeMenu={this.closeMenu}>
                        {text}
                      </InnerMenu>
                    ) : (
                      <NavLink
                        to={link}
                        navId={id}
                        onClick={this.clickLink(callback)}
                      >
                        {text}
                      </NavLink>
                    )}
                  </S.Item>
                ))}
              </S.List>
              {isMobile ? (
                <S.Close onClick={this.closeMenu}>×</S.Close>
              ) : (
                <S.Logo onClick={this.clickLogo} />
              )}
            </S.Container>
          )}

          {/* 🍔 Burger Menu */}
          {!open && isMobile && (
            <>
              <BurgerMenu clickCallBack={this.openMenu} />
              <S.Logo onClick={this.clickLogo} />
            </>
          )}
        </S.Nav>
      </S.Header>
    );
  }
}

/*
 * Export
 */
export default Nav;
