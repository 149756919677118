/*
 * Package Import
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/*
 * Local Import
 */
import NotFound from 'src/containers/NotFound';

// Data
import { Pages, Redirects } from './utils/components';

// Helpers
import { getPathname } from './utils/paths';

/*
 * Routes
 */

const Routes = () => (
  <Switch>
    {/* Pages */}
    {Pages}

    {/* Redirections */}
    {Redirects}

    {/* 404 */}
    <Route path={getPathname('notFound')} component={NotFound} />
  </Switch>
);

/*
 * Export
 */
export default Routes;
