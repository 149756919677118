/*
 * Local Import
 */
import fioleCybersecurite from 'assets/files/images/home/fioleCybersecurite.svg';
import cardCybersecurite from 'assets/files/images/jobs/cardCybersecurite.svg';
import cybersecuriteMissions from 'assets/files/images/jobs/cybersecuriteMissions.jpg';
import cybersecuriteQualite from 'assets/files/images/jobs/cybersecuriteQualite.jpg';

/**
 * data
 */
export default {
  title: 'développeur cybersécurité',
  id: 'cybersecurity',
  card: {
    logo: fioleCybersecurite,
    missions: [
      'Sécuriser les données utilisées par une organisation',
      'Prévenir les risques de piratages informatiques',
      'Anticiper et empêcher les intrusions dans la base de données',
      'Détecter et réparer les failles de sécurité dans le système d’information',
    ],
    qualities: ['Rigoureux', 'Éthique', 'Polyvalent'],
    skills: [
      'Maîtrise des langages informatiques',
      'Connaître les bases de données, serveur web et Big data',
      'Maîtrise des tests d’intrusion',
    ],
    salary: cardCybersecurite,
    evolutions: ['Chef de projet sécurité informatique', 'Expert technique'],
    activity: ['État', 'Entreprise', 'Freelance'],
  },
  summary: [
    {
      title: 'Les missions du développeur cybersécurité ',
      text: [
        'Son rôle est de veiller à la sécurité du web en luttant contre la cybercriminalité. Il protège les programmes et données des entreprises contre les cyberattaques, en apportant ses compétences dans la conception des logiciels et applications pour le web.',
        'Ses missions sont multiples. Elles vont de l’analyse des besoins de l’utilisateur avec l’anticipation d’éventuelles attaques informatiques (avant le développement), en passant par la réalisation des audits et évaluations de code, pour déterminer leur conformité (pendant le développement), jusqu’à la maintenance, en détectant et réparant les failles de sécurité (après le développement).',
        'Les cyberattaques peuvent toucher n’importe quel domaine. Le développeur cybersécurité pourra donc aussi bien travailler pour l’État qu’en entreprise ou en freelance.',
      ],
      img: cybersecuriteMissions,
      alt: 'Les missions du développeur cybersécurité',
    },
    {
      title: 'Les qualités cruciales du développeur cybersécurité',
      text: [
        'Le métier de développeur cybersécurité est un métier où il faut être particulièrement polyvalent. Il doit maîtriser à la fois les langages informatiques, les tests d’intrusion, connaître la base de données, serveur web, Big data (l’ensemble des données informatiques récoltées sur internet à très grande échelle), ainsi que les différentes attaques possibles pour pouvoir prévenir, anticiper, détecter et sécuriser le projet face aux cyberattaques.',
        'Le développeur cybersécurité doit aussi être rigoureux et avoir un bon sens de l’éthique car il pourrait être amené à travailler avec des données très sensibles.',
      ],
      img: cybersecuriteQualite,
      alt: 'Les qualités du développeur cybersécurité',
    },
  ],
  salary: {
    title: 'Salaire et évolutions du développeur cybersécurité ',
    text:
      'Un développeur cybersécurité gagne en moyenne 33K brut par an, mais ça dépend de plusieurs critères comme la région dans laquelle il travaille, son expérience ou encore ses spécialités. En fonction, son salaire peut être beaucoup plus élevé. Pour ce qui est de son évolution, il pourra envisager de passer lead développeur cybersécurité voire même directeur technique après quelques années d’expérience.',
    evolution: [
      {
        post: 'Développeur cybersécurité junior',
        salary: '30 000€ par an.',
      },
      {
        post: 'Développeur cybersécurité confirmé',
        salary: '48 000€ par an.',
      },
      {
        post: 'Développeur cybersécurité senior',
        salary: '84 000€ par an.',
      },
    ],
  },
  hats: {
    formation: {
      title: 'Les formations pour devenir développeur cybersécurité',
      text:
        'Le domaine de la cybersécurité a explosé très récemment. On commence donc à voir fleurir un peu partout des cursus universitaires proposant ce type d’orientation. Les formations professionnelles proposent également de s’engouffrer dans cette voie. En revanche, il faudra, la plupart du temps, disposer de quelques bases en informatique et développement pour se spécialiser en cybersécurité.',
    },
  },
  cta: {
    content: [
      'Ça t’a donné envie d’en savoir plus ?',
      'Viens explorer le métier de développeur cybersécurité dans notre programme de découverte !',
    ],
    link: {
      to: '/programme',
      text: 'programme',
    },
  },
};
