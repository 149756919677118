/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, colors, medias, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.div({
  padding: '6em 5em 3em',

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '6em 2em 3em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '6em 1em 0',
  },
});

export const Title = styled.h4({
  textAlign: 'center',
  margin: 'auto auto 2em',
  color: 'white',
  fontWeight: weights.bold,
  textTransform: 'uppercase',
  fontSize: '1.3em',
});

export const Text = styled.p({
  margin: 'auto',
  color: 'white',
  textAlign: 'center',
  maxWidth: '50em',
  lineHeight: '1.7em',
});

export const Evolution = styled.div({
  margin: '4em 0 -5em',
  background: 'white',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  padding: '0 3em 0',

  [medias.maxWidth(breakpoints.xl)]: {
    padding: '0 2em 0',
  },
  [medias.maxWidth(breakpoints.l)]: {
    flexWrap: 'nowrap',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '0 1em 0',
  },
  [medias.maxWidth(breakpoints.s)]: {
    flexDirection: 'column',
  },
});

export const Steps = styled.div({
  margin: '-3em 1em 0',
  textAlign: 'center',

  [medias.maxWidth(breakpoints.s)]: {
    ':not(:first-of-type)': {
      margin: '0.5em 1em 0',
    },
  },
});

export const Circle = styled.div({
  width: '6em',
  height: '6em',
  background: 'white',
  borderRadius: '50%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '::before': {
    content: '""',
    position: 'absolute',
    width: '5.2em',
    height: '5.2em',
    border: `solid 3px ${colors.momentumOrange}`,
    borderRadius: '50%',
  },
});

export const MoneyLogo = styled.img({
  width: '3em',
  height: '3em',
  background: 'white',
});

export const Post = styled.h5({
  fontWeight: weights.bold,
  textTransform: 'uppercase',
  fontSize: '0.7em',

  [medias.maxWidth(breakpoints.xl)]: {
    fontSize: '0.6em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    fontSize: '0.7em',
  },
});

export const Salary = styled.p({
  fontSize: '0.7em',

  [medias.maxWidth(breakpoints.xl)]: {
    fontSize: '0.6em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    fontSize: '0.7em',
  },
});
