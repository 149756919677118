/*
 * Package Import
 */
import React from 'react';
import { Logger } from '@oclock/logger';
import { HelmetProvider } from 'react-helmet-async';

// Redux
import { Provider } from 'react-redux';

// Router
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';

/*
 * Local Import
 */
import App from 'src/containers/App';
import configureStore from 'src/store';

// 3rd service • Sentry • Configuration
import { configSentry } from 'src/sentry';

/*
 * Code
 */
const history = createBrowserHistory();

// Grab the state from a global injected into server-generated HTML
const initialState = window.__INITIAL_STATE__;

// Get the store ready && get the history synced with the store
// @NOTE: export to be able to grab store from other component
export const store = configureStore({ initialState, history });

/*
 * Export default
 */
export default () => (
  <Provider store={store}>
    <HelmetProvider>
      <ConnectedRouter history={history}>
        <Logger {...configSentry}>
          <App />
        </Logger>
      </ConnectedRouter>
    </HelmetProvider>
  </Provider>
);
