/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import book from 'assets/files/images/manifestum/book.svg';
import * as S from './style';

/*
 * Component
 */
const Title = () => (
  <S.Container>
    <S.Title>Manifestum</S.Title>
    <S.Book src={book} alt="livre magique" />
    <S.SubTitle>
      «&nbsp;Arrêtez tout, rien ne va&nbsp;!&nbsp;»
      <br />
      C’est comme ça qu’un manifeste commence, en général. D’ailleurs, c’est
      même le but d’un manifeste :
      <br />
      de dire que tout va mal et qu’il faut y remédier. Mais pas dans celui-là.
      Pas ici.
    </S.SubTitle>
  </S.Container>
);

/*
 * Export
 */
export default Title;
