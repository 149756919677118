/*
 * SEO
 */
const seo = {
  title: 'Page non trouvée - Momentum',
  description: '',
  og: { title: '', description: '', image: '' },
  color: 'default',
};

/*
 * Export
 */
export default { seo };
