/*
 * Package import
 */

/*
 * Local import
 */

/*
 * Code
 */
export const externalRedirects = [
];


/**
 * internalRedirects
 * @type {Array}
 */
export const internalRedirects = [
];
