/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, colors, medias, utils, weights } from 'src/themes';
import shadowButton from 'assets/files/images/common/boutonOmbrePixel.svg';

/*
 * Components
 */
export const MainTitle = styled.h2({
  paddingTop: '11em',
  textTransform: 'uppercase',
  font: `${weights.bold} 1.7em/1.2 Quicksand`,
  margin: 'auto',
  textAlign: 'center',

  [medias.maxWidth(breakpoints.xl)]: {
    paddingTop: '9em',
  },
  [medias.maxWidth(breakpoints.l)]: {
    paddingTop: '7em',
  },
  [medias.maxWidth(breakpoints.m)]: {
    paddingTop: '13em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const Timeline = styled.div({
  position: 'relative',
  padding: '0 5em 7em',
  marginTop: '7em',

  [medias.maxWidth(breakpoints.s)]: {
    padding: '0 2em 7em',
  },
});

export const Step = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '6em',

  [medias.minWidth(breakpoints.l)]: {
    '&:nth-of-type(odd)': {
      flexDirection: 'row-reverse',
    },
    '&:nth-of-type(even)': {
      textAlign: 'right',
    },
  },
  [medias.maxWidth(breakpoints.l)]: {
    flexDirection: 'column',
    position: 'relative',
  },
});

export const Text = styled.div({
  maxWidth: '40%',

  [medias.maxWidth(breakpoints.l)]: {
    maxWidth: '100%',
    margin: '3em 0 0',
  },
});

export const Title = styled.h4({
  textTransform: 'uppercase',
  margin: '0 0 .5em',
  font: `${weights.bold} 1em/1.2 Quicksand`,
});

export const Paragraph = styled.div({
  lineHeight: '1.7em',
  fontSize: '0.9em',
});

export const Day = styled.div({
  color: 'white',
  background: colors.momentumOrange,
  height: 'fit-content',
  width: 'fit-content',
  padding: '0.1em 0.4em',
  textTransform: 'uppercase',
  fontSize: '0.8em',
  letterSpacing: '0em',
  zIndex: 1,

  [medias.maxWidth(breakpoints.l)]: {
    padding: '0.1em 1.5em',
    margin: '0 0 0 -2em',
    order: '-1',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '0.1em 1em',
    margin: '0 0 0 -1.25em',
  },
});

export const Illustration = styled.img({
  width: '40%',
  height: '13em',

  [medias.maxWidth(breakpoints.l)]: {
    order: '-1',
    position: 'absolute',
    width: '6em',
    height: '6em',
    top: '-2.5em',
    left: '0',
    right: '0',
    margin: 'auto',
  },
});

export const OrangeLine = styled.div({
  position: 'absolute',
  height: '100%',
  width: '0.5em',
  background: colors.momentumOrange,
  left: 0,
  right: 0,
  margin: '0 auto',

  [medias.maxWidth(breakpoints.l)]: {
    margin: '0 auto 0 3em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    margin: '0 auto 0 1em',
  },
});

export const LastDay = styled.div({
  position: 'relative',
  height: 'fit-content',
  width: '69%',
  margin: 'auto',
  padding: '4em',
  textAlign: 'center',
  color: 'white',
  background: colors.momentumBlack,

  [medias.maxWidth(breakpoints.l)]: {
    width: 'auto',
    margin: '0 3em',
    padding: '4em 1em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    margin: '0 2em',
    padding: '4em 1em',
  },
  [Day]: {
    width: '5em',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-0.8em',
    margin: 'auto',

    [medias.maxWidth(breakpoints.l)]: {
      width: 'fit-content',
      padding: '0.1em 1.5em',
      margin: '0',
      order: '-1',
    },
    [medias.maxWidth(breakpoints.s)]: {
      padding: '0.1em 1em',
      margin: '0 0 0 -1.25em',
    },
  },
  [Title]: {
    color: colors.momentumOrange,
    margin: '0 0 1em',
    fontSize: '1.4em',
  },
  hr: {
    borderTop: 'white solid 2px',
    width: '4em',
    margin: '1em auto 2.5em',

    [medias.maxWidth(breakpoints.s)]: {
      display: 'none',
    },
  },
});

export const CatchPhrase = styled.p({
  position: 'relative',
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  fontSize: '1.2em',
  width: 'fit-content',
  margin: 'auto',

  [medias.maxWidth(breakpoints.m)]: {
    fontSize: '0.8em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    display: 'none',
  },
});

export const Cta = styled.a({
  ...utils.backSvgFill(shadowButton),
  backgroundPosition: 'bottom',
  margin: '2em auto 0',
  width: '12em',
  height: '3.9em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: weights.bold,

  [medias.maxWidth(breakpoints.s)]: {
    width: '10em',
    height: '3.2em',
  },
});
