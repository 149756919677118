/*
 * Package Import
 */
import React, { Fragment } from 'react';

/*
 * Local Import
 */
// Assets
import clock from 'assets/files/images/program/clock.svg';
import calendar from 'assets/files/images/program/calendar.svg';
import computer from 'assets/files/images/program/computer.svg';
import schoolHat from 'assets/files/images/program/schoolHat.svg';
import drop from 'assets/files/images/program/package.svg';

// Style
import * as S from './style';

/**
 * Data
 */
const data = [
  {
    icon: clock,
    title: 'Durée du programme :',
    list: ['11 jours'],
  },
  {
    icon: calendar,
    title: 'Date de prochaine rentrée :',
    list: ['15 novembre 2021'],
  },
  {
    icon: computer,
    title: 'Format pédagogique :',
    list: ['Téléprésentiel'],
  },
  {
    icon: schoolHat,
    title: 'Prérequis :',
    list: ['Aucun'],
  },
  {
    icon: drop,
    title: 'Matériel nécessaire :',
    list: ['Ordinateur', 'Connexion internet', 'Curiosité'],
  },
];

/*
 * Component
 */
const Title = () => (
  <S.Container>
    <S.Title>
      11 jours, 5 métiers, 1&nbsp;vocation&nbsp;:
      <br />
      <S.Highlight>tout un programme</S.Highlight>
    </S.Title>
    <S.Panel>
      <S.Tldr>
        Il y a beaucoup de choses à dire sur ce programme.
        <br />
        Mais si tu veux gagner du temps, on ne t’en voudra pas, on a même prévu
        le coup.
        <br />
        Cet encart est là pour ça.
      </S.Tldr>
      <S.Items>
        {data.map(({ icon, title, list }) => (
          <Fragment key={title}>
            <S.Item icon={icon}>
              {title}
              <S.List>
                {list.map(element => (
                  <S.Point key={element}>{element}</S.Point>
                ))}
              </S.List>
            </S.Item>
          </Fragment>
        ))}
      </S.Items>
    </S.Panel>
  </S.Container>
);

/*
 * Export
 */
export default Title;
