/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import Home from 'src/containers/Home';
import Program from 'src/containers/Program';
import Jobs from 'src/containers/Jobs';
import SignUp from 'src/containers/SignUp';
import Manifestum from 'src/containers/Manifestum';
import Rgpd from 'src/containers/Rgpd';
import Mentions from 'src/containers/Mentions';
import { getPathname } from 'src/routes/utils/paths';

/*
 * Routes
 */
export default [
  // Static pages
  {
    exact: true,
    path: getPathname('index'),
    component: Home,
  },
  {
    path: getPathname('program'),
    component: Program,
  },
  {
    path: getPathname(['jobs', 'webdev']),
    component: () => <Jobs page="webdev" />,
  },
  {
    path: getPathname(['jobs', 'cm']),
    component: () => <Jobs page="cm" />,
  },
  {
    path: getPathname(['jobs', 'cybersecurity']),
    component: () => <Jobs page="cybersecurity" />,
  },
  {
    path: getPathname(['jobs', 'webdesigner']),
    component: () => <Jobs page="webdesigner" />,
  },
  {
    path: getPathname(['jobs', 'digitalmediator']),
    component: () => <Jobs page="digitalmediator" />,
  },
  {
    path: getPathname('signup'),
    component: SignUp,
  },
  {
    exact: true,
    path: getPathname('manifestum'),
    component: Manifestum,
  },
  {
    exact: true,
    path: getPathname('rgpd'),
    component: Rgpd,
  },
  {
    exact: true,
    path: getPathname('mentions'),
    component: Mentions,
  },
];
