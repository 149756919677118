/*
 * Package Import
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/*
 * Local Import
 */
import paths from 'src/routes/data/paths';
import * as S from './style';

/*
 * Component
 */
const InnerMenu = ({ children, isMobile, closeMenu }) => {
  /**
   * State
   */
  const [open, setOpen] = useState(false);

  /**
   * Refs
   */
  const node = useRef();

  /**
   * Handlers
   */
  const handleOpen = (evt) => {
    if (!isMobile) {
      const isLink = evt.target.nodeName === 'A';
      if (!node.current.contains(evt.target) && !isLink) {
        // outside click
        setOpen(false);
      }
      else if (!evt.target?.href) {
        setOpen(true);
      }
    }
    else if (evt.type === 'click') {
      if (node.current.contains(evt.target)) {
        // inside click
        setOpen(!open);
      }
      else {
        setOpen(false);
      }
    }
  };

  const handleClose = () => {
    if (!isMobile) {
      // outside click
      setOpen(false);
    }
  };

  const closeMenuMobile = () => {
    if (isMobile) {
      closeMenu();
    }
  };

  /**
   * Lifecycle
   */
  useEffect(() => {
    // Listener to check if click is inside the button
    document.addEventListener('mousedown', handleOpen);

    return () => {
      document.removeEventListener('mousedown', handleOpen);
    };
  }, []);

  return (
    <>
      <S.InnerMenu
        ref={node}
        onMouseEnter={handleOpen}
        onClick={handleOpen}
        aria-expanded={open}
        aria-haspopup="true"
        aria-label="Nos métiers. Appuyez sur entrée pour ouvrir le sous menu."
      >
        {children}
      </S.InnerMenu>
      {open && (
        <S.SubMenu onMouseLeave={handleClose}>
          {Object.values(paths.jobs.children).map(({ name, pathname }) => (
            <S.SubLink
              key={name}
              onClick={closeMenuMobile}
              to={`/nos-metiers${pathname}`}
            >
              {name}
            </S.SubLink>
          ))}
        </S.SubMenu>
      )}
    </>
  );
};

/*
 * PropTypes
 */
InnerMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default withRouter(InnerMenu);
