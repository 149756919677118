/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, colors, medias, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.div({
  marginTop: '98px',
  padding: '4em',

  [medias.maxWidth(breakpoints.m)]: {
    padding: '4em 2em 0',
  },
});

export const Intro = styled.div({
  fontWeight: weights.bold,
  fontFamily: 'Lato',
  maxWidth: '55em',
  margin: 'auto',
  textAlign: 'center',

  '&::after': {
    content: '""',
    width: '8em',
    height: '0.1em',
    margin: '2em auto 0',
    background: colors.momentumOrange,
    display: 'block',
  },
});

export const LoopIntro = styled.div({
  margin: '0 auto 1em',
});

export const Block = styled.div({
  position: 'relative',
  maxWidth: '42em',
  margin: '6em auto',
});

export const Title = styled.h3({
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  lineHeight: '1.8em',
  color: colors.momentumOrange,
  fontFamily: 'Quicksand',
  fontSize: '1.1em',
});

export const SubTitle = styled.h4({
  fontSize: '0.9em',
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  margin: '0.5em 0 0.2em',
});

export const Paragraph = styled.p({
  marginBottom: '2em',
  lineHeight: '1.8em',
});

export const Ul = styled.ul({
  margin: '-2em 0 1em 2em',
});

export const Li = styled.li({
  position: 'relative',

  '::before': {
    content: '"–"',
    position: 'absolute',
    left: '-0.7em',
  },
});
