/*
 * Local Import
 */
import fioleMediateurNumerique from 'assets/files/images/home/fioleMediateurNumerique.svg';
import cardMediateur from 'assets/files/images/jobs/cardMediateur.svg';
import mediateurNumeriqueMissions from 'assets/files/images/jobs/mediateurNumeriqueMissions.jpg';
import mediateurNumeriqueQualite from 'assets/files/images/jobs/mediateurNumeriqueQualite.jpg';

/**
 * data
 */
export default {
  title: 'médiateur numérique',
  id: 'digitalmediator',
  card: {
    logo: fioleMediateurNumerique,
    missions: [
      'Informer et conseiller les publics sur les usages du numérique',
      'Animer des ateliers d’accompagnement et de prise en main des outils numériques',
      'Organiser des actions de médiation',
      'Communiquer et valoriser les activités réalisées par une structure',
    ],
    qualities: ['Patient', 'Pédagogue', 'Sens relationnel'],
    skills: [
      'Connaissances techniques',
      'Communication',
      'Connaissances des enjeux de société',
    ],
    salary: cardMediateur,
    evolutions: ['Responsable de la gestion et de l’organisation'],
    activity: [
      'Centre culturel de loisirs',
      'SAV',
      'Collectivités territoriales',
      'Entreprises',
    ],
  },
  summary: [
    {
      title: 'Les missions du médiateur numérique',
      text: [
        'Le rôle du médiateur numérique est semblable à celui d’un guide. Il va aider à la compréhension des enjeux, usages et technologies du numérique en montrant le chemin à suivre.',
        'Comment ? En animant des formations et ateliers où il va proposer des débats, mises en situation et autres animations ludiques. Dans notre société actuelle, dire qu’il est difficile de vivre sans le numérique serait un euphémisme, car il est omniprésent. Mais tout le monde n’est pas formé et sensibilisé à ses usages.',
        'C’est pourquoi le métier de médiateur numérique est indispensable et peut intervenir dans bon nombre de domaines, comme dans un centre culturel auprès d’un public local pour proposer des ateliers comme : « savoir utiliser les réseaux sociaux » par exemple. Il peut également travailler dans des collectivités territoriales, espaces collaboratifs, pour des entreprises en animant des forums et autres activités, ou se mettre à son compte pour proposer ses services à différentes collectivités.',
      ],
      img: mediateurNumeriqueMissions,
      alt: 'Les missions du médiateur numérique',
    },
    {
      title: 'Les qualités cruciales du médiateur numérique',
      text: [
        'Il y a quelques qualités et compétences requises indispensables pour devenir médiateur numérique.',
        'Dans ce métier, les maîtres-mots sont : patience, pédagogie, communication, créativité et sens relationnel. Toutes ces qualités sont essentielles pour animer correctement des ateliers d’accompagnement et de prise en main des outils numériques. Mais pas que.',
        'Il doit également maîtriser parfaitement les logiciels de bureautique, Internet et autres outils numériques qu’il présente, connaître les enjeux de société et être constamment à jour sur les nouvelles pratiques du numérique.',
      ],
      img: mediateurNumeriqueQualite,
      alt: 'Les qualités du médiateur numérique',
    },
  ],
  salary: {
    title: 'Salaire et évolutions du médiateur numérique',
    text:
      'On parle ici d’un métier très récent, donc difficile d’établir une moyenne de salaire. L’employeur est très souvent public et selon que l’on travaille pour une petite ville ou une grande collectivité territoriale, le salaire est d’environ 18K. Les perspectives d’évolutions sont multiples : se spécialiser sur un domaine du numérique particulier ou prendre en charge la coordination des  médiateurs numériques au sein des collectivités, par exemple.',
    evolution: [
      {
        post: 'Médiateur numérique junior',
        salary: '18 000€ par an.',
      },
      {
        post: 'Médiateur numérique confirmé',
        salary: '19 000€ par an.',
      },
      {
        post: 'Médiateur numérique senior',
        salary: '26 000€ par an.',
      },
    ],
  },
  hats: {
    formation: {
      title: 'Les formations pour devenir médiateur numérique',
      text:
        'Le médiateur numérique fait partie de ces nouveaux métiers de plus en plus demandés par le secteur publique. Pour le devenir, il existe essentiellement des organismes de formations courtes. Mais, toute personne passée par un cursus "numérique" peut prétendre à ce genre de poste.',
    },
  },
  cta: {
    content: [
      'Ça t’a donné envie d’en savoir plus ?',
      'Viens explorer le métier de médiateur numérique dans notre programme de découverte !',
    ],
    link: {
      to: '/programme',
      text: 'programme',
    },
  },
};
