/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import saveRef from '@oclock/save-ref';

/*
 * Local Import
 */
// Layout
import Head from 'src/components/Head';
import Footer from 'src/components/Footer';
import Nav from 'src/containers/Nav';
import Title from 'src/components/Home/Title';
import Jars from 'src/components/Home/Jars';
import TheWay from 'src/components/Home/TheWay';
import Pillars from 'src/components/Home/Pillars';
import Register from 'src/components/Register';

// Wrappers
import Main from 'src/components/Home/Main';
import Wrapper from 'src/components/Home/Wrapper';
import Section from 'src/components/Section';

// Data
import metas from './metas';

/*
 * Component
 */
class HomeContainer extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    screen: PropTypes.oneOf(['mobile', 'desktop']),
  };

  /*
   * Render
   */
  render() {
    const ref = saveRef(this);

    return (
      <Wrapper ref={ref('app')}>
        <Head {...metas} />
        <Nav />
        <Main>
          <Section color="black" noPadding>
            <Title />
          </Section>
          <Section color="white">
            <Jars />
          </Section>
          <Section color="black">
            <TheWay />
          </Section>
          <Section color="white">
            <Pillars />
          </Section>
          <Section color="black">
            <Register />
          </Section>
        </Main>
        <Footer />
      </Wrapper>
    );
  }
}

/*
 * Redux
 */
const mapStateToProps = state => ({
  screen: state.size && state.size < 900 ? 'mobile' : 'desktop',
});

const mapDispatchToProps = {};

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
