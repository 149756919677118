/*
 * Package Import
 */
import { utils as themeUtils } from '@oclock/theme';

/*
 * Extends
 */

// Clearfix
const clearfix = () => ({
  '::before, ::after': {
    clear: 'both',
    content: '""',
    display: 'table',
  },
});

/*
 * Autofill
 */
const autofillBackground = color => ({
  // Impossible to override the background
  // We go wild: enormous inset box-shadow!
  ':-webkit-autofill': {
    boxShadow: `inset 0 0 0 1000px ${color}`,
  },
});

const autofillColor = color => ({
  ':-webkit-autofill': {
    WebkitTextFillColor: color,
  },
});

/**
 * Underline color
 * @param {String} color
 * @return {String}
 * */
const backgroundUnderlineColor = color => ({
  backgroundImage: `linear-gradient(to bottom, transparent 50%, ${color} 50%)`,
});

/**
 * Background in one line with extension
 * @param {String} img
 * @param {String} opts? default : null
 * @param {String} ext?  default : 'png'
 * @return {Object}
 * */
const back = (img, opts = null) => (!opts ? { background: `url(${img})` } : { background: `url(${img}) ${opts}` });

const backSvg = (img, opts = null, size = null) => {
  const obj = {
    ...back(img, opts),
  };

  if (size) {
    obj.backgroundSize = size;
  }

  return obj;
};

const backFill = (img, opts = 'no-repeat') => ({
  ...back(img, opts),
  backgroundSize: '100%',
});

/**
 * Background SVG
 * @param {String} img
 * @param {String} opts? default : 'no-repeat'
 * @return {String}
 * */
const backSvgFill = (img, opts = 'no-repeat', backgroundSize = '100%') => backSvg(img, opts, backgroundSize);

/**
 * Background image with default path
 * @param {String} img
 * @param {String} ext? default : 'png'
 * @return {String}
 * */
const backImage = img => ({
  backgroundImage: `url(${img})`,
});

/**
 * Jambage
 * @param  {Object} object { color: Sring, size?: Number }
 * @return {String}        text-shadow
 */
const jambObject = ({ color, size = 2 }) => {
  let str = '';
  const pas = 1;

  for (let i = -size; i <= size; i++) {
    for (let j = -1; j <= 1; j++) {
      if (!(i === 0 && j === 0)) {
        str += `${pas * i}px ${pas * j}px ${color}`;
        if (!(i === size && j === 1)) {
          str += ', ';
        }
      }
    }
  }

  return str;
};

const jamb = (options) => {
  if (typeof options === 'string') {
    return jambObject({ color: options });
  }

  return jambObject(options);
};

/**
 * Horloge
 * */
const hour = (h) => {
  // eslint-disable-next-line no-mixed-operators
  const deg = h * 30 - 90;
  return {
    transform: `rotate(${deg}deg)`,
  };
};

const minute = (m) => {
  // eslint-disable-next-line no-mixed-operators
  const deg = m * 6 - 90;

  return {
    transform: `rotate(${deg}deg)`,
  };
};

const forceStyleInMedia = (oldStyles, newStyles) => {
  const style = { ...oldStyles, ...newStyles };
  Object.keys(oldStyles).forEach((oldKey) => {
    if (oldKey.slice(0, 6) === '@media') {
      Object.keys(newStyles).forEach((newKey) => {
        if (newKey in style[oldKey]) {
          style[oldKey][newKey] = newStyles[newKey];
        }
      });
    }
  });
  return style;
};

/*
 * Export
 */
export const utils = {
  ...themeUtils,

  // Extends
  clearfix,

  // Autofill
  autofillBackground,
  autofillColor,

  backgroundUnderlineColor,

  // Images
  backSvg,
  backFill,
  backSvgFill,
  backImage,

  // Jamb
  jamb,

  // Horloge
  hour,
  minute,

  // Force
  forceStyleInMedia,
};
