/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors, breakpoints, medias, utils, weights } from 'src/themes';
import shadowButton from 'assets/files/images/common/boutonOmbrePixel.svg';

/*
 * Component
 */
export const Container = styled.div({
  padding: '5em 0',
  textAlign: 'center',
});

export const Title = styled.h1({
  fontWeight: weights.black,
  letterSpacing: '.12em',
  font: `${weights.normal} 2em/1.3 Quicksand`,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2em 2em 1em',

  /* @medias */
  [medias.maxWidth(breakpoints.iphone6s)]: {
    fontSize: '1.6em',
  },
  [medias.betweenWidth(breakpoints.xs, breakpoints.m)]: {
    fontSize: '1.4em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '2em 0.5em 1em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const Beginning = styled.p({
  textAlign: 'center',
  padding: '0 2em',
  fontFamily: 'Quicksand',

  [medias.maxWidth(breakpoints.s)]: {
    padding: '0 1em',
  },
});

export const Form = styled.form({
  maxWidth: '30em',
  margin: '2em auto',
  padding: '0 2em',
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',

  ':nth-last-of-type(-n+3)': {
    order: 1,
  },
  '::before': {
    content: '""',
    opacity: '0',
    transition: '.8s opacity',
  },
});

export const ErrorMessage = styled.div({
  position: 'absolute',
  top: '10em',
  left: 0,
  right: 0,
});

export const Input = styled.input(({ isValidated }) => ({
  fontFamily: 'Lato !important',
  border: 0,
  display: 'block',
  font: `${weights.normal} 2.4rem/1.5 "Oclock"`,
  padding: '0.25em 0.25em 0.25em 2.4em',
  width: '100%',
  borderRadius: '9px',
  margin: '0.4em 0',
  height: '2.5em',

  '&:first-of-type': {
    margin: '7em 0 0.4em',
  },
  '&:last-of-type': {
    order: 1,
  },
  '&::placeholder': {
    fontFamily: 'Lato',
    color: 'grey',
    opacity: 1,
  },

  '&:focus': {
    '&::placeholder': {
      opacity: '0.25',
    },
  },

  ...(!isValidated && {
    background: '#F97071',
    color: colors.white,
    '&::placeholder': {
      fontFamily: 'Lato',
      color: colors.white,
      opacity: 1,
    },
  }),
  [medias.maxWidth(breakpoints.m)]: {
    padding: '0.25em 0.4em',
  },
}));

export const DatePicker = styled.input(({ isValidated }) => ({
  width: '100%',
  height: '2.5em',
  fontFamily: 'Lato',
  fontSize: '1em',
  borderRadius: '9px',
  padding: '0.25em 0.25em 0.25em 2.4em',
  margin: '0.4em 0',
  color: 'grey',
  background: colors.white,

  ...(!isValidated && {
    background: '#F97071',
    color: 'white',
    '&::placeholder': {
      fontFamily: 'Lato',
      color: 'white',
      opacity: 1,
    },
  }),
  [medias.maxWidth(breakpoints.m)]: {
    padding: '0.25em 0.4em',
  },
}));

export const SelectContainer = styled.div({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  order: 0,
  margin: '0.4em 0',

  '>div': {
    margin: '0.4em 0',
    width: '100%',
  },
});

export const TownContainer = styled.div({
  display: 'flex',
  width: '100%',
  order: 1,

  [Input]: {
    margin: '0.4em 0',
    '&:first-of-type': {
      width: '150%',
      margin: '0.4em 1em 0.4em 0',
    },
  },
});

export const Stepbar = styled.div(({ step }) => ({
  width: '100%',
  margin: '2em 0 0',
  height: '0.6em',
  borderRadius: '9px',
  background: colors.white,
  order: 1,

  '&::before': {
    content: '""',
    width: `${step * 50}%`,
    background: colors.momentumOrange,
    height: '0.6em',
    position: 'absolute',
    left: 10,
    borderRadius: '9px',
  },
}));

export const Step = styled.div({
  margin: 'auto',
  order: 1,
});

export const Cta = styled.button(({ step }) => ({
  ...utils.backSvgFill(shadowButton),
  backgroundPosition: 'bottom',
  color: colors.white,
  margin: '2em auto 0',
  width: '12em',
  height: '3.9em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  ...(step === 2 && {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '-6em',
  }),

  [medias.maxWidth(breakpoints.s)]: {
    width: '10em',
    height: '3.2em',
  },
}));

export const PreviousStep = styled.button({
  position: 'absolute',
  color: colors.white,
  fontSize: '0.8em',
  background: utils.underline({ color: colors.white }),
  left: '0.6em',
  bottom: '0.1em',
});
