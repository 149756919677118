/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Local Import
 */
import { breakpoints, colors, medias, utils } from 'src/themes';

/*
 * Keyframes
 */

/*
 * Components
 */
export const Footer = styled.footer`
  background: ${colors.momentumBlack};
  padding-bottom: 4em;
`;

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
  maxWidth: `${breakpoints.xl + 20}px`,
  padding: '3em 0 1em',
  borderTop: `2px solid ${colors.momentumOrange}`,

  [medias.betweenWidth(breakpoints.s, breakpoints.l)]: {
    maxWidth: '30em',
  },

  [medias.maxWidth(breakpoints.l)]: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em 0 0',
  },

  [medias.maxWidth(breakpoints.xs)]: {
    fontSize: '.95em',
  },
});

export const Links = styled.nav(
  {
    display: 'flex',
    flexDirection: 'column',
    width: '7em',
    alignItems: 'flex-start',
    padding: '0 0 1em',

    [medias.maxWidth(breakpoints.xxl)]: {
      marginLeft: '1.5em',
    },
    [medias.maxWidth(breakpoints.l)]: {
      alignItems: 'center',
      marginLeft: '0',
      padding: '1em 0 0',
      width: '11em',
    },
  },
  ({ hasBefore }) => hasBefore && {
    [medias.maxWidth(breakpoints.l)]: {
      '::before': {
        content: '""',
        width: '2em',
        height: '1px',
        background: colors.grayLittle,
        marginBottom: '1em',
      },
    },
  },
);

export const Link = styled(RouterLink, {
  shouldForwardProp: prop => !['isActive'].includes(prop),
})(({ isActive }) => ({
  position: 'relative',
  margin: '0.3em 0',
  color: 'white',
  fontSize: '0.85em',
  letterSpacing: '0.02em',

  '&:hover': {
    background: utils.underline({ color: 'white' }),
    textShadow: utils.jamb(colors.black),
  },
  ...(isActive && {
    '&::before, &::after': {
      position: 'absolute',
      content: '""',
      margin: '0 .5em',
    },
    // Right Triangle
    '&::before': {
      borderLeft: `10px solid ${colors.momentumOrange}`,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      left: '-1.5em',
      top: '0.5em',
    },
    // Left Triangle
    '&::after': {
      borderRight: `10px solid ${colors.momentumOrange}`,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      right: '-1.5em',
      top: '0.5em',
    },
  }),
}));

export const Form = styled.div`
  max-width: 17em;

  ${medias.maxWidth(breakpoints.l)} {
    max-width: 100%;
    width: 100%;
  }

  ${medias.maxWidth(breakpoints.s)} {
    display: none;
  }
`;

export const FormText = styled.p`
  color: ${colors.grayLittle};
  font-style: italic;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-align: center;

  ${medias.maxWidth(breakpoints.l)} {
    max-width: 20em;
    margin: 2em auto 0;
  }
`;

export const LogoContainer = styled.div({
  color: 'white',
  display: 'flex',
  flexDirection: 'column',

  [medias.maxWidth(breakpoints.l)]: {
    order: -1,
  },
});

export const MomentumLogo = styled.img({
  margin: '-1em auto',
  width: '17em',

  [medias.maxWidth(breakpoints.s)]: {
    width: '14em',
  },
});

export const Momentum = styled.div({});

export const Oclock = styled.div({
  display: 'flex',
  margin: '2em auto',
  fontSize: '0.8em',

  [medias.maxWidth(breakpoints.l)]: {
    display: 'none',
  },
});

export const ExternalLink = styled.a({
  marginTop: '-0.2em',
});

export const LogoOclock = styled.img({
  background: utils.underline({ color: 'white' }),
  width: '4em',
});

export const Contact = styled.div({
  width: 'fit-content',
  color: 'white',
  fontSize: '0.8em',
  textAlign: 'center',

  [medias.maxWidth(breakpoints.xxl)]: {
    marginRight: '1.5em',
  },
  [medias.maxWidth(breakpoints.l)]: {
    marginRight: '0',
  },
  [medias.minWidth(breakpoints.l)]: {
    [Links]: {
      margin: 'auto 0 auto auto',
      [Link]: {
        margin: 'auto 0 auto auto',
      },
    },
  },
});

export const Mail = styled.a({});

export const SocialNetworks = styled.div({
  [medias.maxWidth(breakpoints.l)]: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2em 0 0',
  },
});

export const SocialLogo = styled.img({
  width: '3em',
  margin: '1em 0 0',

  [medias.minWidth(breakpoints.l)]: {
    margin: '1em 0 0 1em',
    width: '2em',
  },
});
