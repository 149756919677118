/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colors } from 'src/themes';

/*
 * Components
 */
const Wrapper = styled.div({
  background: colors.momentumBlack,
});

export default Wrapper;
