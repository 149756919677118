/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const BurgerMenu = ({ clickCallBack }) => (
  <S.Button data-testid="burgerButton" onClick={clickCallBack}>
    <S.Menu>Menu</S.Menu>
    <S.Bars>
      <S.Bar />
      <S.Bar />
      <S.Bar />
    </S.Bars>
  </S.Button>
);

BurgerMenu.propTypes = {
  clickCallBack: PropTypes.func.isRequired,
};

/**
 * Export
 */
export default BurgerMenu;
