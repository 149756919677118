/*
 * Npm import
 */

/*
 * Local import
 */
import animatedScrollTo from 'animated-scroll-to';

/*
 * Code
 */
const scrollTo = (target, options = {}) => {
  // Vars
  const { add, prevent, ...opts } = options;
  const offset = add || 0;

  // Top
  const { scrollTop } = document.scrollingElement;
  const { top } = target.getBoundingClientRect();
  const targetTop = scrollTop + top;

  // New top = targetTop + offset
  // We must round the sum, as browser round height of elements,
  // but does not seem to round scrollTop value.
  //
  // e.g. scroll to 1256.87px will scroll to 1256px
  // whereas a 1256.87px <div> will display on screen with 1257px
  const newTop = Math.round(targetTop + offset);

  // Prevent
  if (prevent && prevent(scrollTop, newTop)) {
    return;
  }

  // Go
  animatedScrollTo(newTop, {
    speed: 300,
    maxDuration: 600,
    ...opts,
  });
};

/*
 * Export
 */
export default scrollTo;
