/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, colors, medias, utils, weights } from 'src/themes';

/*
 * Components
 */
export const Container = styled.main`
  background-color: ${colors.black};

  section {
    display: flex;
  }

  a {
    background: ${utils.underline(colors.cream)};
    text-shadow: ${utils.jamb(colors.black)};
  }

  ${medias.maxWidth(breakpoints.s)} {
    br {
      display: none;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em 1em;
  height: 100vh;
  flex-direction: column;
  padding: 5em 1em;
  margin: auto;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 3em;
  font-weight: ${weights.black};
  line-height: 1.2;
  text-transform: uppercase;
`;

export const Hello = styled.p`
  font-size: 1.3em;
  color: ${colors.momentumOrange};

  &::before {
    font-size: 1.5em;
    content: '✋';
    padding-right: 10px;
    position: relative;
    top: -3px;
  }
`;
