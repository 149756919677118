/*
 * Package import
 */
import React from 'react';
import { Route } from 'react-router-dom';

/*
 * Local import
 */
import { RedirectWithStatus } from 'src/components/Status';
import pages from 'src/routes/data/pages';
import {
  internalRedirects,
  externalRedirects,
} from 'src/routes/data/redirects';

/*
 * Redirects
 */
// Internal
const internalRoutes = internalRedirects.map(route => (
  <RedirectWithStatus key={route.from} {...route} />
));

// External: <Redirect /> does not work in front-end
let externalRoutes;
if (typeof window !== 'undefined') {
  // Client side rendered
  externalRoutes = externalRedirects.map(route => (
    <Route
      key={route.from}
      path={route.from}
      component={() => {
        window.location.replace(route.to);
        return null;
      }}
    />
  ));
}
else {
  // Server side rendered
  externalRoutes = externalRedirects.map(route => (
    <RedirectWithStatus key={route.from} {...route} />
  ));
}

export const Redirects = [...internalRoutes, ...externalRoutes];

/*
 * Pages
 */
export const Pages = pages.map(route => <Route key={route.path} {...route} />);
