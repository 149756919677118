/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import { timeline } from './data';
import * as S from './style';

/*
 * Component
 */
const Timeline = () => (
  <>
    <S.MainTitle>
      Rétrécis tes choix,
      <br />
      augmente tes <S.Highlight>possibilités</S.Highlight>
    </S.MainTitle>

    <S.Timeline>
      <S.OrangeLine />
      {timeline.map(({ title, icon, alt, text, time }) => (
        <S.Step key={title}>
          <S.Text>
            <S.Title>{title}</S.Title>
            <S.Paragraph>{text}</S.Paragraph>
          </S.Text>
          <S.Day>{time}</S.Day>
          <S.Illustration src={icon} alt={alt} />
        </S.Step>
      ))}
    </S.Timeline>
    <S.LastDay>
      <S.Day>Jour 11</S.Day>
      <S.Title>Apprends.</S.Title>
      <S.Paragraph>
        Mission accomplie ! Enfin, pas tout à fait.
        <br />
        Si le programme Momentum s’arrête, ton aventure commence.
        <br />
        À ce stade, le but est de te proposer des écoles qui te permettront
        d’apprendre le métier que tu auras choisi et ainsi, faire de cette
        vocation naissante, une réalité !
        <hr />
      </S.Paragraph>
      <S.CatchPhrase>
        Alors, prêt&nbsp;à&nbsp;découvrir&nbsp;ton&nbsp;avenir&nbsp;?
      </S.CatchPhrase>
      <S.Cta href="#contact-form">Je m’inscris</S.Cta>
    </S.LastDay>
  </>
);

/*
 * Export
 */
export default Timeline;
