/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { weights } from 'src/themes';

/*
 * Components
 */
export const Button = styled.div`
  float: right;
  text-align: center;
  cursor: pointer;
  width: 9rem;
  height: 9rem;
  padding-top: 2.7rem;
`;

export const Menu = styled.div`
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 1rem;
  letter-spacing: 0;
  color: white;
  font-weight: ${weights.black};
`;

export const Bar = styled.div`
  width: 3rem;
  height: 0.35rem;
  border-radius: 1rem;
  margin: 0.45rem auto;
`;

export const Bars = styled.div({
  [Bar]: {
    background: 'white',
  },
});
