/*
 * Npm import
 */


/*
 * Local import
 */


/*
 * Code
 */


/*
 * Export defaut
 */
export default (func) => {
  // Store
  // We will store result under json key representing arguments
  const memo = {};

  return (...args) => {
    // Get store key
    const json = JSON.stringify(args);

    // If the result is in store
    if (json in memo) {
      // Just return it
      return memo[json];
    }

    // Else, we compute the result
    const result = func(...args);

    // Then save it in store for later and return it
    memo[json] = result;
    return result;
  };
};
