/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Nav from 'src/components/Nav';
import { links } from './data';

/*
 * Code
 */

// State
const mapStateToProps = state => ({
  links,
  activeSection: state.section,
  isMobile: !!state.size && state.size < 900,
});

// Actions
// const mapDispatchToProps = {};

/*
 * Export
 */
export default connect(mapStateToProps /* , mapDispatchToProps */)(Nav);
