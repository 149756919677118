/*
 * Package Import
 */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { selectStyleOverride } from './style';

/*
 * Component
 */
export default class SelectField extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    // Props
    name: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool.isRequired,
    choices: PropTypes.array.isRequired,
    noOptionsMessage: PropTypes.string,

    // From Others Components
    onSelectChange: PropTypes.func,
    transformText: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    onSelectChange: null,
    transformText: null,
    noOptionsMessage: '',
  };

  /*
   * State
   */
  state = {};

  /*
   * Handlers
   */
  onSelectChange = (evt) => {
    this.props.onSelectChange(evt.label);
  };

  /*
   * Code
   */
  getOptionLabel = (option) => {
    const { transformText } = this.props;

    return (
      // If we have the prop `transformText`, display it.
      (transformText && transformText(option.label))
      // Otherwise, just return the value of `label`
      || option.label
    );
  };

  /*
   * Render
   */
  render() {
    const { choices, value, ...inputProps } = this.props;

    /**
     * React-Select V2
     * @API : https://react-select.com/props
     */
    return (
      <Select
        {...inputProps}
        onChange={this.onSelectChange}
        options={choices}
        styles={selectStyleOverride}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionLabel}
        noOptionsMessage={() => this.props.noOptionsMessage}
        isSearchable={false}
        value={choices.find(option => option.label === value)}
      />
    );
  }
}
