/* eslint-disable id-length */
/*
 * Local Import
 */
import { breakpoints, colors, medias, weights } from 'src/themes';

/*
 * Root Style
 */
export default {
  // Html
  html: {
    fontSize: '55%',
    [medias.minWidth(breakpoints.xxl)]: {
      fontSize: '56%',
    },
    [medias.minWidth(breakpoints.xxxl)]: {
      fontSize: '57%',
    },
    [medias.minWidth(breakpoints.huge)]: {
      fontSize: '58%',
    },
    [medias.minWidth(breakpoints.full)]: {
      fontSize: '59%',
    },
    [medias.minWidth(breakpoints.fullXl)]: {
      fontSize: '60%',
    },
    [medias.minWidth(breakpoints.fullXxl)]: {
      fontSize: '61%',
    },
    [medias.minWidth(breakpoints.fullXxxl)]: {
      fontSize: '62%',
    },
  },

  // Body
  body: {
    color: colors.grayDark,
    font: `${weights.normal} 2.4em/1.5 Lato`,
    letterSpacing: '0.04em',
    minWidth: breakpoints.xxs,
    textRendering: 'optimizeLegibility',
    width: '100%',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
  },

  header: {
    font: `${weights.normal} 2.4em/1.5 Lato`,
  },

  footer: {
    font: `${weights.normal} 1em/1.5 Lato`,
  },

  p: {
    letterSpacing: 0,
  },

  '#root ~ .textAd': {
    top: '-99px',
  },
};
