/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

/*
 * Local Import
 */
import { env } from '~adaptors';
import { changeSize } from 'src/store/ducks/size';

/*
 * Redux
 */
const mapStateToProps = null;

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeSize }, dispatch);

const createContainer = connect(
  mapStateToProps,
  mapDispatchToProps
);

/*
 * Component
 */
class Size extends React.Component {
  /*
   * PropTypes
   */
  static propTypes = {
    changeSize: PropTypes.func.isRequired,
  };

  /*
   * Lifecycles
   */
  componentDidMount() {
    if (env === 'client') {
      window.addEventListener('resize', this.change);
      this.change();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.change);
  }

  change = () => {
    this.props.changeSize(window.innerWidth);
  };

  /*
   * Render
   */
  render() {
    return null;
  }
}

/*
 * Export
 */
export default createContainer(Size);
