/*
 * Pacakge Import
 */
import React from 'react';

/*
 * Local Import
 */
import Button from './Button';
import * as S from './style';

/*
 * Component
 */
const Gender = props => (
  <S.SignupGender>
    <Button gender="woman" {...props} />
    <Button gender="man" {...props} />
  </S.SignupGender>
);

/*
 * Export
 */
export default Gender;
