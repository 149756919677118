/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { breakpoints, colors, medias, utils, weights } from 'src/themes';
import encartPixel from 'assets/files/images/program/encartPixel.svg';

/*
 * Components
 */
export const Title = styled.h2({
  margin: '3em 5em',
  textTransform: 'uppercase',
  font: `${weights.bold} 1.7em/1.2 Quicksand`,

  [medias.maxWidth(breakpoints.xl)]: {
    textAlign: 'center',
  },
  [medias.maxWidth(breakpoints.l)]: {
    fontSize: '1.3em',
    margin: '3em 1em',
  },
});

export const Highlight = styled.span({
  color: colors.momentumOrange,
});

export const Job = styled.div({
  position: 'relative',
  display: 'flex',
  padding: '0 5em 5em',
  alignItems: 'center',

  [medias.maxWidth(breakpoints.xl)]: {
    overflow: 'hidden',
    flexDirection: 'column',
    padding: '0',
  },
});

export const Insert = styled.div({
  width: '40em',
  height: '14em',
  ...utils.backSvgFill(encartPixel, 'no-repeat center'),
  padding: '2em 2em 2em 12em',

  hr: {
    borderBottom: `3px solid ${colors.momentumOrange}`,
    width: '2em',
    margin: '0.5em 0 1em 0',
  },

  [medias.maxWidth(breakpoints.xl)]: {
    width: '50em',
    margin: '0 0 0 14em',
    height: '18em',
    padding: '2em 12em 2em 12em',
  },
  [medias.maxWidth(breakpoints.l)]: {
    position: 'absolute',
    left: 0,
    margin: '0 0 0 2em',
    padding: '2em 19em 2em 12em',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '2em 29em 2em 3em',
  },
  [medias.maxWidth(breakpoints.s)]: {
    padding: '2em 37.5em 2em 3em',
  },
});

export const Name = styled.p({
  color: colors.momentumOrange,
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  lineHeight: '1.2em',
});

export const Position = styled.p({
  fontWeight: weights.bold,
  lineHeight: '1em',
  fontSize: '0.8em',
});

export const Text = styled.p({
  fontSize: '0.8em',
});

export const Menu = styled.ul({
  [medias.minWidth(breakpoints.xl)]: {
    marginLeft: '3em',
  },
  [medias.maxWidth(breakpoints.xl)]: {
    width: '30em',
    margin: '2em 0',
    justifyContent: 'center',
    height: '5em',
    display: 'flex',
    flexWrap: 'wrap',
  },
  [medias.maxWidth(breakpoints.l)]: {
    margin: '21em 0 4em',
  },
  [medias.maxWidth(breakpoints.m)]: {
    width: '100%',
  },
  [medias.maxWidth(breakpoints.s)]: {
    margin: '26em 0 4em',
    fontSize: '0.8em',
  },
});

export const Item = styled.li({
  [medias.maxWidth(breakpoints.xl)]: {
    margin: '0 1em',
  },
});

export const Link = styled.button(({ isActive }) => ({
  position: 'relative',
  textTransform: 'uppercase',
  fontWeight: weights.bold,
  color: isActive ? colors.momentumOrange : 'white',
  fontSize: '0.8em',
  marginBottom: '0.8em',
  textAlign: 'left',

  [medias.minWidth(breakpoints.xl)]: {
    ...(isActive && {
      // Triangles left & right
      '&::before, &::after': {
        position: 'absolute',
        content: '""',
        margin: '0 .5em',
      },
      '&::before': {
        borderLeft: '10px solid white',
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        left: '-1.5em',
        top: '0.5em',
      },
      '&::after': {
        borderRight: '10px solid white',
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        right: '-1.5em',
        top: '0.5em',
      },
    }),
  },
}));

export const Class = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10em 5em 5em',

  [medias.maxWidth(breakpoints.xl)]: {
    flexDirection: 'column',
  },
  [medias.maxWidth(breakpoints.m)]: {
    padding: '10em 2em 5em',
  },
});

export const Classroom = styled.img({
  width: '45%',
  height: 'fit-content',
  objectFit: 'contain',
  border: `3px solid ${colors.momentumOrange}`,

  [medias.maxWidth(breakpoints.xl)]: {
    width: '100%',
  },
});

export const Home = styled.div({
  width: '38%',
  [Title]: {
    margin: '0 0 0.8em',
    textAlign: 'left',

    [medias.maxWidth(breakpoints.xl)]: {
      margin: '2em 0 0.8em',
    },
  },

  [medias.maxWidth(breakpoints.xl)]: {
    width: '100%',
  },
});
