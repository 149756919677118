/*
 * Package Import
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

/*
 * Local Import
 */
import section from 'src/store/ducks/section';
import size from 'src/store/ducks/size';

/*
 * Combine ...Reducers
 */
const reducer = history => combineReducers({
  router: connectRouter(history),
  section,
  size,
});

/*
 * Export
 */
export default reducer;
