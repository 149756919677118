/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

/*
 * Local Import
 */
// import { getColor } from 'src/modules/favicon';
import { getColor, getFaviconPath } from 'src/modules/favicon';
// import {
//   BASE_URL,
//   FB_URL,
//   TWITTER_URL,
//   LINKEDIN_URL,
//   YOUTUBE_URL,
// } from 'src/data/constants';
import { BASE_URL } from 'src/data/constants';

/**
 * Create JSON-LD
 * @param  {Object} jsonLD
 * @return {Array}
 */
// const createJsonLD = ({ jsonLD }) => {
//   const baseJsonLD = [
//     {
//       '@context': 'http://schema.org',
//       '@type': 'WebSite',
//       name: 'O’clock - école de développement Web',
//       alternateName: 'O’clock',
//       url: BASE_URL,
//       sameAs: [FB_URL, TWITTER_URL, LINKEDIN_URL, YOUTUBE_URL],
//     },
//   ];

//   // If we have JSON-LD
//   if (jsonLD) {
//     baseJsonLD.push(...jsonLD);
//   }

//   return baseJsonLD;
// };

/*
 * Component
 */
const Head = (props) => {
  const { seo } = props;
  // const dynamicJsonLD = createJsonLD(props);

  // Hooks
  const { pathname } = useLocation();

  // Props
  const color = getColor(seo);
  const favicon = getFaviconPath(seo);

  // const scripts = `${JSON.stringify(dynamicJsonLD)}`;

  /*
   * Render
   */
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />

      {/* OpenGraph */}
      <meta
        name="og:title"
        content={seo.og && seo.og.title ? seo.og.title : seo.title}
      />
      <meta
        name="og:description"
        content={
          seo.og && seo.og.description ? seo.og.description : seo.description
        }
      />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Momentum" />

      {seo.og
        && seo.og.image && [
          /* eslint-disable indent, react/jsx-indent */
          <meta
            key="image"
            property="og:image"
            content={
              // Il faut ajouter `https://oclock.io`, sinon les og:image ne
              // fonctionne pas sur Twitter. Sauf que les métadonnées d'un
              // article nous retourne une og:image avec un lien absolu
              // (https://wp.oclock.io/images/xxx-xxx.xxx). De fait, nous avions
              // `https://oclock.io/https://wp.oclock.io/images/xxx-xxx.xxx`,
              // d'où cette condition.
              //
              // @TODO : Trouver un moyen de se passer de cette condition
              seo.og.image.includes('oclock.io')
                ? seo.og.image
                : `${BASE_URL}${seo.og.image}`
            }
          />,
          <meta key="image:height" property="og:image:height" content="630" />,
          <meta key="image:width" property="og:image:width" content="1200" />,
          /* eslint-enable indent, react/jsx-indent */
      ]}

      <meta property="og:locale" content="fr_FR" />
      <meta property="og:url" content={`${BASE_URL}${pathname}`} />

      {/* PWA */}
      <meta name="theme-color" content={color} />
      <meta name="application-name" content="Momentum" />
      <link rel="manifest" href="/manifest.webmanifest" />

      {/* Twitter */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Oclock_io" />
      <meta name="twitter:creator" content="@Oclock_io" /> */}
      {seo.og && seo.og.twitter && (
        <meta
          name="twitter:image"
          content={
            seo.og.twitter.includes('oclock.io')
              ? seo.og.twitter
              : `${BASE_URL}${seo.og.twitter}`
          }
        />
      )}

      {/*  Indexation */}
      <link rel="canonical" href={`${BASE_URL}${pathname}`} />

      {/* Favicon */}
      <link rel="icon" type="image/png" href={favicon[16]} sizes="16x16" />
      <link rel="icon" type="image/png" href={favicon[32]} sizes="32x32" />

      {/* JSON-LD */}
      {/* <script type="application/ld+json">{`${scripts}`}</script> */}
    </Helmet>
  );
};

/*
 * PropTypes
 */
Head.propTypes = {
  color: PropTypes.string,

  // SEO
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,

    // OG
    og: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      twitter: PropTypes.string,
    }),
  }),

  // JSON-LD
  jsonLD: PropTypes.arrayOf(PropTypes.object),
};

/*
 * Export
 */
export default Head;
