/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Components
 */
export const createMain = ({ ...style }) => styled.main({
  ...style,
});
