/*
 * Package Import
 */

/*
 * Local Import
 */
import { createMain } from 'src/components/Main';

/*
 * Components
 */
export default createMain({});
